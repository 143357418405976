import CloseIcon from "@/assets/vectors/close.svg?react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function SubscriptionToast({ title, message }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col" data-theme="insoles-light">
      <div className="flex justify-between items-center">
        <span className="flex justify-center text-lg font-bold text-warning">
          {title}
        </span>

        <button
          onClick={() => toast.dismiss()}
          className="btn btn-circle btn-outline btn-xs"
        >
          <CloseIcon />
        </button>
      </div>

      <div className="divider my-1 before:bg-gray-200 after:bg-gray-200"></div>

      <p className="mb-3">{message}</p>

      <p>
        {t(`Please contact your system administrator or XSENSOR Support to renew
        your subscription.`)}
      </p>

      <span className="flex select-text justify-center text-sm text-info">
        support@xsensor.com
      </span>

      <span className="flex select-text justify-center text-sm text-info">
        www.xsensor.com
      </span>

      <span className="flex select-text justify-center text-sm text-info">
        403-266-6612
      </span>
    </div>
  );
}
