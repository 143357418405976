import { setEnvironment } from "@/redux/environment/slice";
import { useEffect, useState } from "react";
import { error, info } from "@/utilities/log";
import { PURGE } from "@/redux/purge";
import useGraphqlRest from "../useGraphqlRest";
import CURRENT_ENVIRONMENT from "@/api/environment/currentEnvironment";
import useInitializeAmplify from "../useInitializeAmplify";
import { FALLBACK_PRODUCTION_ENVIRONMENT, LOCAL_ENVIRONMENT } from "@/env";
import { appWindow } from "@tauri-apps/api/window";
import useGetVersion from "../tauri/useGetVersion";
import usePlatform from "../tauri/usePlatform";
import { store } from "@/configurations/store";
import configureSentry from "@/configurations/sentry";

const URL_TO_STAGE = {
  "fg-dev.xsensorcloud.com": "dev",
  "fg-staging.xsensorcloud.com": "staging",
  "fg.xsensorcloud.com": "prod",
  localhost: "local",
  "10.0.0": "local",
};

export default function useInitializeEnvironment(client) {
  const { isWeb } = usePlatform();
  const getVersion = useGetVersion();
  const [isReady, setReady] = useState(false);
  const graphqlRest = useGraphqlRest();
  const initalizeAmplify = useInitializeAmplify();

  useEffect(() => {
    if (!client) return;

    const fn = async () => {
      store.dispatch(PURGE());

      try {
        const version = await getVersion();
        const url = Object.entries(URL_TO_STAGE).filter(([key, value]) =>
          window.location.href.includes(key)
        );
        // this is done as a rest call because apollo client is not yet initialized
        const response = await graphqlRest(CURRENT_ENVIRONMENT, {
          version,
          stage: isWeb ? url?.[1] : undefined,
        });
        info(
          "[useInitializeEnvironment] current environment",
          response?.data?.currentEnvironment
        );
        const environment = import.meta.env.PROD
          ? response?.data?.currentEnvironment
          : LOCAL_ENVIRONMENT;

        import.meta.env.PROD && configureSentry(environment);

        info("[useInitializeEnvironment] actual environment used", environment);

        store.dispatch(setEnvironment(environment));

        await initalizeAmplify(environment);

        setTimeout(() => {
          appWindow.center();
        }, 500);

        setReady(true);
      } catch (e) {
        error("Error fetching tags:", e);
        const fallbackEnvironment = import.meta.env.PROD
          ? FALLBACK_PRODUCTION_ENVIRONMENT
          : LOCAL_ENVIRONMENT;

        store.dispatch(setEnvironment(fallbackEnvironment));
        await initalizeAmplify(fallbackEnvironment);

        setReady(true);
      }
    };

    if (!isReady) {
      fn();
    }
  }, [graphqlRest, isReady, client, getVersion, initalizeAmplify, isWeb]);

  return isReady;
}
