import { useMemo } from "react";
import useOfflineMeta from "../useOfflineMeta";

export default function useGetChildSessions(sessionID) {
  const { data: offlineSessions } = useOfflineMeta();
  const allChildSessions = useMemo(
    () => offlineSessions?.filter((session) => session?.rootID === sessionID),
    [offlineSessions, sessionID]
  );
  const directChildSessions = useMemo(
    () => offlineSessions?.filter((session) => session?.parentID === sessionID),
    [offlineSessions, sessionID]
  );
  const childSessions = useMemo(
    () => [...new Set([...allChildSessions, ...directChildSessions])],
    [allChildSessions, directChildSessions]
  );

  return childSessions;
}
