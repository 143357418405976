import useInitConnectionListener from "@/hooks/events/initConnection/useInitConnectionListener";
import useDesktopSettingsSyncListener from "@/hooks/events/desktopSettingsSync/useDesktopSettingsSyncListener";
import { Outlet } from "react-router-dom";
import useSessionExportedListener from "@/hooks/events/useSessionExportedListener";
import useListenForDesktopActivityTimeout from "@/hooks/events/useListenForDesktopActivityTimeout";
import useSessionReportExportedListener from "@/hooks/events/sessionReportExported/useSessionReportExportedListener";
import useSyncSessionListener from "@/hooks/events/syncSession/useSyncSessionListener";
import useSessionClosedListener from "@/hooks/events/sessionClosed/useSessionClosedListener/useSessionClosedListener";

export default function Listeners() {
  // event listener hooks for rust events
  // useGetOpenSessionsListener();
  useInitConnectionListener();
  useSyncSessionListener();
  useSessionClosedListener();
  useDesktopSettingsSyncListener();
  useSessionExportedListener();
  useSessionReportExportedListener();
  useListenForDesktopActivityTimeout();

  return <Outlet />;
}
