import useAccountSubscription from "@/hooks/account/useAccountSubscription";
import { selectIsLoggedIn, selectIsOnline } from "@/redux/account/selectors";
import {
  selectAllSyncSessions,
  selectOpenSessions,
} from "@/redux/sessions/selectors";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function ReconcileSessionButton({ session, sessions }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sessionID, parentID, parentIDs } = session;
  const { isActive } = useAccountSubscription();
  const openSessions = useSelector(selectOpenSessions);
  const syncingSessions = useSelector(selectAllSyncSessions);
  const promise = syncingSessions[sessionID]?.promise;
  const isSynced = syncingSessions[sessionID]?.isSynced;
  const isOnline = useSelector(selectIsOnline);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const hasParent = useMemo(() => {
    if (!parentID && !parentIDs) return false;

    return sessions.find(
      (s) => s.sessionID === parentID || parentIDs?.includes(s.sessionID)
    );
  }, [sessions, parentID, parentIDs]);
  const isOpen = useMemo(() => {
    return openSessions.includes(sessionID);
  }, [openSessions, sessionID]);
  const disabled =
    !isActive ||
    !isOnline ||
    !isLoggedIn ||
    hasParent ||
    isOpen ||
    promise ||
    isSynced;

  const tooltipMessage = !isOnline
    ? t("You must be online to reconcile a session")
    : !isLoggedIn
      ? t("You must be logged in to reconcile a session")
      : !isActive
        ? t("You must have an active subscription to reconcile a session")
        : syncingSessions[sessionID]
          ? t("Session is currently syncing")
          : hasParent
            ? t("Please reconcile the original session")
            : isOpen
              ? t("Please close the session in order to reconcile")
              : null;

  return (
    <div
      className={`tooltip-left flex flex-col before:!max-w-3xl before:whitespace-pre-line 
      before:overflow-wrap before:break-words before:content-[attr(data-tip)] ${
        tooltipMessage ? "tooltip tooltip-left" : ""
      }`}
      data-tip={tooltipMessage}
    >
      {/* do not make this a <Link> tag, the disabled: modifier is only working on a button */}
      <button
        onClick={() =>
          isLoggedIn
            ? navigate(`/auth/offline/reconcile/${sessionID}`)
            : navigate(`/offline/reconcile/${sessionID}`)
        }
        className={`btn-outline btn-success btn-xs btn disabled:text-white`}
        disabled={disabled}
      >
        {promise ? t("Syncing") : isSynced ? t("Synced") : t("Reconcile")}
      </button>
    </div>
  );
}
