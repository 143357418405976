import { selectSearch } from "@/redux/listFilter/selectors";
import { emptyArr } from "@/utilities/empties";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export default function useLocalSearch(data = emptyArr, searchKeys = []) {
  const search = useSelector(selectSearch);
  const filtered = useMemo(() => {
    return data.filter((item) => {
      return searchKeys.some((key) => {
        return item?.[key]?.toLowerCase()?.includes(search?.toLowerCase());
      });
    });
  }, [data, searchKeys, search]);

  return filtered;
}
