import getRootSessionID from "./getRootSessionID";

export default function getParentSessionID(sessionID) {
  // remove the first segment, which is the root session ID
  const { rootID, trimmed } = getRootSessionID(sessionID);
  // combine the root session ID with the remaining segments to get the parent session ID
  const parentID = trimmed?.reduce((acc, cur) => {
    return acc + "-" + cur;
  }, rootID);

  return parentID;
}
