import useSelectUser from "@/hooks/apolloState/useSelectUser"
import useSelectCurrentUser from "@/hooks/apolloState/useSelectCurrentUser"
import { useMemo } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import UserForm from "../UserForm"
import { ROLE } from "@/views/UserList/constants"
import { useTranslation } from "react-i18next"
import useSelectCurrentFacility from "@/hooks/apolloState/useSelectCurrentFacility"
import useSelectFacility from "@/hooks/apolloState/useSelectFacility"
import useSubmit from "./useSubmit"

export default function EditUserForm() {
  const { t } = useTranslation()
  const { userID } = useParams()
  const user = useSelectUser(userID)
  const myFacility = useSelectCurrentFacility()
  const shareAllClients = myFacility?.shareAllClients
  const facility = useSelectFacility(user?.facility)
  const form = useForm({
    defaultValues: async () => {
      return {
        ...user,
        role: { value: user.role.name, label: user.role.name },
        facility
      }
    }
  })
  const { getValues, watch } = form
  const { role } = watch()
  const [editUser, { loading }] = useSubmit(getValues, userID)
  const hiddenFields = useMemo(
    () => [
      (shareAllClients || role?.value === ROLE.XSENSOR_ADMIN) && "clients"
    ],
    [role, shareAllClients]
  )

  const currentUser = useSelectCurrentUser()
  const disabledFields = useMemo(
    () => [
      "email",
      (currentUser.currentRole?.name !== ROLE.XSENSOR_ADMIN ||
        currentUser.id === user.id) &&
        "facility"
    ],
    [currentUser, user]
  )

  return (
    <UserForm
      hiddenFields={hiddenFields}
      disabledFields={disabledFields}
      submit={editUser}
      form={form}
      loading={loading}
      title={t("Edit User")}
      submitLabel={t("Update")}
    />
  )
}
