import { getMetaPath } from "@/utilities/paths/getSessionPaths";
import { BaseDirectory, exists, removeFile } from "@tauri-apps/api/fs";
import { useCallback } from "react";
import useRetry from "./useRetry/useRetry";

export default function useRemoveSession() {
  const retry = useRetry();
  const removeSession = useCallback(
    async (sessionID, path) => {
      const metaPath = await getMetaPath(sessionID);

      await retry(5, 1000, async () => {
        (await exists(path, { dir: BaseDirectory.App })) &&
          (await removeFile(path, { dir: BaseDirectory.App }));
      });

      // remove the meta file
      (await exists(metaPath, { dir: BaseDirectory.App })) &&
        (await removeFile(metaPath, { dir: BaseDirectory.App }));
    },
    [retry]
  );

  return removeSession;
}
