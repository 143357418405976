import RowSkeleton from "./RowSkeleton/RowSkeleton";
import HeaderSkeleton from "./HeaderSkeleton/HeaderSkeleton";

const ROWS = 8;

export default function TableSkeleton() {
  return (
    <div
      data-theme="insoles-light"
      className="TableSkeleton flex flex-col overflow-y-scroll w-full"
    >
      <HeaderSkeleton />

      {[...Array(ROWS)].map((_, index) => {
        return <RowSkeleton key={index} />;
      })}
    </div>
  );
}
