import { combineReducers } from "redux";
import accountSlice from "./account/slice";
import listFilterSlice from "./listFilter/slice";
import sessionsSlice from "./sessions/slice";
import sidecarSlice from "./sidecar/slice";
import tableSlice from "./table/slice";
import uiSlice from "./ui/slice";
import environmentSlice from "./environment/slice";

export const PERSIST_WHITELIST = [
  "account",
  "sidecar",
  "sessions",
  "environment",
  "ui",
  "listFilter",
];

export default combineReducers({
  listFilter: listFilterSlice.reducer,
  sessions: sessionsSlice.reducer,
  sidecar: sidecarSlice.reducer,
  account: accountSlice.reducer,
  ui: uiSlice.reducer,
  table: tableSlice.reducer,
  environment: environmentSlice.reducer,
});
