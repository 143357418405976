import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

export default function AllAssignedClinicians() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();

  return (
    <div className="relative flex flex-col">
      <span className="text-lg font-bold pb-5 bg-base-100 w-full">
        {t("All Assigned Clinicians")}
      </span>

      {state?.users.map((user) => {
        return (
          <Fragment key={user.id}>
            <div>{user.name}</div>
            <div className="divider my-1"></div>
          </Fragment>
        );
      })}

      <div className="sticky bottom-0 right-0 flex justify-end">
        <button
          type="button"
          className="btn-outline btn btn-sm mr-5 px-8"
          data-testid="clientFormCancleBtn"
          onClick={() => navigate(-1, { replace: true })}
        >
          {t("Back")}
        </button>
      </div>
    </div>
  );
}
