import MY_USER from "@/api/user/myUser";
import useAutoReconcile from "@/hooks/io/useAutoReconcile";
import useCleanupActiveSessions from "@/hooks/io/useCleanupActiveSessions";
import useUpdateAllMeta from "@/hooks/io/useUpdateAllMeta";
import { useLazyQuery } from "@apollo/client";
import { Auth } from "aws-amplify";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import useGlobalSignOut from "@/hooks/account/useGlobalSignOut";
import { useDispatch } from "react-redux";
import { setCurrentUserId, setIsLoggedIn } from "@/redux/account/slice";
import useSetLanguageAtLogin from "./useSetLanguageAtLogin";
import useHandleLoginErrors from "./useHandleLoginErrors";
import * as Sentry from "@sentry/react";
import useCleanupBadMetaData from "@/hooks/io/useCleanupBadMetaData";

export default function useLogin(getValues) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [getMyUser] = useLazyQuery(MY_USER);
  const cleanupActiveSessions = useCleanupActiveSessions();
  const updateAllMeta = useUpdateAllMeta();
  const autoReconcile = useAutoReconcile();
  const globalSignOut = useGlobalSignOut();
  const cleanupBadMetaData = useCleanupBadMetaData();
  const setLanguageAtLogin = useSetLanguageAtLogin();
  const handleLoginErrors = useHandleLoginErrors();
  const login = useCallback(
    async (user) => {
      try {
        setLoading(true);
        const email = (getValues ? getValues("email") : user?.username)?.trim();
        const password = getValues && getValues("password");
        // this is to ensure only one session is active at a time
        await globalSignOut(email);
        const foundUser = user ? user : await Auth.signIn(email, password);

        if (foundUser?.challengeName === "NEW_PASSWORD_REQUIRED") {
          navigate("/complete_change_password", {
            state: {
              username: email,
              password: password,
            },
          });
          return;
        }

        const result = await getMyUser();
        const myUser = result?.data?.myUser;

        myUser?.cognitoID &&
          Sentry.setUser({
            id: myUser.cognitoID,
            email: myUser?.email,
          });

        // handle all the language settings during login
        await setLanguageAtLogin(myUser);
        // move xsn's from active folder to offline in case the app crashed or closed improperly
        // and were left in the active folder
        await cleanupActiveSessions();
        // sync any sessions that are already associated with a client but failed to sync
        await autoReconcile();
        // update all meta to indicate sessions are offline
        await updateAllMeta({ offline: true });
        // cleanup meta with no xsn
        await cleanupBadMetaData();

        dispatch(setCurrentUserId(myUser?.cognitoID));

        dispatch(setIsLoggedIn(true));
        navigate("/auth");
      } catch (e) {
        handleLoginErrors(e, getValues);
      } finally {
        setLoading(false);
      }
    },
    [
      getValues,
      navigate,
      cleanupActiveSessions,
      updateAllMeta,
      globalSignOut,
      autoReconcile,
      getMyUser,
      dispatch,
      setLanguageAtLogin,
      handleLoginErrors,
      cleanupBadMetaData,
    ]
  );

  return { login, loading };
}
