import { gql } from "@apollo/client";
import USER_FRAGMENT from "../user/userFragment";
import SESSION_FRAGMENT from "../session/sessionFragment";

const CLIENT = gql`
  fragment ClientFragment on Client {
    id
    clientID
    privateID
    facility
    fullname
    groups
    numberOfSessions
    disabled
    users {
      ...UserFragment
    }
    latestSession {
      ...SessionFragment
    }
  }

  ${SESSION_FRAGMENT}
  ${USER_FRAGMENT}
`;

export default CLIENT;
