import { useCallback, useEffect } from "react";

// NOTE: calls the callback function when user clicks anywhere outside the provided ref element
export default function useClickOutside(
  ref,
  callback,
  customEvent = "mousedown"
) {
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (!ref.current) return;
      if (ref.current.contains(e.target)) return;
      // .contains does not recognize SVG elements so we add a special check for those too
      if (e.target instanceof SVGElement) return;
      callback(e);
    },
    [ref, callback]
  );

  useEffect(() => {
    window.addEventListener(customEvent, handleClick);

    return () => {
      window.removeEventListener(customEvent, handleClick);
    };
  }, [customEvent, handleClick]);
}
