import CLIENT_LIST from "@/api/lists/clientList";
import { useQuery } from "@apollo/client";

import SuperSelect from "../SuperSelect/SuperSelect";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function SelectClients({ control, disabled, rules, ...props }) {
  const { t } = useTranslation();
  const { data, previousData, loading } = useQuery(CLIENT_LIST);
  const clients = data?.clientList || previousData?.clientList;

  return (
    <div
      className={`${props?.disabled ? "tooltip tooltip-bottom" : ""}`}
      data-tip={t(
        `You must disable "sharing all clients" in order to assign individual clients to a user`
      )}
    >
      <Controller
        control={control}
        name="clients"
        rules={rules}
        render={({ field }) => {
          return (
            <SuperSelect
              {...field}
              closeMenuOnSelect={false}
              isMulti
              isDisabled={disabled}
              {...props}
              options={clients}
              getOptionLabel={(option) => option.fullname}
              getOptionValue={(option) => option.clientID}
              isLoading={loading}
            />
          );
        }}
      />
    </div>
  );
}
