import i18n from "@/configurations/i18n";
import { EXPORT_TYPE } from "@/constants";

export default function formatExportType(type) {
  if (type === EXPORT_TYPE.PDF_REPORT) return i18n.t("Report");
  if (type === EXPORT_TYPE.CSV_REPORT) return i18n.t("Stance Statistics");
  if (type === EXPORT_TYPE.TRIM_STANCE) return i18n.t("Trim Stance");
  if (type === EXPORT_TYPE.AVG_STANCE) return i18n.t("Average Stance");

  return type;
}
