import { components } from "react-select";

export default function MultiValue(props) {
  return (
    <components.MultiValue
      {...props}
      className="MultiValue !rounded !overflow-hidden !leading-5 !text-sm !text-neutral-content !bg-neutral"
    />
  );
}
