import MY_USER from "@/api/user/myUser";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useCallback } from "react";
import i18n from "@/configurations/i18n";
import USER_UPDATE from "@/api/user/userUpdate";
import { useDispatch, useSelector } from "react-redux";
import { selectManuallySelectedLanguage } from "@/redux/account/selectors";
import { setManuallySelectedLanguage } from "@/redux/account/slice";

export default function useSetLanguageAtLogin() {
  const dispatch = useDispatch();
  const [getMyUser] = useLazyQuery(MY_USER);
  const [updateUser] = useMutation(USER_UPDATE);
  const manuallySelectedLanguage = useSelector(selectManuallySelectedLanguage);
  const setLanguageAtLogin = useCallback(
    async (myUser) => {
      let updatedUser;

      // IF the users account has no langauge set OR if the user has manually selected a language THEN update their account language
      if (!myUser?.language || manuallySelectedLanguage) {
        console.info(
          "[useLogin] Setting user account language to: ",
          i18n.language
        );
        updatedUser = await updateUser({
          variables: {
            cognitoID: myUser?.cognitoID,
            language: manuallySelectedLanguage || i18n.language,
          },
        });
        await getMyUser();
      }

      const language = updatedUser?.language || myUser?.language;

      dispatch(setManuallySelectedLanguage());

      console.info("[change language] ", language);
      i18n.changeLanguage(language);
    },
    [getMyUser, updateUser, manuallySelectedLanguage, dispatch]
  );

  return setLanguageAtLogin;
}
