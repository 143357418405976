import { createSlice } from "@reduxjs/toolkit";
import purge, { PURGE } from "../purge";

const initialState = {
  isShowingSidebar: false,
  expirationToasted: false,
  sortSessions: [{ id: "createdAt", desc: true }],
  sortClients: [{ id: "latestSession.lastUpdated", desc: true }],
  sortUsers: [{ id: "name", desc: false }],
  sortOfflineSessions: [{ id: "createdAt", desc: true }]
};

export const uiSlice = createSlice({
  name: "UI",
  initialState,
  reducers: {
    setExpirationToasted: (state, { payload }) => {
      state.expirationToasted = payload;
    },

    setSortClients: (state, { payload }) => {
      state.sortClients = payload;
    },

    setSortSessions: (state, { payload }) => {
      state.sortSessions = payload;
    },

    setSortUsers: (state, { payload }) => {
      state.sortUsers = payload;
    },

    setSortOfflineSessions: (state, { payload }) => {
      state.sortOfflineSessions = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, purge(initialState));
  }
});

export const {
  setExpirationToasted,
  setSortClients,
  setSortSessions,
  setSortUsers,
  setSortOfflineSessions
} = uiSlice.actions;

export default uiSlice;
