import { useParams } from "react-router-dom"
import useSelectSession from "@/hooks/apolloState/useSelectSession/useSelectSession"
import { useTranslation } from "react-i18next"
import { selectOfflineMeta } from "@/redux/sessions/selectors"
import { useSelector } from "react-redux"
import useSelectClient from "@/hooks/apolloState/useSelectClient"

export default function DisplaySessionNotes() {
  const params = useParams()
  const { id } = params
  const { t } = useTranslation()
  const session = useSelectSession(id)
  const client = useSelectClient(id)
  const sessionID = session?.id || client?.latestSession?.id
  const offlineMeta = useSelector(selectOfflineMeta)
  const notes =
    session?.notes ||
    client?.latestSession?.notes ||
    session?.label ||
    offlineMeta.find((meta) => meta.sessionID === sessionID)?.notes

  return (
    <div className="flex flex-col gap-5">
      <h1 className="text-2xl">{t("Session Notes")}</h1>

      <p>{notes}</p>
    </div>
  )
}
