import { createSelector } from "@reduxjs/toolkit";

export const selectAllSyncSessions = (state) => state.sessions.syncing;
export const selectOpenSessions = (state) => state.sessions.openSessions;
export const selectMetaCache = (state) => state.sessions.metaCache;
export const selectOpeningSessions = (state) => state.sessions.openingSessions;
export const selectActiveSessions = (state) => state.sessions.active;
export const selectOfflineSessions = (state) => state.sessions.offline;
export const selectOfflineMeta = (state) => state.sessions.offlineMeta;
export const selectReconcilingSessions = (state) =>
  state.sessions.reconcilingSessions;

export const selectSyncedSessions = createSelector(
  [selectAllSyncSessions],
  (allSyncedSessions) => {
    return Object.entries(allSyncedSessions)
      .filter(([key, value]) => value.isSynced)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  }
);

export const selectSyncingSessions = createSelector(
  [selectAllSyncSessions],
  (allSyncedSessions) => {
    return Object.entries(allSyncedSessions)
      .filter(([key, value]) => value.promise && !value.isSynced)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  }
);

export const selectSyncedSessionIDs = createSelector(
  [selectSyncedSessions],
  (syncedSessions) => {
    return Object.keys(syncedSessions).map((session) => session.sessionID);
  }
);

export const selectSyncingSessionIDs = createSelector(
  [selectSyncingSessions],
  (syncingSessions) => {
    return Object.keys(syncingSessions);
  }
);

export const selectLocalFiles = createSelector(
  [selectActiveSessions, selectOfflineSessions],
  (active, offline) => {
    return { active, offline };
  }
);
