import { useApolloClient } from "@apollo/client";
import FACILITY_FRAGMENT from "@/api/facility/facilityFragment";

export default function useSelectFacility(id) {
  const client = useApolloClient();
  const reference = client.cache.identify({
    __typename: "Facility",
    id,
  });
  const facility = client.readFragment({
    id: reference,
    fragmentName: "FacilityFragment",
    fragment: FACILITY_FRAGMENT,
  });

  return facility;
}
