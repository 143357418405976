import { appWindow } from "@tauri-apps/api/window";
import { useEffect, useState } from "react";

export default function useWindowFocus() {
  const [focused, setFocused] = useState(false);
  useEffect(() => {
    const async_fn = async () => {
      await appWindow.onFocusChanged(({ payload: focused }) => {
        setFocused(focused);
      });
    };

    async_fn();
  }, []);

  return focused;
}
