import { info } from "@/utilities/log";
import { listen } from "@tauri-apps/api/event";
import { useEffect } from "react";
import useLogout from "../account/useLogout";

export default function useListenForDesktopActivityTimeout() {
  const logout = useLogout();

  useEffect(() => {
    const unlisten = listen("activity_timeout", async (event) => {
      info("[useListenForDesktopActivityTimeout] ", event);

      await logout();
    });

    return () => {
      unlisten.then((f) => f());
    };
  }, [logout]);
}
