import { ApolloClient, ApolloLink } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import authLink from "./authLink";
import errorLink from "./errorLink";
import { dynamicHttpLink } from "./httpLink";
import { info } from "@/utilities/log";
import useGetInMemoryCache from "./useGetInMemoryCache";

export default function useInitializeApolloClient() {
  const [client, setClient] = useState();
  const [key, setKey] = useState(0);
  const getInMemoryCache = useGetInMemoryCache();
  const buildApollo = useCallback(async () => {
    info("Building Apollo cache and client");
    const cache = getInMemoryCache();
    const client = new ApolloClient({
      defaultOptions: {
        watchQuery: {
          errorPolicy: "none",
        },
        query: {
          errorPolicy: "none",
        },
        mutate: {
          errorPolicy: "none",
        },
      },
      link: ApolloLink.from([authLink, errorLink, dynamicHttpLink]),
      cache,
      name: import.meta.env.APP_NAME,
      version: import.meta.env.APP_VERSION,
    });

    setClient((prev) => {
      prev?.stop();

      return client;
    });

    setKey((prev) => prev + 1);
  }, [getInMemoryCache]);

  useEffect(() => {
    if (client) return;

    buildApollo();
  }, [client, buildApollo]);

  return { client, reset: buildApollo, key };
}
