import { components } from "react-select";

export const Control = (props) => {
  return (
    <components.Control
      {...props}
      className={`Control !min-h-[48px] !h-full outline-none focus:ring !input-bordered 
    !input !pr-1 !text-base-content ${props.isDisabled && "!border-base-300"}`}
    />
  );
};
