import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useCloseWindow from "./useCloseWindow";
import { selectSyncingSessionIDs } from "@/redux/sessions/selectors";

export default function useTryCloseWindow() {
  const navigate = useNavigate();
  const closeWindow = useCloseWindow();
  const syncingSessionsIDs = useSelector(selectSyncingSessionIDs);
  const tryCloseWindow = useCallback(async () => {
    if (syncingSessionsIDs.length > 0) {
      navigate("still_syncing", { relative: "path" });
    } else {
      closeWindow();
    }
  }, [syncingSessionsIDs, navigate, closeWindow]);

  return tryCloseWindow;
}
