import i18n from "@/configurations/i18n";
import { invoke } from "@tauri-apps/api/tauri";
import { toaster } from "@/utilities/toaster";

export default function eventListenerBuilder(sessions, openSessions) {
  return (event) => {
    if (event.payload.success !== "ok")
      toaster.error(
        `${i18n.t("Failed to open session")} ${event.payload.sessionID}`
      );

    // increment the number of sessions that have been opened
    openSessions.push(event.payload.sessionID);

    if (openSessions.length === 2) {
      // only invoke compare sessions once both sessions are open
      invoke("compare_sessions", {
        sessionIds: sessions.map((s) => s.sessionID),
      });
    }
  };
}
