import { InMemoryCache } from "@apollo/client";
import { useCallback } from "react";
import mergeWithPayload from "./mergeWithPayload";

export default function useGetInMemoryCache() {
  const getInMemoryCache = useCallback(() => {
    return new InMemoryCache({
      typePolicies: {
        Session: {
          keyFields: ["sessionID"],
        },
        Client: {
          keyFields: ["clientID"],
        },
        User: {
          keyFields: ["cognitoID"],
        },
        Query: {
          fields: {
            clientMany: {
              keyArgs: [
                "filter",
                ["search", "enabled", "showDisabled"],
                "sort",
                "privateIDs",
                "clientIDs",
                "groups",
                "users",
              ],
              merge: mergeWithPayload,
            },
            userMany: {
              keyArgs: ["filter", ["search", "enabled", "roles"], "sort"],
              merge: mergeWithPayload,
            },
            sessionMany: {
              keyArgs: [
                "filter",
                ["search", "hideArchived"],
                "clientID",
                "sort",
              ],
              merge: mergeWithPayload,
            },
          },
        },
      },
    });
  }, []);

  return getInMemoryCache;
}
