import { gql } from "@apollo/client";
import FACILITY_FRAGMENT from "./facilityFragment";

const FACILITY_UPDATE = gql`
  mutation FACILITY_UPDATE(
    $name: String
    $shareAllClients: Boolean
    $subscriptionExpirationDate: Date
    $RMA: String
    $desktopSettings: DesktopSettingsInput
  ) {
    facilityUpdate(
      name: $name
      shareAllClients: $shareAllClients
      subscriptionExpirationDate: $subscriptionExpirationDate
      RMA: $RMA
      desktopSettings: $desktopSettings
    ) {
      ...FacilityFragment
    }
  }

  ${FACILITY_FRAGMENT}
`;

export default FACILITY_UPDATE;
