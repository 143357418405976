import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import useCheckIsOnline from "@/hooks/account/useCheckIsOnline";
import SESSION_LOCK from "@/api/session/sessionLock";
import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser";

export default function useSetSessionLock() {
  const checkIsOnline = useCheckIsOnline();
  const currentUser = useCurrentUser();
  const [sessionLock] = useMutation(SESSION_LOCK);
  const setSessionLock = useCallback(
    async (session, locked) => {
      const online = await checkIsOnline();
      online &&
        (await sessionLock({
          variables: {
            sessionID: session?.sessionID,
            locked: locked ? currentUser?.name : null,
          },
        }));
    },
    [checkIsOnline, sessionLock, currentUser]
  );

  return setSessionLock;
}
