import { format } from "date-fns";

import { FaRunning } from "react-icons/fa";
import { IoWalk } from "react-icons/io5";
import { MdOutlineNordicWalking } from "react-icons/md";
import { TbMathXDivideY2 } from "react-icons/tb";
import { LuSlice } from "react-icons/lu";
import formatExportType from "@/utilities/text/formatExportType";
import { Link, useNavigate } from "react-router-dom";

const SESSION_TYPE_ICONS = {
  Running: <FaRunning size="18" className="text-success" />,
  Freerunning: <FaRunning size="18" className="text-success" />,
  Walking: <IoWalk size="18" />,
  Treadmill: <MdOutlineNordicWalking size="18" className="text-orange-300" />,
  "Olympic Speed Walking": <IoWalk size="18" className="text-indigo-300" />,
  average_stance: <TbMathXDivideY2 size="18" className="text-pink-300" />,
  trim_stance: <LuSlice size="18" className="text-purple-300" />,
};

export default function LastUsedSessionCell({ client }) {
  const navigate = useNavigate();
  const exportType = formatExportType(client?.latestSession?.exportType);
  const sessionType = client?.latestSession?.sessionType;
  const Icon = SESSION_TYPE_ICONS[sessionType] || (
    <FaRunning size="18" className="text-success" />
  );
  const label = client?.latestSession?.notes;

  if (!client?.latestSession) return null;

  return (
    <div className="RecentSessionCell flex justify-center">
      <div className={`tooltip-left flex flex-col px-5`}>
        {label ? (
          <div
            onClickCapture={(e) => {
              e.stopPropagation();
              e.preventDefault();

              navigate(`notes/${client.clientID}`);
            }}
            className="block w-[250px] max-h-64 truncate text-left font-bold"
          >
            {label}
          </div>
        ) : (
          <p className="block w-[250px] max-h-64 truncate text-left text-slate-300">
            {exportType || sessionType}
          </p>
        )}

        <div className="flex justify-start">
          {format(new Date(client.latestSession.lastUpdated), "PPpp")}
        </div>
      </div>
    </div>
  );
}
