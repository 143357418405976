export const ROW_ITEM_HEIGHT = 68;

export const DEFAULT_SORT = [
  { status: 1 },
  // { acknowledged: 1 },
  // { lastReceived: -1 },
];

export const ROLE = {
  ADMIN: "ADMIN",
  XSENSOR_ADMIN: "XSENSOR_ADMIN",
  STANDARD: "STANDARD",
};
