import { Outlet } from "react-router-dom";
import useCheckRemainingSubscription from "@/hooks/account/useCheckRemainingSubscription";
import useRefreshToken from "@/hooks/account/useRefreshToken";
import useOfflineAutoLogout from "@/hooks/account/useOfflineAutoLogout";
import useInactivityAutoLogout from "@/hooks/account/useInactivityAutoLogout";
import useActionListener from "@/hooks/useActionListener";
import { setIsOnline } from "@/redux/account/slice";
import useOnReconnect from "@/hooks/io/useOnReconnect";
import useSetLanguage from "@/hooks/useSetLanguage";

export default function AuthenticatedRoutes() {
  const onReconnect = useOnReconnect();

  // authorization hooks
  useOfflineAutoLogout();
  useInactivityAutoLogout();
  useRefreshToken();
  useSetLanguage();

  // listens for when a client is reconnecting from offline and starts syncing sessions
  useActionListener(setIsOnline, onReconnect);

  useCheckRemainingSubscription();

  return <Outlet />;
}
