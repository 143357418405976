import { useMemo } from "react";
import { useMatches } from "react-router-dom";

export default function Breadcrumbs() {
  const matches = useMatches();
  const crumbs = useMemo(
    () =>
      matches
        // first get rid of any matches that don't have handle and crumb
        .filter((match) => Boolean(match.handle?.crumb))
        // now map them into an array of elements, passing the match to each
        .map((match) => {
          return <match.handle.crumb key={match.id} />;
        }),
    [matches]
  );

  return (
    <div className="breadcrumbs p-0 m-0 text-lg">
      <ul>
        {crumbs.map((crumb, index) => (
          <li key={index}>{crumb}</li>
        ))}
      </ul>
    </div>
  );
}
