import { clearOffset, setSearch } from "@/redux/listFilter/slice";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import { MdClear } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

export default function Search({ className, placeholder }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const debounceSearchText = useDebouncedCallback((value) => {
    dispatch(clearOffset());
    dispatch(setSearch(value));
  }, 500);

  useEffect(() => {
    debounceSearchText(searchText);
  }, [debounceSearchText, searchText]);

  return (
    <div className={`flex items-center ${className}`}>
      <label className="sr-only">{t("Search")}</label>

      <div className="relative flex w-full items-center">
        {!show && (
          <button
            onClick={() => {
              setShow(true);
            }}
            className={`btn-ghost btn-circle btn-sm btn text-primary`}
          >
            <AiOutlineSearch size={20} />
          </button>
        )}

        {show && (
          <div className={`relative rounded-lg bg-neutral min-w-[320px] p-1`}>
            <input
              ref={ref}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              type="text"
              className={`input input-xs text-base font-mono bg-transparent focus:!border-none focus:!outline-none`}
              placeholder={placeholder || t("Search") + "..."}
              autoFocus
            />

            <button
              onClick={() => {
                if (searchText === "") {
                  ref.current.blur();

                  setShow(false);
                } else {
                  setSearchText("");

                  ref.current.focus();
                }
              }}
              className="btn-ghost btn-sm btn-circle btn absolute top-0 bottom-0 right-1 m-auto"
            >
              <MdClear />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
