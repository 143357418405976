import { useLazyHasPermission } from "@/hooks/account/useHasPermission/useHasPermission";
import useAccountSubscription from "@/hooks/account/useAccountSubscription";
import { useMemo } from "react";
import useSelectCurrentFacility from "@/hooks/apolloState/useSelectCurrentFacility";

export default function useColumnVisibility() {
  const { isActive } = useAccountSubscription();
  const hasPermission = useLazyHasPermission();
  const myFacility = useSelectCurrentFacility();
  const visibility = useMemo(
    () => ({
      fullname: true,
      privateID: true,
      groups: true,
      numberOfSessions: true,
      "latestSession.createdAt": true,
      users: !myFacility?.shareAllClients,
      actions: isActive && !hasPermission("spoofFacility")
    }),
    [isActive, hasPermission, myFacility]
  );

  return visibility;
}
