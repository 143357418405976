import { listen } from "@tauri-apps/api/event";
import { useEffect } from "react";
import useCheckIsOnline from "@/hooks/account/useCheckIsOnline";
import { useSelector } from "react-redux";
import { info } from "@/utilities/log";
import { selectIsLoggedIn } from "@/redux/account/selectors";
import useSyncSessionReportExport from "./useSyncSessionReportExport";
import useSaveOfflineSessionReportExport from "./useSaveOfflineSessionReportExport";
import { path } from "@tauri-apps/api";
import useSelectSession from "@/hooks/apolloState/useSelectSession/useSelectSession";
import getRootSessionID from "@/utilities/paths/getRootSessionID";
import useReadMetaFile from "@/hooks/io/useReadMetaFile";

export default function useSessionReportExportedListener() {
  const syncSessionReportExport = useSyncSessionReportExport();
  const saveOfflineSessionReportExport = useSaveOfflineSessionReportExport();
  const checkIsOnline = useCheckIsOnline();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const selectSession = useSelectSession();
  const readMetaFile = useReadMetaFile();

  useEffect(() => {
    const unlisten = listen("session_report_exported", async (event) => {
      const {
        token,
        export_type: exportType,
        file_path: filePath,
        label,
        sessions,
      } = event.payload;
      const isOnline = await checkIsOnline();
      const sessionIDs = token ? [token] : sessions.map(({ token }) => token);
      const meta = { sessionIDs, exportType, label, filePath, sessions };
      const fileName = await path.basename(filePath);
      const parentID = sessionIDs?.[0];
      const extension = "." + (await path.extname(filePath));
      const parentSession = await selectSession(parentID);
      const sessionID = fileName.replace(extension, "");
      const parentMeta =
        !parentSession && parentID && (await readMetaFile(parentID));
      const { rootID } = getRootSessionID(parentID);
      const clientID =
        parentSession?.clientID || parentMeta?.clientID || meta?.clientID;

      const metaData = {
        sessionID,
        exportType,
        notes: label,
        parentID: sessionIDs?.length === 1 ? sessionIDs[0] : undefined,
        parentIDs: sessionIDs?.length > 1 ? sessionIDs : undefined,
        createdAt: new Date().toISOString(),
        clientID,
        extension,
        archived: false,
        rootID: rootID || parentID,
        offline: parentMeta?.offline,
      };

      info("[useSyncSessionReportExportedListener] event recevied", sessionIDs);

      if (isOnline && !metaData?.offline && isLoggedIn) {
        await syncSessionReportExport(metaData, fileName);
      } else {
        await saveOfflineSessionReportExport(metaData, fileName);
      }
    });

    return () => {
      unlisten.then((f) => f());
    };
  }, [
    syncSessionReportExport,
    checkIsOnline,
    isLoggedIn,
    saveOfflineSessionReportExport,
    selectSession,
    readMetaFile,
  ]);
}
