import React, { forwardRef } from "react";

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <button
    className="custom-input w-full"
    type="button"
    onClick={onClick}
    ref={ref}
    readOnly
  >
    {value}
  </button>
));

export default CustomInput;
