import { invoke } from "@tauri-apps/api/tauri";
import { useCallback } from "react";

export default function useGetXsnMeta(path) {
  const getXsnMeta = useCallback(
    async (_path) => {
      const session = await invoke("get_session", {
        path: _path || path,
      });

      return session;
    },
    [path]
  );

  return getXsnMeta;
}
