import useLogout from "@/hooks/account/useLogout";
import { useEffect } from "react";
import { useTimeout } from "react-use";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "@/redux/account/selectors";
import { AUTO_LOGOUT_TIME_SECONDS } from "./constants";

export default function useInactivityAutoLogout() {
  const { t } = useTranslation();
  const logout = useLogout();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [isReady, cancel, reset] = useTimeout(AUTO_LOGOUT_TIME_SECONDS * 1000);
  const ready = isReady();

  useEffect(() => {
    const mouseMoveListener = window.addEventListener("mousemove", reset);
    const keydownListener = window.addEventListener("keydown", reset);
    const scrollListener = window.addEventListener("keydown", reset);

    return () => {
      window.removeEventListener("mousemove", mouseMoveListener);
      window.removeEventListener("scroll", scrollListener);
      window.removeEventListener("keydown", keydownListener);
    };
  }, [reset]);

  useEffect(() => {
    if (!isLoggedIn) return reset();
    if (!ready) return;

    logout({ onCompleteMessage: t("Logged out due to inactivity") });
  }, [ready, logout, cancel, reset, isLoggedIn, t]);
}
