import { DATA_PATH } from "@/utilities/paths/paths";
import { error, info } from "@/utilities/log";
import { useCallback } from "react";
import { appConfigDir, join } from "@tauri-apps/api/path";
import { removeDir } from "@tauri-apps/api/fs";

export default function useRemoveSettingsDirectory() {
  const removeSettingsDirectory = useCallback(async () => {
    info("[useRemoveSettingsDirectory] started ");

    try {
      await removeDir(
        await join(await appConfigDir(), DATA_PATH.SETTINGS_ACTIVE),
        { recursive: true }
      );

      info("[useRemoveSettingsDirectory] completed ");
    } catch (e) {
      error("[useRemoveSettingsDirectory] ", e);
    }
  }, []);

  return removeSettingsDirectory;
}
