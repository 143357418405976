import InputField from "@/components/forms/formComponents/InputField/InputField";
import SelectFacility from "@/components/selects/SelectFacility/SelectFacility";

import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSubmit from "./useSubmit";
import MY_FACILITY from "@/api/facility/myFacility";
import { useLazyQuery } from "@apollo/client";

export default function SwitchFacilityForm() {
  const { t } = useTranslation();
  const [getFacility, { refetch }] = useLazyQuery(MY_FACILITY);
  const form = useForm({
    defaultValues: async () => {
      const { data } = await getFacility();

      return {
        facility: data?.myFacility,
      };
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;
  const { submit, loading } = useSubmit(form, refetch);

  return (
    <form
      className="flex flex-col h-full gap-5"
      onSubmit={handleSubmit(submit)}
    >
      <h4 className="mb-3 text-center text-3xl font-bold">
        {t("Switch Facility")}
      </h4>

      <InputField
        className="grow"
        error={errors.facility}
        label={t("Facility")}
      >
        <SelectFacility control={control} />
      </InputField>

      <div className="flex justify-end">
        <Link
          to="../"
          className="btn btn-neutral btn-sm btn-outline px-6"
          disabled={loading}
        >
          {t("Cancel")}
        </Link>

        <button
          className="btn-success btn-sm btn px-8 ml-5"
          disabled={loading}
          type="submit"
        >
          {loading ? (
            <span className="loading loading-ring loading-md text-neutral"></span>
          ) : (
            t("Save")
          )}
        </button>
      </div>
    </form>
  );
}
