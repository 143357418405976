import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser"
import usePlatform from "@/hooks/tauri/usePlatform"
import { ROLE } from "@/views/UserList/constants"
import { Navigate } from "react-router-dom"

export default function SwitchAuthenticatedRoute() {
  const { isWeb } = usePlatform()
  const currentUser = useCurrentUser()

  return isWeb && !import.meta.env.DEV ? (
    <Navigate replace to={"/auth/downloads"} />
  ) : currentUser?.role?.name === ROLE.ADMIN ? (
    <Navigate replace to={"/auth/clients/select_role"} />
  ) : (
    <Navigate replace to={"/auth/clients"} />
  )
}
