import CLIENT_UPDATE from "@/api/client/clientUpdate";
import { useMutation } from "@apollo/client";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useSelectClient from "@/hooks/apolloState/useSelectClient";
import ClientForm from "../ClientForm";
import { useTranslation } from "react-i18next";
import useSelectCurrentFacility from "@/hooks/apolloState/useSelectCurrentFacility";
import useSubmit from "./useSubmit";

export default function EditClientForm() {
  const { t } = useTranslation();
  const { clientID } = useParams();
  const client = useSelectClient(clientID);
  const myFacility = useSelectCurrentFacility();
  const form = useForm({
    defaultValues: async () => {
      return {
        // filter out users that were assigned but no longer exist
        users: client?.users,
        fullname: client?.fullname,
        privateID: client?.privateID,
        groups: client?.groups?.map((group) => ({
          value: group,
          label: group
        }))
      };
    }
  });
  const [clientUpdate, { loading: submitting }] = useMutation(CLIENT_UPDATE);
  const submit = useSubmit(form, clientUpdate, clientID);
  const hiddenFields = useMemo(
    () => [myFacility?.shareAllClients && "users"],
    [myFacility]
  );

  return (
    <ClientForm
      title={t("Edit Client")}
      submit={submit}
      loading={submitting}
      hiddenFields={hiddenFields}
      form={form}
      buttonLabel={t("Update")}
    />
  );
}
