import Filters from "@/components/NavBar/Filters/Filters";
import { selectFilterArchived } from "@/redux/listFilter/selectors";
import { resetFilters, setFilterArchived } from "@/redux/listFilter/slice";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

export default function SessionFilters() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const filterArchived = useSelector(selectFilterArchived);

  return (
    <div>
      <Filters
        isFiltered={!filterArchived}
      >
        <div className="flex justify-between">
          <span>{t("Hide archived sessions")}</span>

          <input
            className="toggle toggle-success"
            checked={filterArchived}
            type="checkbox"
            onChange={(e) => dispatch(setFilterArchived(e.target.checked))}
            name="filterArchived"
          />
        </div>
      </Filters>
    </div>
  );
}
