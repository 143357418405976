import i18n from "@/configurations/i18n";

export const DEFAULT_SETTINGS_SESSION_ID = "default_settings_session";

export const PASSWORD_TOOLTIP_INFO = `
  • ${i18n.t("Requires numbers")}
  • ${i18n.t("Requires uppercase and lowercase letters")}
  • ${i18n.t("Minimum length, at least 6 but fewer than 99 characters")}
  • ${i18n.t("Requires a special character from this set:")}
    ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ \``;
