import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Card from "../../Card/Card"

export default function PersonalInfoCard() {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()

  return (
    <Card
      className="mb-5"
      title={t("Personal Info")}
      buttons={
        <Link
          to="edit_personal_info"
          replace
          className="btn btn-primary btn-sm ml-auto text-base-100 w-52"
        >
          {t("Edit Info")}
        </Link>
      }
    >
      <div className="flex gap-5">
        <span className="text-base-200 font-bold text-sm w-28">
          {t("Name")}:
        </span>
        <span>{currentUser?.name}</span>
      </div>

      <div className="flex gap-5">
        <span className="text-base-200 font-bold text-sm w-28">
          {t("Email")}:
        </span>
        <span>{currentUser?.email}</span>
      </div>
    </Card>
  )
}
