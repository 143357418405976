import FACILITY_UPDATE from "@/api/facility/facilityUpdate";
import useSelectCurrentFacility from "@/hooks/apolloState/useSelectCurrentFacility";
import { toaster } from "@/utilities/toaster";
import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ToggleShareAllClients() {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const myFacility = useSelectCurrentFacility();
  const [updateFacility] = useMutation(FACILITY_UPDATE, {
    onError: () => {
      toaster.error(t("Error updating settings"));
      setChecked(!checked);
    },
  });

  useEffect(() => {
    setChecked(myFacility?.shareAllClients);
  }, [myFacility]);

  return (
    <div className="form-control">
      <label className="flex gap-3 p-0 label cursor-pointer">
        <span className="label-text">{t("Share Clients")}</span>
        <input
          onChange={() => {
            setChecked(!checked);
            updateFacility({
              variables: {
                shareAllClients: !checked,
              },
            });
          }}
          type="checkbox"
          className="toggle-success toggle"
          checked={checked || false}
        />
      </label>
    </div>
  );
}
