import { useCallback } from "react";
import { info } from "@/utilities/log";
import useGetLocalSessions from "../useLocalSessions";

export default function useCheckIsSessionLocal() {
  const getLocalSessions = useGetLocalSessions();
  const checkIsSessionLocal = useCallback(
    async (_session) => {
      info("[useIsSessionLocal] started ", _session);
      const localSessions = await getLocalSessions();

      const result =
        localSessions?.filter((session) => {
          return session?.filePath.split(_session.sessionID)?.[1] === ".xsn";
        }).length > 0;

      info("[useIsSessionLocal] completed ", result);
      return result;
    },
    [getLocalSessions]
  );

  return checkIsSessionLocal;
}
