import { emptyArr } from "@/utilities/empties";
import sortAsObject from "@/utilities/sortAsObject";
import { useMemo } from "react";

export default function useLocalSorting(data = emptyArr, sort) {
  const sorted = useMemo(() => {
    if (data.length === 0) return data;

    const copy = [...data];
    const asObject = sortAsObject(sort);

    Object.keys(asObject)?.map((key) => {
      copy.sort((a, b) =>
        asObject[key] === 1 ? asc(a[key], b[key]) : desc(a[key], b[key])
      );
    });

    return copy;
  }, [data, sort]);

  return sorted;
}

function asc(a, b) {
  return a?.localeCompare(b, "en", { numeric: true });
}

function desc(a, b) {
  return b?.localeCompare(a, "en", { numeric: true });
}
