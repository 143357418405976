import React from "react";

// NOTE: use these in place of empty types in components and hooks
// to prevent needless rerenders from creating new empties each time
export const emptyObj = {};
export const emptyArr = [];
export const noop = () => {};
export const EmptyComponent = React.forwardRef((_, ref) => {
  return <span ref={ref}></span>;
});
