import { appWindow } from "@tauri-apps/api/window";

import { FaMinus, FaRegSquare, FaTimes } from "react-icons/fa";
import useTryCloseWindow from "../hooks/useTryCloseWindow";
import { useSelector } from "react-redux";
import { selectPlatform } from "@/redux/environment/selectors";

const PLATFORM = {
  windows: "windows",
  win32: "win32",
};

export default function WindowsTitleBar() {
  const currentPlatform = useSelector(selectPlatform);
  const tryCloseWindow = useTryCloseWindow();

  if (
    currentPlatform !== PLATFORM.win32 &&
    currentPlatform !== PLATFORM.windows
  )
    return null;

  return (
    <div
      data-tauri-drag-region
      className="WindowsTitleBar right-0 left-0 z-[100] flex select-none justify-end"
    >
      <button
        onClick={() => appWindow.minimize()}
        className="btn-ghost btn-sm flex items-center justify-center rounded-none text-white"
      >
        <FaMinus size={12} />
      </button>

      <button
        onClick={() => appWindow.toggleMaximize()}
        className="btn-ghost btn-sm flex items-center justify-center rounded-none text-white"
      >
        <FaRegSquare size={12} />
      </button>

      <button
        onClick={tryCloseWindow}
        className="btn-ghost btn-sm flex items-center justify-center rounded-none text-white"
      >
        <FaTimes size={12} />
      </button>
    </div>
  );
}
