import { differenceInMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { toaster } from "@/utilities/toaster";
import { useLocation } from "react-router-dom";
import useCheckIsOnline from "./useCheckIsOnline";
import { useTranslation } from "react-i18next";

const POLLING_INTERVAL = 10 * 1000;

export default function useMonitorIsOnline() {
  const { t } = useTranslation();
  const location = useLocation();
  const checkIsOnline = useCheckIsOnline();
  const [_, setToastID] = useState(new Date().toISOString());

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        await checkIsOnline();
      } catch (e) {
        !location.pathname?.includes("/offline") &&
          setToastID((prev) => {
            if (differenceInMinutes(new Date(), new Date(prev)) > 0) {
              toaster.error(t("Check your internet connection..."), {
                id: "checkConnection",
              });
              return new Date().toISOString();
            }

            return prev;
          });
      }
    }, POLLING_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [location, checkIsOnline, t]);
}
