import { info } from "@/utilities/log";
import { invoke } from "@tauri-apps/api";
import { BaseDirectory, exists } from "@tauri-apps/api/fs";
import { useCallback } from "react";

export default function useUnzip() {
  const unzip = useCallback(async (fromPath, toPath) => {
    info("[useUnzip] started ", fromPath, " <<>> ", toPath);
    const pathExists = await exists(fromPath, { dir: BaseDirectory.AppConfig });

    if (!pathExists) {
      info("[useUnzip NO FILE FOUND...");
      return;
    }

    await invoke("unzip_files", {
      fromPath,
      toPath,
    });

    info("[useUnzip] completed ");
  }, []);

  return unzip;
}
