import { invoke } from "@tauri-apps/api/tauri";

export async function error(...args) {
  console.error(...args);

  await invoke("log_error", { message: args.join("") });
}

export async function info(...args) {
  console.info(...args);

  await invoke("log_info", { message: args.join("") });
}

export async function trace(...args) {
  console.trace(...args);

  await invoke("log_trace", { message: args.join("") });
}
