import useGetLocalXsns from "@/hooks/integration/useGetLocalXsns/useGetLocalXsns";
import { DATA_PATH } from "@/utilities/paths/paths";
import { listen } from "@tauri-apps/api/event";
import { useCallback, useEffect } from "react";
import useCloseSession from "../useCloseSession";
import useCloseSessionOffline from "../useCloseSessionOffline";
import useCheckIsOnline from "@/hooks/account/useCheckIsOnline";
import { useSelector } from "react-redux";
import useReadMetaFile from "@/hooks/io/useReadMetaFile";
import { info } from "@/utilities/log";
import { selectSyncingSessions } from "@/redux/sessions/selectors";
import { selectIsLoggedIn } from "@/redux/account/selectors";
import { DEFAULT_SETTINGS_SESSION_ID } from "@/utilities/constants";
import useOfflineMeta from "@/hooks/io/useOfflineMeta";
import useRemoveSession from "@/hooks/io/useRemoveSession";
import { useTranslation } from "react-i18next";
import { toaster } from "@/utilities/toaster";
import getSessionPaths from "@/utilities/paths/getSessionPaths";
import { FILE_EXTENSION } from "@/constants";

export default function useSessionClosedHandler() {
  const checkIsOnline = useCheckIsOnline();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const closeSession = useCloseSession();
  const closeSessionOffline = useCloseSessionOffline();
  const syncingSessions = useSelector(selectSyncingSessions);
  const readMetaFile = useReadMetaFile();
  const removeSession = useRemoveSession();
  const { t } = useTranslation();
  const { refetch: refetchOfflineSessions } = useGetLocalXsns(
    DATA_PATH.OFFLINE
  );
  const { refetch: refetchOfflineMeta } = useOfflineMeta();
  const refetchAll = useCallback(async () => {
    await refetchOfflineSessions();
    await refetchOfflineMeta();
  }, [refetchOfflineSessions, refetchOfflineMeta]);
  const handler = useCallback(
    async (event) => {
      const { sessionID } = event.payload;

      info("[useSessionClosedHandler] event received", sessionID);
      const isOnline = await checkIsOnline();
      const meta = await readMetaFile(sessionID);
      const { activePath } = await getSessionPaths({
        sessionID,
        extension: FILE_EXTENSION.XSN,
      });

      info(
        "[useSessionClosedHandler] syncingSessions ",
        JSON.stringify(syncingSessions)
      );
      info(
        "[useSessionClosedHandler] active promise for syncing session ",
        syncingSessions[sessionID]?.promise
      );
      // if xsn is syncing it will store it's promise in redux
      // this waits for syncing to complete before closing the session
      sessionID !== DEFAULT_SETTINGS_SESSION_ID &&
        (await syncingSessions[sessionID]?.promise);

      // if this was a test session then delete it
      if (meta?.isTestSession) {
        await removeSession(sessionID, activePath);
        await refetchAll();
        toaster.success(t("Test session was deleted"));

        return;
      }

      if (isOnline && !meta?.offline && isLoggedIn) {
        await closeSession(sessionID);
      } else {
        await closeSessionOffline(sessionID);
      }

      await refetchAll();
    },
    [
      isLoggedIn,
      syncingSessions,
      readMetaFile,
      closeSession,
      closeSessionOffline,
      checkIsOnline,
      refetchAll,
      removeSession,
      t,
    ]
  );

  return handler;
}
