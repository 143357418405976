import { RootContext } from "@/context/rootContext"
import {
  selectFilterClientIDs,
  selectFilterGroups,
  selectFilterHideDisabledClients,
  selectFilterPrivateIDs,
  selectFilterUsers,
  selectLimit,
  selectOffset,
  selectSearch
} from "@/redux/listFilter/selectors"
import { selectSortClientsAsObject } from "@/redux/ui/selectors"
import { useContext, useEffect } from "react"
import { useSelector } from "react-redux"

export default function useClientManyQuery() {
  const { clientManyQuery } = useContext(RootContext)
  const offset = useSelector(selectOffset)
  const limit = useSelector(selectLimit)
  const filterPrivateIDs = useSelector(selectFilterPrivateIDs)
  const filterClientIDs = useSelector(selectFilterClientIDs)
  const filterGroups = useSelector(selectFilterGroups)
  const filterUsers = useSelector(selectFilterUsers)
  const filterHideDisabledClients = useSelector(selectFilterHideDisabledClients)
  const search = useSelector(selectSearch)
  const _sortAsObject = useSelector(selectSortClientsAsObject)
  const [clientMany, { data, loading, refetch, previousData, fetchMore }] =
    clientManyQuery
  const pageInfo = (data ?? previousData)?.clientMany?.pageInfo
  const clients = (data ?? previousData)?.clientMany?.payload

  // fetch initial clients, this query is moved up the tree as a useLazyQuery to allow refetching with
  // existing variables in other hooks
  // TODO: find a better way to refetch with having to keep this query way up the tree
  useEffect(() => {
    clientMany({
      variables: {
        filter: { search, showDisabled: !filterHideDisabledClients },
        sort: _sortAsObject,
        clientIDs: filterClientIDs?.map((option) => option.clientID),
        privateIDs: filterPrivateIDs?.map((option) => option.value),
        groups: filterGroups?.map((option) => option.value),
        users: filterUsers?.map((option) => option.cognitoID),
        offset,
        limit
      }
    })
  }, [
    search,
    _sortAsObject,
    clientMany,
    data,
    filterGroups,
    filterPrivateIDs,
    filterUsers,
    filterClientIDs,
    filterHideDisabledClients,
    limit,
    offset
  ])

  return { pageInfo, clients, refetch, fetchMore, loading }
}
