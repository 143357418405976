import { gql } from "@apollo/client";
import FACILITY_FRAGMENT from "../facility/facilityFragment";

const FACILITY_LIST = gql`
  query FACILITY_LIST {
    facilityList {
      ...FacilityFragment
    }
  }

  ${FACILITY_FRAGMENT}
`;

export default FACILITY_LIST;
