import CLIENT from "@/api/client/clientFragment";
import { useApolloClient } from "@apollo/client";

export default function useSelectClient(clientID) {
  const apolloClient = useApolloClient();
  const client = apolloClient.readFragment({
    id: `Client:{"clientID":"${clientID}"}`,
    fragmentName: "ClientFragment",
    fragment: CLIENT,
  });

  return client;
}
