import { forwardRef, useMemo, useState } from "react"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import { useTranslation } from "react-i18next"
import { Input } from "./Input/Input"
import { Menu } from "./Menu/Menu"
import { MenuList } from "./MenuList/MenuList"
import { Control } from "./Control/Control"
import { Option } from "./Option/Option"
import { SingleValue } from "./SingleValue/SingleValue"
import SelectContainer from "./SelectContainer/SelectContainer"
import { ValueContainer } from "./ValueContainer/ValueContainer"
import { DropdownIndicator } from "./DropdownIndicator/DropdownIndicator"
import { ClearIndicator } from "./ClearIndicator/ClearIndicator"
import { IndicatorSeparator } from "./IndicatorSeparator/IndicatorSeparator"
import MultiValue from "./MultiValue/MultiValue"

const SuperSelect = forwardRef(
  (
    {
      creatable,
      className,
      createableLabel,
      placeholder = "",
      rollover,
      isMulti,
      ...props
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation()

    const sharedProps = useMemo(
      () => ({
        ...props,
        placeholder,
        className: `super-select-container ${className}`,
        classNamePrefix: "super-select",
        rollover,
        isMulti,
        closeMenuOnSelect: !isMulti,
        blurInputOnSelect: false,
        menuIsOpen: isOpen,
        onMenuOpen: () => setIsOpen(true),
        onMenuClose: () => {
          setTimeout(() => setIsOpen(false), 100)
        },
        components: {
          Input,
          Menu,
          MenuList,
          Control,
          Option,
          SingleValue,
          SelectContainer,
          MultiValue,
          ValueContainer,
          DropdownIndicator,
          ClearIndicator,
          IndicatorSeparator
        }
      }),
      [className, placeholder, props, rollover, isMulti, isOpen]
    )

    return creatable ? (
      <CreatableSelect
        ref={ref}
        {...sharedProps}
        placeholder={t("Search or Create New")}
        onChange={creatable}
        formatCreateLabel={() => {
          return createableLabel
        }}
        loadingMessage={() => t("Loading...")}
        noOptionsMessage={() => t("No options")}
      />
    ) : (
      <Select
        ref={ref}
        {...sharedProps}
        loadingMessage={() => t("Loading...")}
        noOptionsMessage={() => t("No options")}
      />
    )
  }
)

export default SuperSelect
