import useCompareSessions from "@/hooks/integration/useCompareSessions/useCompareSessions";
import { selectCheckedRows } from "@/redux/table/selectors";
import { clearCheckedRows } from "@/redux/table/slice";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

export default function CompareSessionsButton({ className, downloadDesktopSettings }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const checkedRows = useSelector(selectCheckedRows);
  const compareSessions = useCompareSessions();

  return (
    <div
      data-tip={t("Select two sessions to compare them")}
      className={`${checkedRows?.length < 2 && "tooltip tooltip-left"}`}
    >
      <button
        onClick={async () => {
          await compareSessions(checkedRows, undefined, downloadDesktopSettings);

          dispatch(clearCheckedRows());
        }}
        className={`btn-sm btn mr-3 ${
          checkedRows.length == 2 ? "btn-success" : "btn-disabled bg-neutral"
        } ${className}`}
      >
        {t("Compare")}
      </button>
    </div>
  );
}
