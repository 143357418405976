import useProgressInterval from "@/hooks/io/useProgressInterval";
import { useTranslation } from "react-i18next";
import { FaCloudDownloadAlt } from "react-icons/fa";
import useOpenReport from "./hooks/useOpenReport";
import useDownloadReport from "./hooks/useDownloadReport";

export default function DownloadSessionReportButton({
  path,
  session,
  buttonClassName,
}) {
  const { t } = useTranslation();
  const { sessionID } = session;
  const { start, stop, progress } = useProgressInterval();
  const [openReport, { opening: openingReport }] = useOpenReport({
    session,
    path,
    startProgress: start,
    stopProgress: stop,
  });
  const [downloadReport, { opening: downloadingReport }] = useDownloadReport({
    session,
    startProgress: start,
    stopProgress: stop,
  });
  const opening = downloadingReport || openingReport;

  return (
    <div className="flex items-center justify-center h-100">
      {opening ? (
        <button
          disabled
          className={`btn-accent btn-xs btn btn-outline ${buttonClassName}`}
        >
          {t(`Opening...`)}
        </button>
      ) : progress[sessionID] ? (
        <progress
          className="w-24 progress progress-success"
          value={progress[sessionID] || 0}
          max="100"
        />
      ) : (
        <div className="flex">
          <button
            className={`btn-success btn-xs btn btn-outline border-r-0 rounded-l-md rounded-r-none ${buttonClassName}`}
            onClick={openReport}
          >
            {t("Open")}
          </button>

          <button
            className={`btn-success btn-xs btn border-l-0 rounded-r-md rounded-l-none ${buttonClassName}`}
            onClick={downloadReport}
          >
            <FaCloudDownloadAlt className="text-white" size={12} />
          </button>
        </div>
      )}
    </div>
  );
}
