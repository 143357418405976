import i18n from "./i18n";

export const extraTranslations = {
  "Average Stance": i18n.t("Average Stance"),
  "Trim Stance": i18n.t("Trim Stance"),
  STANDARD: i18n.t("STANDARD"),
  ADMIN: i18n.t("ADMIN"),
  XSENSOR_ADMIN: i18n.t("XSENSOR_ADMIN"),
  CANCEL: i18n.t("CANCEL"),
  SEND: i18n.t("SEND"),
  LOGIN: i18n.t("LOGIN"),
  "Sessions synced": i18n.t("Sessions synced"),
  Offline: i18n.t("Offline"),
  "Select Role": i18n.t("Select Role"),
  "Enable User": i18n.t("Enable User"),
  "Create User": i18n.t("Create User"),
};
