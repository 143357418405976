import { RootContext } from "@/context/rootContext";
import { useCallback, useContext } from "react";
import { SESSION_VERSION } from "../constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import getRootSessionID from "@/utilities/paths/getRootSessionID";
import { toaster } from "@/utilities/toaster";

export default function useSubmit(form, session, sessionInsert) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { sessionManyQuery } = useContext(RootContext);
  const [_, { refetch }] = sessionManyQuery;
  const { getValues } = form;

  const sessionID = session?.sessionID;
  const submit = useCallback(async () => {
    const { rootID } = getRootSessionID(sessionID);
    const update = {
      sessionID: session?.sessionID,
      parentID: session?.parentID,
      rootID,
      clientID: getValues("client")?.clientID,
      sessionType: getValues("sessionType")?.value,
      notes: getValues("notes"),
      version: SESSION_VERSION,
    };

    await sessionInsert({ variables: update });
    refetch?.();

    toaster.success(t("Session is updated"));
    navigate("../");
  }, [getValues, navigate, sessionID, session, refetch, sessionInsert, t]);

  return submit;
}
