import { error } from "@/utilities/log";
import { toaster } from "@/utilities/toaster";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function useSubmit({ createUser, refetch, getValues, reset }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const submit = useCallback(async () => {
    try {
      const { data } = await createUser({
        variables: {
          name: getValues("name"),
          email: getValues("email"),
          password: getValues("password"),
          role: getValues("role")?.value,
          facility: getValues("facility")?.id,
          clients: getValues("clients")?.map((client) => client.clientID),
        },
      });

      refetch();

      toaster.success(
        `${t("User")} ${data?.userCreate?.name} ${t("was created!")}`
      );

      reset();

      navigate("../");
    } catch (e) {
      error("problem here", e);
      toaster.error(t("Problem Creating User"));
    }
  }, [getValues, createUser, refetch, reset, navigate, t]);

  return submit;
}
