import { useCallback, useEffect, useState } from "react";
import useCurrentUser from "../useCurrentUser/useCurrentUser";

export function useLazyHasPermission() {
  const currentUser = useCurrentUser();
  const hasPermission = useCallback(
    (permissions) => {
      if (typeof permissions === "object")
        return permissions?.whitelist.every((key) =>
          currentUser?.currentRole?.allowedOperations?.includes(key)
        );

      return currentUser?.currentRole?.allowedOperations?.includes(permissions);
    },
    [currentUser]
  );

  return hasPermission;
}

export function useHasPermission(permission) {
  const hasPermission = useLazyHasPermission();
  const [has, setHas] = useState(false);

  useEffect(() => {
    setHas(hasPermission(permission));
  }, [permission, hasPermission]);

  return has;
}
