import { useMemo } from "react"
import LastUsedSessionCell from "../columns/LastUsedSessionCell/LastUsedSessionCell"
import Actions from "../columns/Actions/Actions"
import LastUsedSessionHeader from "../columns/LastUsedSessionHeader/LastUsedSessionHeader"
import Fullname from "../columns/Fullname/Fullname"
import { useTranslation } from "react-i18next"
import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser"
import { ROLE } from "@/views/UserList/constants"
import ClinicianAvatars from "../ClinicianAvatars/ClinicianAvatars"

export default function useColumnScaffold() {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const scaffold = useMemo(
    () => [
      {
        id: "fullname",
        accessorKey: "fullname",
        header: t("Name"),
        cell: ({ row: { original } }) => <Fullname client={original} />,
        meta: { className: "grow" }
      },

      {
        id: "privateID",
        accessorKey: "privateID",
        header: t("Client ID"),
        cell: ({ row: { original } }) => (
          <div className="truncate">
            {currentUser?.role?.name === ROLE.XSENSOR_ADMIN
              ? original?.clientID
              : original?.privateID}
          </div>
        ),
        size: 200
      },

      {
        id: "groups",
        accessorKey: "groups",
        header: t("Groups"),
        cell: (info) => {
          const clone = [...info.getValue()]
          const groups = clone?.sort()

          return <div className="truncate">{groups?.join(", ")}</div>
        },
        meta: {
          className: "justify-center grow"
        }
      },

      {
        id: "numberOfSessions",
        accessorKey: "numberOfSessions",
        header: () => <span>{t("Number Of Sessions")}</span>,
        cell: (info) => info.getValue(),
        size: 250,
        meta: {
          className: "justify-center"
        }
      },

      {
        id: "users",
        accessorKey: "users",
        header: t("Clinicians"),
        cell: (info) => {
          return <ClinicianAvatars users={info.getValue()} />
        },
        size: 280,
        meta: {
          className: "!overflow-visible"
        }
      },

      {
        id: "latestSession.lastUpdated",
        accessorKey: "latestSession?.lastUpdated",
        header: () => <LastUsedSessionHeader />,
        cell: ({ row: { original } }) => (
          <LastUsedSessionCell client={original} />
        ),
        size: 300,
        meta: {
          className: "py-0 !overflow-visible"
        }
      },

      {
        id: "actions",
        accessorKey: "name",
        header: t("Edit"),
        cell: ({ row: { original } }) => <Actions client={original} />,
        enableSorting: false,
        enableResizing: false,
        size: 120
      }
    ],
    [t, currentUser]
  )

  return scaffold
}
