import { gql } from "@apollo/client";
import FACILITY_FRAGMENT from "./facilityFragment";

const MY_FACILITY = gql`
  query MY_FACILITY {
    myFacility {
      ...FacilityFragment
    }
  }

  ${FACILITY_FRAGMENT}
`;

export default MY_FACILITY;
