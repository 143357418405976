import { useMemo } from "react";

export default function useCollapseToSubArray(sessions) {
  const _sessions = sessions.map((session) => {
    return { ...session, children: null };
  });
  const collapsed = useMemo(() => {
    return _sessions.reduce((acc, session) => {
      const parents = _sessions.filter(
        (s) =>
          s.sessionID === session.parentID ||
          session.parentIDs?.includes(s.sessionID)
      );

      if (
        (!session.parentID && !session.parentIDs) ||
        !parents ||
        parents.length === 0
      ) {
        acc.push(session);

        return acc;
      }

      for (const parent of parents) {
        if (parent.children) {
          parent.children.push(session);
        } else {
          parent.children = [session];
        }
      }

      return acc;
    }, []);
  }, [_sessions]);

  return collapsed;
}
