import { useState, useCallback, useEffect, useMemo, useRef } from "react";

export default function useScrollHandler() {
  const [isScrollable, setIsScrollable] = useState(false);
  const [isBottom, setIsBottom] = useState(false);
  const ref = useRef(null);
  const onScroll = useCallback((e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    setIsBottom(scrollTop + clientHeight >= scrollHeight);
  }, []);
  const toggleScroll = useCallback(() => {
    if (!ref.current) return;

    if (isBottom) {
      ref.current.scrollTop = 0;
    } else {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [isBottom]);
  const scrollerProps = useMemo(() => {
    return {
      onScroll,
      ref,
    };
  }, [onScroll]);

  useEffect(() => {
    if (!ref.current) return;

    const { scrollHeight, clientHeight } = ref.current;

    setIsScrollable(scrollHeight > clientHeight);
  }, []);

  return { toggleScroll, isBottom, scrollerProps, isScrollable };
}
