import getSessionIDSegments from "./getSessionIDSegments";

export default function getRootSessionID(sessionID) {
  const segments = getSessionIDSegments(sessionID);
  // remove trailing "-"
  const trimmed = segments?.map((segment) => {
    const trimEnd =
      segment[segment.length - 1] === "-" ? segment.slice(0, -1) : segment;
    const trimStart = trimEnd[0] === "-" ? trimEnd.slice(1) : trimEnd;

    return trimStart;
  });

  // remove the last segment, which is the current exported session portion
  trimmed.pop();
  // remove the first segment, which is the root session ID
  const rootID = trimmed.shift();

  return { rootID, trimmed };
}
