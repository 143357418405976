import { useEffect } from "react";
import usePlatform from "./usePlatform";
import { mockIPC, mockWindows } from "@tauri-apps/api/mocks";

export default function usePrepareForWeb() {
  const platform = usePlatform();

  useEffect(() => {
    if (platform.isWeb) {
      mockIPC((cmd, args) => {
        // don't use info or error as they cause an invoke and create an infinite loop
        console.info("MOCK EVENT: ", Object.values(args));
      });
      mockWindows("main");
    }
  }, [platform]);
}
