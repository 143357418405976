import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"

export default function Actions({ user }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currentUser = useCurrentUser()
  const isDisabled = !user.enabled

  return (
    <div className="flex justify-center">
      <button
        disabled={isDisabled}
        className="text-decoration-none btn-ghost btn-xs btn text-success disabled:text-primary-content"
        onClick={() => navigate(`edit/${user.cognitoID}`)}
      >
        {t("Edit")}
      </button>

      <div className="divider divider-horizontal m-0"></div>

      <button
        disabled={isDisabled}
        className="text-decoration-none btn-ghost btn-xs btn text-success disabled:text-primary-content"
        onClick={() => navigate(`send_reset_password_email/${user.cognitoID}`)}
      >
        {t("Reset Password")}
      </button>

      <div className="divider divider-horizontal m-0"></div>

      <Link
        className={`text-decoration-none btn-ghost btn-xs btn ${
          isDisabled ? "text-success" : "text-red-500"
        } ${
          currentUser?.cognitoID === user.cognitoID &&
          "btn-disabled btn-active opacity-40"
        }`}
        to={
          isDisabled
            ? `enable_user/${user.cognitoID}`
            : `disable_user/${user.cognitoID}`
        }
      >
        {isDisabled ? t("Enable") : t("Disable")}
      </Link>
    </div>
  )
}
