import useScrollHandler from "@/hooks/ui/useScrollHandler";

import ScrollButton from "./ScrollButton/ScrollButton";

export default function ScrollManager({ children, className }) {
  const { isScrollable, isBottom, scrollerProps, toggleScroll } =
    useScrollHandler();

  return (
    <div className={`ScrollManagerContainer overflow-hidden`}>
      <div
        className={`ScrollManagerScroller relative h-full overflow-y-scroll ${className}`}
        {...scrollerProps}
      >
        {isScrollable && (
          <ScrollButton isBottom={isBottom} toggleScroll={toggleScroll} />
        )}

        {children}
      </div>
    </div>
  );
}
