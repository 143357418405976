import { useCallback } from "react";
import { basename, join } from "@tauri-apps/api/path";
import { DATA_PATH } from "@/utilities/paths/paths";
import { info } from "@/utilities/log";
import useGetDesktopSettings from "@/hooks/account/useGetDesktopSettings";

export default function useMergeSettings() {
  const desktopSettings = useGetDesktopSettings();

  const mergeSettings = useCallback(
    async (settingsUpdate, appConfigPath) => {
      info("[useMergeSettings] start ", desktopSettings, settingsUpdate);

      if (!desktopSettings?.settings) {
        info("[useMergeSettings] completed early ", settingsUpdate);

        return settingsUpdate;
      }

      const mappedSettings = await Promise.all(desktopSettings?.settings?.map(async ({ file: fromPath, setting }) => {
        const fileName = await basename(fromPath);
        const toPath = await join(appConfigPath, DATA_PATH.SETTINGS_ACTIVE, fileName);

        return { file: fromPath, setting, toPath };
      }));

      info("[useMergeSettings] completed ", mappedSettings, settingsUpdate);

      return Object.values(
        Object.assign(
          ...mappedSettings.map((value) => ({[value.setting]: value})),
          ...settingsUpdate.map((value) => ({[value.setting]: value})))
      );
    },
    [desktopSettings]
  );

  return mergeSettings;
}
