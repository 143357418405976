import { useNavigate } from "react-router-dom"
import InputField from "@/components/forms/formComponents/InputField/InputField"
import SelectClients from "@/components/selects/SelectClients/SelectClients"
import SelectRole from "@/components/selects/SelectRole/SelectRole"
import { emptyArr } from "@/utilities/empties"
import FormInput from "@/components/forms/formComponents/FormInput"
import { useTranslation } from "react-i18next"
import SelectFacility from "@/components/selects/SelectFacility/SelectFacility"

export default function UserForm({
  form,
  submit,
  loading,
  title,
  hiddenFields = emptyArr,
  disabledFields = emptyArr,
  submitLabel
}) {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = form
  const navigate = useNavigate()

  return (
    <form className="flex flex-col gap-5" onSubmit={handleSubmit(submit)}>
      <h1>{title}</h1>

      <FormInput
        label={t("Name")}
        errors={errors}
        name="name"
        placeholder={t("Name")}
        {...register("name", {
          required: { value: true, message: t("Name is required") },
          min: 3,
          pattern: {
            value: /^[^-\s].*$/,
            message: t("Name cannot begin with a space")
          }
        })}
      />

      <FormInput
        label={t("Email")}
        errors={errors}
        name="email"
        type="email"
        disabled={disabledFields.includes("email")}
        placeholder={t("Email")}
        {...register("email", {
          required: { value: true, message: t("Email is required") },
          pattern: {
            value:
              /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
            message: t("Email is invalid")
          }
        })}
      />

      <InputField label={t("Role")} error={errors["role"]}>
        <SelectRole control={control} rules={{ required: true }} />
      </InputField>

      {!hiddenFields.includes("facility") && (
        <InputField
          label={t("Facility")}
          error={errors["facility"]}
          disabled={disabledFields.includes("facility")}
        >
          <SelectFacility
            control={control}
            rules={{ required: true }}
            disabled={disabledFields.includes("facility")}
          />
        </InputField>
      )}

      {!hiddenFields.includes("clients") && (
        <InputField label={t("Clients")} error={errors["clients"]}>
          <SelectClients control={control} />
        </InputField>
      )}

      <div className="flex justify-end gap-5">
        <button
          disabled={loading}
          className="btn-outline btn-sm btn px-10"
          type="button"
          onClick={() => navigate(-1, { replace: true })}
        >
          {t("Cancel")}
        </button>

        <button
          disabled={loading}
          className={`btn-success btn btn-sm px-10 `}
          type="submit"
        >
          {loading ? (
            <span className="loading loading-ring loading-md text-neutral"></span>
          ) : (
            submitLabel
          )}
        </button>
      </div>
    </form>
  )
}
