import { components } from "react-select";

export default function SelectContainer(props) {
  return (
    <components.SelectContainer
      {...props}
      className={`${props.isDisabled && "!cursor-not-allowed"}`}
    />
  );
}
