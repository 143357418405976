export const SEARCHABLE_FIELDS = [
  "sessionID",
  "sessionType",
  "exportType",
  "notes",
];

export const COLUMN_SIZES = {
  reconcile: {
    en: 265,
    de: 350,
  },
};
