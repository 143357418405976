import { info } from "@/utilities/log";
import {
  BaseDirectory,
  copyFile,
  removeFile,
  exists,
} from "@tauri-apps/api/fs";
import { useCallback } from "react";

export default function useMoveFile() {
  const moveFile = useCallback(async (from, to) => {
    info("[useMoveFile] started ");
    const fileExists = await exists(from, { dir: BaseDirectory.App });

    if (!fileExists) {
      info("[useMoveFile] NO FILE FOUND...");
      return;
    }

    await copyFile(from, to, { dir: BaseDirectory.App });
    await removeFile(from, { dir: BaseDirectory.App });

    info("[useMoveFile] completed ");
  }, []);

  return moveFile;
}
