export const EXPORT_TYPE = {
  CSV_REPORT: "stance_stats_csv",
  PDF_REPORT: "print_report_pdf",
  TRIM_STANCE: "trim_stance",
  AVG_STANCE: "average_stance",
};

export const FILE_EXTENSION = {
  XSN: ".xsn",
  PDF: ".pdf",
  CSV: ".csv",
  JSON: ".json",
};
