import { gql } from "@apollo/client";
import USER_FRAGMENT from "./userFragment";

const MY_USER = gql`
  query MY_USER {
    myUser {
      ...UserFragment
    }
  }

  ${USER_FRAGMENT}
`;

export default MY_USER;
