import { SESSION_VERSION } from "@/components/forms/SessionForm/constants";
import { info } from "@/utilities/log";
import { getMetaPath } from "@/utilities/paths/getSessionPaths";
import { listen } from "@tauri-apps/api/event";
import { BaseDirectory, writeTextFile } from "@tauri-apps/api/fs";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLazyOfflineMeta } from "../io/useOfflineMeta";
import { addOpenSession } from "@/redux/sessions/slice";
import { FILE_EXTENSION } from "@/constants";

export default function useSessionExportedListener() {
  const dispatch = useDispatch();
  const [refreshOfflineMeta, { data: offlineMeta }] = useLazyOfflineMeta();

  useEffect(() => {
    const unlisten = listen("session_exported", async (event) => {
      info("[useSessionExported] started ", event.payload);
      const { sessionID, exportType, parentID, rootID } = event.payload;
      const metaPath = await getMetaPath(sessionID);
      const rootSession = offlineMeta?.find(
        (meta) => meta.sessionID === rootID
      );
      const meta = {
        sessionID,
        parentID,
        rootID,
        exportType,
        clientID: rootSession?.clientID,
        createdAt: new Date().toISOString(),
        version: SESSION_VERSION,
        locked: "",
        notes: "",
        extension: FILE_EXTENSION.XSN,
        offline: rootSession?.offline,
      };

      await writeTextFile(metaPath, JSON.stringify(meta), {
        dir: BaseDirectory.App,
      });

      refreshOfflineMeta();

      dispatch(addOpenSession(sessionID));
      info("[useSessionExported] complete");
    });

    return () => {
      unlisten.then((f) => f());
    };
  }, [dispatch, offlineMeta, refreshOfflineMeta]);
}
