import { join } from "@tauri-apps/api/path";
import { DATA_PATH } from "./paths";
import { removeFileExtension } from "./removeFileExtension";

export default async function getSessionPaths({
  sessionID,
  extension,
  fileName,
}) {
  const _fileName = fileName || sessionID + extension;
  const _sessionID = sessionID || removeFileExtension(_fileName);

  return {
    activePath: await join(DATA_PATH.ACTIVE, _fileName),
    offlinePath: await join(DATA_PATH.OFFLINE, _fileName),
    exportsPath: await join(DATA_PATH.EXPORTS, _fileName),
    metaPath: await getMetaPath(_sessionID),
  };
}

export async function getMetaPath(sessionID) {
  return await join(DATA_PATH.META, `${sessionID}.json`);
}
