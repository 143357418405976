import React, { forwardRef } from "react";

const InputComponent = forwardRef(({ className, ...props }, ref) => {
  return (
    <input
      {...props}
      ref={ref}
      className={`input-bordered input ${className}`}
    />
  );
});

export default InputComponent;
