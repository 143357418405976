import { gql } from "@apollo/client";
import CLIENT from "./clientFragment";

const CLIENT_CREATE = gql`
  mutation CLIENT_CREATE(
    $fullname: String!
    $groups: [String]
    $users: [ID]
    $privateID: ID!
  ) {
    clientCreate(
      fullname: $fullname
      groups: $groups
      users: $users
      privateID: $privateID
    ) {
      ...ClientFragment
    }
  }

  ${CLIENT}
`;

export default CLIENT_CREATE;
