import { useQuery } from "@apollo/client";
import SuperSelect from "../SuperSelect/SuperSelect";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CLIENT_LIST from "@/api/lists/clientList";

export default function SelectClient({ control, rules, ...props }) {
  const { t } = useTranslation();
  const { data, previousData, loading } = useQuery(CLIENT_LIST, {
    fetchPolicy: "cache-and-network",
  });
  const clients = data?.clientList || previousData?.clientList;

  return (
    <Controller
      control={control}
      name="client"
      rules={rules}
      render={({ field }) => {
        return (
          <SuperSelect
            placeholder={t("Client")}
            options={clients}
            getOptionLabel={(option) => option.fullname}
            getOptionValue={(option) => option.clientID}
            isLoading={loading}
            isDisabled={loading}
            {...props}
            {...field}
          />
        );
      }}
    />
  );
}
