import { FILE_EXTENSION } from "@/constants";
import { useLazyGetLocalXsns } from "@/hooks/integration/useGetLocalXsns/useGetLocalXsns";
import { useLazyRefreshOpenSessionsList } from "@/hooks/integration/useRefreshOpenSessionsList";
import useMoveFile from "@/hooks/io/useMoveFile/useMoveFile";
import useReadMetaFile from "@/hooks/io/useReadMetaFile";
import { removeOpenSession } from "@/redux/sessions/slice";
import { info } from "@/utilities/log";
import getSessionPaths from "@/utilities/paths/getSessionPaths";
import { DATA_PATH } from "@/utilities/paths/paths";
import { BaseDirectory, writeTextFile } from "@tauri-apps/api/fs";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

export default function useCloseSessionOffline() {
  const dispatch = useDispatch();
  const moveFile = useMoveFile();
  const [getSessions] = useLazyGetLocalXsns(DATA_PATH.OFFLINE);
  const [refreshOpenSessions] = useLazyRefreshOpenSessionsList();
  const readMetaFile = useReadMetaFile();
  const closeSession = useCallback(
    async (sessionID) => {
      info("[useCloseSessionOffline] started " + sessionID);
      const { offlinePath, activePath, metaPath } = await getSessionPaths({
        sessionID,
        extension: FILE_EXTENSION.XSN,
      });
      const meta = await readMetaFile(sessionID);

      await writeTextFile(
        metaPath,
        JSON.stringify({ ...meta, offline: true }),
        {
          dir: BaseDirectory.App,
        }
      );

      await moveFile(activePath, offlinePath);
      await getSessions();
      await refreshOpenSessions({ pause: 3000 });

      dispatch(removeOpenSession(sessionID));

      info("[useCloseSessionOffline] completed " + sessionID);
    },
    [dispatch, moveFile, getSessions, refreshOpenSessions, readMetaFile]
  );

  return closeSession;
}
