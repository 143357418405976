import SESSION_LOCK from "@/api/session/sessionLock";
import { FILE_EXTENSION } from "@/constants";
import { RootContext } from "@/context/rootContext";
import useRemoveSession from "@/hooks/io/useRemoveSession";
import { removeOpenSession } from "@/redux/sessions/slice";
import { info } from "@/utilities/log";
import getSessionPaths from "@/utilities/paths/getSessionPaths";
import { useMutation } from "@apollo/client";
import { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

export default function useCloseSession() {
  const dispatch = useDispatch();
  const { sessionManyQuery, clientManyQuery } = useContext(RootContext);
  const [_, { refetch: refetchSessions }] = sessionManyQuery;
  const [__, { refetch: refetchClients }] = clientManyQuery;
  const { pathname } = useLocation();
  const [sessionLock] = useMutation(SESSION_LOCK);
  const removeSession = useRemoveSession();
  const closeSession = useCallback(
    async (sessionID) => {
      info("[useCloseSession] started " + sessionID);
      const { activePath } = await getSessionPaths({
        sessionID,
        extension: FILE_EXTENSION.XSN,
      });

      await sessionLock({
        variables: { locked: "", sessionID },
      });

      await removeSession(sessionID, activePath);

      pathname?.includes("/sessions")
        ? refetchSessions?.()
        : refetchClients?.();

      dispatch(removeOpenSession(sessionID));

      info("[useCloseSession] completed ", sessionID);
    },
    [
      sessionLock,
      refetchSessions,
      refetchClients,
      pathname,
      removeSession,
      dispatch,
    ]
  );

  return closeSession;
}
