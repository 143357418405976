import { error } from "@/utilities/log";
import { Auth } from "aws-amplify";
import { useCallback, useState } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useLogin from "../../LoginForm/useLogin";

export default function useCompleteChangePassword(formValues) {
  const { t } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { login } = useLogin();
  const completeChangePassword = useCallback(async () => {
    try {
      setLoading(true);

      const foundUser = await Auth.signIn(
        location?.state?.username,
        location?.state?.password
      );
      await Auth.completeNewPassword(foundUser, formValues.password);
      const user = await Auth.currentAuthenticatedUser();

      await login(user);

      toaster.success(t("Account activated"));
      setLoading(false);
    } catch (e) {
      error(e);
      toaster.error(t("Error Changing Password"));
    } finally {
      setLoading(false);
    }
  }, [formValues, location, login, t]);

  return { completeChangePassword, loading };
}
