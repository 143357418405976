import { gql } from "@apollo/client";
import SESSION_FRAGMENT from "../session/sessionFragment";

const SESSION_BY_ID = gql`
  query SESSION_BY_ID($sessionID: ID!) {
    sessionById(sessionID: $sessionID) {
      ...SessionFragment
    }
  }

  ${SESSION_FRAGMENT}
`;

export default SESSION_BY_ID;
