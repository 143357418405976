export const selectLimit = (state) => state.listFilter.limit;
export const selectSearch = (state) => state.listFilter.search;
export const selectOffset = (state) => state.listFilter.offset;
export const selectFilterPrivateIDs = (state) =>
  state.listFilter.filterPrivateIDs;
export const selectFilterGroups = (state) => state.listFilter.filterGroups;
export const selectFilterUsers = (state) => state.listFilter.filterUsers;
export const selectFilterClientIDs = (state) =>
  state.listFilter.filterClientIDs;

export const selectFilterArchived = (state) => state.listFilter.filterArchived;
export const selectPageTokens = (state) => state.listFilter.pageTokens;
export const selectUserEnabled = (state) => state.listFilter.userEnabled;
export const selectFilterHideDisabledClients = (state) =>
  state.listFilter.filterHideDisabledClients;
