import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import useRequestPasswordReset from "./hooks/useRequestPasswordReset";
import FormInput from "@/components/forms/formComponents/FormInput";
import { useTranslation } from "react-i18next";

export default function RequestResetPassword() {
  const { t } = useTranslation();
  const form = useForm();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;
  const { sendResetPasswordCode } = useRequestPasswordReset();

  return (
    <form onSubmit={handleSubmit(sendResetPasswordCode)} className="w-full">
      <h3 className="font-bold text-3xl mb-8">
        {t("Send Password Reset Code")}
      </h3>

      <FormInput
        type="input"
        name="email"
        errors={errors}
        placeholder={t("Email")}
        {...register("email", {
          required: t("Email is required"),
          pattern: {
            value:
              /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
            message: t("Email is invalid"),
          },
        })}
      />

      <div className="mb-5 flex flex-1 justify-end">
        <div className="flex items-center justify-end">
          <Link className="underline" to="/reset_password_code">
            <span>{t("Already Have a Code?")}</span>
          </Link>
        </div>
      </div>

      <div className="modal-action">
        <button
          type="button"
          onClick={() => navigate("/")}
          className="btn btn-sm btn-outline"
        >
          {t("Cancel")}
        </button>
        <button type="submit" className="btn btn-sm btn-success">
          {t("Send")}
        </button>
      </div>
    </form>
  );
}
