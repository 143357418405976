import getSessionPaths from "@/utilities/paths/getSessionPaths";
import { useCallback, useMemo, useState } from "react";
import { toaster } from "@/utilities/toaster";
import { error, info } from "@/utilities/log";
import { useLazyOfflineMeta } from "./useOfflineMeta";
import { useNavigate } from "react-router-dom";
import { emptyObj } from "@/utilities/empties";
import useRemoveSession from "./useRemoveSession";
import { useTranslation } from "react-i18next";
import useSyncSession from "./useSyncSession";
import { FILE_EXTENSION } from "@/constants";
import isSessionReport from "@/utilities/isSessionReport";

export default function useReconcileSession({
  session = emptyObj,
  parentMeta,
  formMeta,
  reset,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const syncSession = useSyncSession();
  const { sessionID } = session;
  const [refetchMeta, { data: offlineMeta }] = useLazyOfflineMeta();
  const [loading, setLoading] = useState(false);
  const removeSession = useRemoveSession();
  const reconcileSessions = useMemo(() => {
    return offlineMeta?.filter((meta) => meta.sessionID?.includes(sessionID));
  }, [offlineMeta, sessionID]);
  const submit = useCallback(async () => {
    info("[useReconcileSession] started ", sessionID);
    toaster.success(t("Syncing session..."));
    setLoading(true);

    navigate("../");

    try {
      // sort the sessions so that the parents get synced before the children
      const parentFirst = reconcileSessions.sort(
        (a, b) => a.sessionID.length - b.sessionID.length
      );

      // do not be tempted to Promise.all, each parent export session must be reconciled first
      for (const meta of parentFirst) {
        info("[useReconcileSession] reconciling ", meta.sessionID);
        const { offlinePath } = await getSessionPaths({
          sessionID: meta.sessionID,
          extension: meta.extension || FILE_EXTENSION.XSN,
        });
        const reportNotes = isSessionReport(meta?.exportType) ? meta?.notes : undefined;

        const _meta = {
          ...parentMeta,
          ...meta,
          ...formMeta,
          notes: reportNotes ?? formMeta?.notes,
        };

        await syncSession(offlinePath, _meta, false);

        // cleanup files
        await removeSession(meta.sessionID, offlinePath);

        toaster.success(t("Reconciled ") + _meta.sessionID);
        info("[useReconcileSession] reconciled ", meta.sessionID);
      }

      info("[useReconcileSession] completed ");
    } catch (e) {
      toaster.error(t("Failed to reconcile ") + parentMeta.sessionID);
      error("[useReconsileSession] error ", e);
    }

    // refresh the offline meta
    refetchMeta();
    // reset the form
    reset();

    setLoading(false);

    info("[useReconcileSession] completed ", sessionID);
  }, [
    formMeta,
    parentMeta,
    reset,
    navigate,
    refetchMeta,
    sessionID,
    syncSession,
    reconcileSessions,
    removeSession,
    t,
  ]);

  return { submit, loading };
}
