import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function SessionLockedButton({ session }) {
  const { t } = useTranslation();
  const [isLockedHovered, setIsLockedHovered] = useState(false);
  const currentUser = useCurrentUser();

  if (session?.locked === currentUser?.name) {
    return (
      <div
        data-tip={t("Session is in use by you")}
        className="tooltip tooltip-left"
      >
        <button className="btn-xs btn disabled" disabled={true}>
          {t("In Use")}
        </button>
      </div>
    );
  }

  return (
    <div
      data-tip={
        session?.locked && `${t("Session is in use by")} ${session.locked}`
      }
      className="tooltip tooltip-left before:!max-w-3xl before:overflow-wrap before:whitespace-pre-line 
      before:break-words before:content-[attr(data-tip)]"
    >
      <Link
        className={`btn-warning btn-xs btn !text-warning-content`}
        to={`${session.sessionID}/force_open`}
        onMouseEnter={() => {
          setIsLockedHovered(true);
        }}
        onMouseLeave={() => setIsLockedHovered(false)}
      >
        {isLockedHovered ? t("Force Open") : t("In Use")}
      </Link>
    </div>
  );
}
