import { useCallback, useState } from "react";

const downloadProgressInterval = 400;
const downloadProgressIncrement = 3;

export default function useProgressInterval(
  progressInterval = downloadProgressInterval,
  progressIncrement = downloadProgressIncrement
) {
  const [progress, setProgress] = useState({});
  const [__, setProgressInterval] = useState({});

  const start = useCallback(
    (id) => {
      // initialize progress
      setProgress((prev) => {
        prev[id] = progressIncrement;

        return prev;
      });

      const interval = setInterval(
        () =>
          setProgress((prev) => {
            prev[id] = prev[id] + progressIncrement;

            return prev;
          }),
        progressInterval
      );

      setProgressInterval((prev) => {
        prev[id] = interval;
        return prev;
      });
    },
    [progressIncrement, progressInterval]
  );

  const stop = useCallback((id) => {
    // set to 100 so progress bar will finish
    setProgress((prev) => {
      prev[id] = 100;

      return prev;
    });

    // wait just long enough for the eye to recognize the progress bar is full
    setTimeout(() => {
      setProgressInterval((prev) => {
        prev[id] && clearInterval(prev[id]);

        return { ...prev, [id]: undefined };
      });

      setProgress((prev) => {
        return { ...prev, [id]: undefined };
      });
    }, 50);
  }, []);

  return { start, stop, progress };
}
