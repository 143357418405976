import { format } from "date-fns";
import { useMemo } from "react";
import Actions from "../Actions/Actions";
import { useTranslation } from "react-i18next";
import RowCheckExpander from "@/components/RowCheckExpander/RowCheckExpander";
import ViewNotesLink from "@/components/buttons/ViewNotesLink/ViewNotesLink";
import { COLUMN_SIZES } from "../constants";

export default function useColumnScaffold(sessions) {
  const { t, i18n } = useTranslation();
  const scaffold = useMemo(
    () => [
      {
        id: "sessionType",
        accessorKey: "sessionType",
        header: t("Type"),
        cell: ({ row: { original } }) => {
          return <RowCheckExpander original={original} />;
        },
        size: 250,
        meta: {
          cellClassName: "!justify-start",
          className: "!overflow-visible",
          isExpander: true,
        },
      },

      {
        id: "sessionID",
        accessorKey: "sessionID",
        header: t("Session ID"),
        cell: ({ row: { original } }) => original?.sessionID,
        meta: {
          className: "justify-center grow",
        },
      },

      {
        id: "createdAt",
        accessorKey: "createdAt",
        header: t("Created At"),
        cell: (info) => {
          if (!info.getValue()) return;

          return format(new Date(info.getValue()), "PPpp");
        },
        meta: {
          className: "justify-center grow",
        },
      },

      {
        id: "notes",
        accessorKey: "notes",
        header: t("Notes"),
        cell: ({ row: { original } }) => <ViewNotesLink session={original} />,
        meta: {
          className: "justify-center grow",
        },
      },

      {
        id: "reconcile",
        accessorKey: "sessionID",
        header: "",
        size: COLUMN_SIZES.reconcile[i18n?.language ?? "en"],
        cell: ({ row: { original } }) => (
          <Actions sessions={sessions} session={original} />
        ),
        enableResizing: false,
        enableSorting: false,
        meta: {
          className: "!overflow-visible",
        },
      },
    ],
    [sessions, i18n, t]
  );

  return scaffold;
}
