import { createSlice } from "@reduxjs/toolkit";
import purge, { PURGE } from "../purge";

const initialState = {
  offset: 0,
  limit: 25,
  userEnabled: true,
  // leave the first one null for the first page
  pageTokens: [null],
  filterGroups: [],
  filterUsers: [],
  filterPrivateIDs: [],
  filterClientIDs: [],
  filterArchived: true,
  filterHideDisabledClients: true,
};

export const listFilterSlice = createSlice({
  name: "LIST_FILTER",
  initialState,
  reducers: {
    setLimit: (state, { payload }) => {
      state.limit = payload;
    },
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
    setUserEnabled: (state, { payload }) => {
      state.userEnabled = payload;
    },
    setOffset: (state, { payload }) => {
      state.offset = payload;
    },
    clearOffset: (state) => {
      state.offset = initialState.offset;
    },
    setFilterPrivateIDs: (state, { payload }) => {
      state.filterPrivateIDs = payload;
    },
    setFilterGroups: (state, { payload }) => {
      state.filterGroups = payload;
    },
    setFilterUsers: (state, { payload }) => {
      state.filterUsers = payload;
    },
    setFilterClientIDs: (state, { payload }) => {
      state.filterClientIDs = payload;
    },
    setFilterArchived: (state, { payload }) => {
      state.filterArchived = payload;
    },
    setFilterHideDisabledClients: (state, { payload }) => {
      state.filterHideDisabledClients = payload;
    },
    addPageToken: (state, { payload }) => {
      state.pageTokens.push(payload);
    },
    removePageToken: (state) => {
      state.pageTokens.pop();
    },
    clearPageTokens(state) {
      state.pageTokens = [null];
    },
    resetFilters: (state, { payload }) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, purge(initialState));
  },
});

export const {
  setLimit,
  setSearch,
  setUserEnabled,
  setOffset,
  clearOffset,
  setFilterPrivateIDs,
  setFilterGroups,
  setFilterClientIDs,
  setFilterUsers,
  setFilterArchived,
  setFilterHideDisabledClients,
  addPageToken,
  clearPageTokens,
  removePageToken,
  resetFilters,
} = listFilterSlice.actions;

export default listFilterSlice;
