import { useMemo } from "react";
import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser";
import { ROLE } from "@/views/UserList/constants";
import { useQuery } from "@apollo/client";
import MY_FACILITY from "@/api/facility/myFacility";

export default function useGetDesktopSettings() {
  const { data } = useQuery(MY_FACILITY);
  const currentFacility = data?.myFacility;
  const currentUser = useCurrentUser();

  const desktopSettings = useMemo(
    () => {
      const userDefaultSettings = currentUser?.currentRole?.name === ROLE.STANDARD? currentUser?.desktopSettings : undefined;
      return userDefaultSettings ?? currentFacility?.desktopSettings;
    },
    [currentUser?.currentRole?.name, currentUser?.desktopSettings, currentFacility?.desktopSettings]
  );

  return desktopSettings;
}
