import { listen } from "@tauri-apps/api/event";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDesktopBuildDate, setDesktopVersion } from "@/redux/account/slice";
import { info } from "@/utilities/log";

export default function useInitConnectionListener() {
  const dispatch = useDispatch();

  useEffect(() => {
    const unlisten = listen("init_connection", async (event) => {
      const { id, response, desktop_version, desktop_builddate } = event.payload;

      info("[useInitConnectionListener] event recevied", id, response);

      dispatch(setDesktopVersion(desktop_version));
      dispatch(setDesktopBuildDate(desktop_builddate));
    });

    return () => {
      unlisten.then((f) => f());
    };
  }, [dispatch]);
}
