import useSendResetPasswordEmail from "@/hooks/account/useSendResetPasswordEmail"
import useSelectUser from "@/hooks/apolloState/useSelectUser"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

export default function SendResetPasswordEmail() {
  const { t } = useTranslation()
  const { userID } = useParams()
  const user = useSelectUser(userID)
  const { sendResetPasswordEmail, loading } = useSendResetPasswordEmail(user)

  return (
    <form onSubmit={sendResetPasswordEmail}>
      <h1 className="flex justify-center">{t("Send Reset Password Email")}</h1>

      <div className="my-5">
        {t("Are you sure you want to send reset password email to")}{" "}
        <span className="text-success">{user.name}</span>?
      </div>

      <div className="flex w-full justify-end">
        <button
          disabled={loading}
          className={`btn-success btn-sm btn`}
          type="submit"
        >
          {loading ? (
            <span className="loading loading-ring loading-md text-neutral"></span>
          ) : (
            t("Send")
          )}
        </button>
      </div>
    </form>
  )
}
