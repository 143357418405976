import { useCallback } from "react";
import { selectEnvironment } from "@/redux/environment/selectors";
import { useSelector } from "react-redux";
import getApiUrl from "@/utilities/getApiUrl";

export default function useGlobalSignOut() {
  const environment = useSelector(selectEnvironment);
  const url = getApiUrl(environment);
  const globalSignOut = useCallback(
    async (email) => {
      await fetch(url + "/user_global_sign_out", {
        method: "POST",
        headers: {
          // "Apollographql-Client-Version": import.meta.env.APP_VERSION,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: email.trim() }),
      });
    },
    [url]
  );

  return globalSignOut;
}
