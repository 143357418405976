import React, { forwardRef } from "react";

const TextAreaComponent = forwardRef(({ className, ...props }, ref) => {
  return (
    <textarea
      {...props}
      ref={ref}
      className={`textarea-bordered textarea ${className}`}
    />
  );
});

export default TextAreaComponent;
