import { RootContext } from "@/context/rootContext";
import {
  selectFilterArchived,
  selectLimit,
  selectOffset,
  selectSearch
} from "@/redux/listFilter/selectors";
import { selectSortSessionsAsObject } from "@/redux/ui/selectors";
import { useDebounce } from "@uidotdev/usehooks";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function useSessionManyQuery() {
  const { clientID } = useParams();
  const { sessionManyQuery } = useContext(RootContext);
  const limit = useSelector(selectLimit);
  const offset = useSelector(selectOffset);
  const search = useSelector(selectSearch);
  const filterArchived = useSelector(selectFilterArchived);
  const _sortAsObject = useSelector(selectSortSessionsAsObject);
  const [sessionMany, { data, loading, refetch, fetchMore }] = sessionManyQuery;
  const pageInfo = data?.sessionMany?.pageInfo;
  const sessions = data?.sessionMany?.payload;
  const debouncedArchived = useDebounce(filterArchived, 150);

  // fetch initial sessions, this query is moved up the tree to allow refetching with
  // existing variables in other hooks
  useEffect(() => {
    if (!clientID) return;

    sessionMany({
      variables: {
        clientID,
        filter: { search, hideArchived: debouncedArchived },
        sort: _sortAsObject,
        limit,
        offset
      }
    });
    // leave clientID out of deps to prevent bad refetch
  }, [
    search,
    _sortAsObject,
    sessionMany,
    data,
    limit,
    offset,
    debouncedArchived,
    clientID
  ]);

  return { sessions, loading, pageInfo, fetchMore, refetch };
}
