import { gql } from "@apollo/client";

const ENVIRONMENT = gql`
  fragment EnvironmentFragment on Environment {
    API_URL
    TCP_PORT
    DOWNLOAD_REPO
    COGNITO_REGION
    COGNITO_CLIENT_ID
    USER_POOL_ID
    STAGE
  }
`;

export default ENVIRONMENT;
