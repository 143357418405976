import { useCallback } from "react";
import { error, info } from "@/utilities/log";
import { Auth } from "aws-amplify";

const URL = "https://prod-fg-api.xsensorcloud.com";

export default function useGraphqlRest() {
  const fetcher = useCallback(async (query, variables) => {
    try {
      let tokens;
      try {
        tokens = await Auth.currentSession();
      } catch (e) {
        info("Not signed in");
      }

      const body = JSON.stringify({
        query,
        variables,
      });

      const result = await fetch(URL + "/graphqlRest", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: tokens?.accessToken?.jwtToken,
          "authorization-id": tokens?.idToken?.jwtToken,
        },
        body,
      });

      const res = await result?.json();

      return res;
    } catch (e) {
      error(e);
    }
  }, []);

  return fetcher;
}
