import { useState } from "react"
import { useSelector } from "react-redux"
import { selectSyncingSessionIDs } from "@/redux/sessions/selectors"
import { useTranslation } from "react-i18next"
import { selectIsLoggedIn } from "@/redux/account/selectors"
import Dropdown from "./Dropdown/Dropdown"
import WindowsTitleBar from "@/components/TitleBar/WindowsTitleBar/WindowsTitleBar"
import { FaUserCircle } from "react-icons/fa"
import usePlatform from "@/hooks/tauri/usePlatform"

export default function AccountDropdown() {
  const { isMacOs } = usePlatform()
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const syncingSessionIDs = useSelector(selectSyncingSessionIDs)

  return (
    <div className={`flex flex-col h-full ${isMacOs && "pr-5"}`}>
      <div className="relative flex">
        {isLoggedIn && (
          <button
            className="btn btn-ghost btn-sm btn-circle flex items-center justify-center"
            onClick={() => setOpen((prev) => !prev)}
          >
            <FaUserCircle size={20} />
          </button>
        )}

        <Dropdown open={open} setOpen={setOpen} />

        <WindowsTitleBar />
      </div>

      {syncingSessionIDs?.length > 0 && (
        <div
          data-tip={syncingSessionIDs.join("\r\n")}
          className="tooltip tooltip-left before:!max-w-3xl before:overflow-wrap before:whitespace-pre-line 
            before:break-words before:content-[attr(data-tip)] text-success flex justify-center "
        >
          <span className="mr-2">{t("Syncing")}</span>
          <span className="loading loading-ring loading-sm"></span>
        </div>
      )}
    </div>
  )
}
