import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { ROLE } from "@/views/UserList/constants";

const ToggleCurrentRole = forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <div className="form-control">
      <div className="flex justify-center gap-5">
        <span className="label-text">{t(ROLE.STANDARD)}</span>
        <input
          ref={ref}
          {...props}
          type="checkbox"
          className="toggle toggle-success"
        />
        <span className="label-text">{t(ROLE.ADMIN)}</span>
      </div>
    </div>
  );
});

export default ToggleCurrentRole;
