import sortAsObject from "@/utilities/sortAsObject";
import { createSelector } from "@reduxjs/toolkit";

export const selectBreadcrumbs = (state) => state.ui.breadcrumbs;
export const selectExpirationToasted = (state) => state.ui.expirationToasted;

export const selectSortSessions = (state) => state.ui.sortSessions;
export const selectSortSessionsAsObject = createSelector(
  selectSortSessions,
  sortAsObject
);

export const selectSortClients = (state) => state.ui.sortClients;
export const selectSortClientsAsObject = createSelector(
  selectSortClients,
  sortAsObject
);

export const selectSortUsers = (state) => state.ui.sortUsers;
export const selectSortUsersAsObject = createSelector(
  selectSortUsers,
  sortAsObject
);

export const selectSortOfflineSessions = (state) =>
  state.ui.sortOfflineSessions;

export const selectSortOfflineSessionsAsObject = createSelector(
  selectSortOfflineSessions,
  sortAsObject
);
