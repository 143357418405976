import ROLE_MANY from "@/api/role/roleMany";
import { useQuery } from "@apollo/client";

import SuperSelect from "../SuperSelect/SuperSelect";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function SelectRole({ control, rules, ...props }) {
  const { t } = useTranslation();
  const { loading, data } = useQuery(ROLE_MANY);

  return (
    <Controller
      control={control}
      name="role"
      rules={rules}
      render={({ field }) => {
        return (
          <SuperSelect
            placeholder={t("Role")}
            options={data?.roleMany?.map(({ name }) => ({
              value: name,
              label: t(name),
            }))}
            isLoading={loading}
            isDisabled={loading}
            {...field}
            {...props}
          />
        );
      }}
    />
  );
}
