import FACILITY_LIST from "@/api/user/facilityList"
import { useQuery } from "@apollo/client"
import { Controller } from "react-hook-form"
import SuperSelect from "../SuperSelect/SuperSelect"
import { createFilter } from "react-select"

export default function SelectFacility({ disabled, control, rules, ...props }) {
  const { data, loading } = useQuery(FACILITY_LIST, {
    fetchPolicy: "cache-and-network"
  })

  return (
    <Controller
      control={control}
      name="facility"
      rules={rules}
      render={({ field }) => {
        return (
          <SuperSelect
            isLoading={loading}
            options={data?.facilityList}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            isDisabled={disabled}
            isClearable={true}
            filterOption={createFilter({
              matchFrom: "any",
              stringify: (option) => `${option.label}`
            })}
            {...field}
            {...props}
          />
        )
      }}
    />
  )
}
