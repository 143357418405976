import useDownloadAndSave from "@/hooks/io/useDownloadAndSave/useDownloadAndSave";
import { error } from "@/utilities/log";
import toExportExtension from "@/utilities/text/toExportExtension";
import { toaster } from "@/utilities/toaster";
import { appDataDir, join } from "@tauri-apps/api/path";
import { open } from "@tauri-apps/api/shell";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

export default function useOpenReport({
  session,
  path,
  startProgress,
  stopProgress,
}) {
  const { t } = useTranslation();
  const { sessionID, exportType } = session;
  const fileName = `${sessionID}.${toExportExtension(exportType)}`;
  const [opening, setOpening] = useState(false);
  const downloadAndSave = useDownloadAndSave();
  const openReport = useCallback(async () => {
    try {
      startProgress(sessionID);
      const fullPath = await join(path, fileName);
      await downloadAndSave(fullPath);
      const appDir = await appDataDir();
      const filePath = await join(appDir, path, fileName);

      await open(filePath);

      setOpening(true);

      stopProgress(sessionID);

      setOpening(false);
    } catch (e) {
      setOpening(false);
      toaster.error(t("Failed to download session"));
      stop(sessionID);
      error("[useOpenReport]", e);
    }
  }, [
    startProgress,
    sessionID,
    path,
    fileName,
    downloadAndSave,
    stopProgress,
    t,
  ]);

  return [openReport, { opening }];
}
