import SESSION_BY_ID from "@/api/session/sessionById";
import useOpenSession from "@/hooks/integration/useOpenSession/useOpenSession";
import useDownloadAndSave from "@/hooks/io/useDownloadAndSave/useDownloadAndSave";
import useDownloadDesktopSettings from "@/hooks/io/useDownloadDesktopSettings/useDownloadDesktopSettings";
import { error } from "@/utilities/log";
import { getMetaPath } from "@/utilities/paths/getSessionPaths";
import { toaster } from "@/utilities/toaster";
import { useLazyQuery } from "@apollo/client";
import { BaseDirectory, writeTextFile } from "@tauri-apps/api/fs";
import { join } from "@tauri-apps/api/path";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function useSubmit({
  session,
  path,
  startProgress,
  stopProgress,
  forceOpen,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { sessionID } = session;
  const [sessionById] = useLazyQuery(SESSION_BY_ID, {
    fetchPolicy: "network-only",
  });
  const [opening, setOpening] = useState(false);
  const downloadAndSave = useDownloadAndSave();
  const [openSession] = useOpenSession();
  const downloadDesktopSettings = useDownloadDesktopSettings();
  const submit = useCallback(async () => {
    try {
      startProgress(sessionID);
      const metaPath = await getMetaPath(sessionID);
      const { data } = await sessionById({
        variables: { sessionID: session.sessionID },
      });

      if (data?.sessionById.locked && !forceOpen) {
        navigate(`${session.sessionID}/force_open`);
        return;
      }

      const fullPath = await join(path, `${session.sessionID}.xsn`);
      await downloadAndSave(fullPath);

      setOpening(true);

      let localMeta = {
        ...session,
      };

      // remove any fields that cannot be synced back the api when updating a session
      delete localMeta.__typename;
      delete localMeta.children;

      await writeTextFile(metaPath, JSON.stringify(localMeta), {
        dir: BaseDirectory.App,
      });
      stopProgress(sessionID);

      await openSession(session, undefined, downloadDesktopSettings);

      forceOpen && navigate(-1, { replace: true });

      setOpening(false);
    } catch (e) {
      setOpening(false);
      toaster.error(t("Failed to download session"));
      stop(sessionID);
      error("[DownloadSessionButton submit ERROR]", e);
    }
  }, [
    startProgress,
    sessionID,
    sessionById,
    session,
    forceOpen,
    downloadAndSave,
    path,
    stopProgress,
    openSession,
    downloadDesktopSettings,
    navigate,
    t,
  ]);

  return { submit, opening };
}
