import { gql } from "@apollo/client";
import SESSION_FRAGMENT from "./sessionFragment";

const SESSION_UPDATE = gql`
  mutation SESSION_UPDATE(
    $sessionID: ID!
    $parentID: ID
    $rootID: ID
    $clientID: ID
    $version: String
    $sessionType: String
    $exportType: String
    $createdAt: Date
    $notes: String
    $sensors: [String]
    $locked: String
    $archived: Boolean
  ) {
    sessionUpdate(
      sessionID: $sessionID
      parentID: $parentID
      rootID: $rootID
      clientID: $clientID
      version: $version
      sessionType: $sessionType
      exportType: $exportType
      createdAt: $createdAt
      notes: $notes
      sensors: $sensors
      locked: $locked
      archived: $archived
    ) {
      ...SessionFragment
    }
  }

  ${SESSION_FRAGMENT}
`;

export default SESSION_UPDATE;
