import { selectFilterHideDisabledClients } from "@/redux/listFilter/selectors";
import { setFilterHideDisabledClients } from "@/redux/listFilter/slice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

export default function ToggleShowDisabled() {
  const dispatch = useDispatch();
  const filterHideDisabledClients = useSelector(
    selectFilterHideDisabledClients
  );
  const { t } = useTranslation();

  return (
    <div className="flex justify-between">
      <span>{t("Hide Archived Clients")}</span>

      <input
        className="toggle toggle-success"
        checked={filterHideDisabledClients}
        type="checkbox"
        onChange={(e) =>
          dispatch(setFilterHideDisabledClients(e.target.checked))
        }
        name="filterArchived"
      />
    </div>
  );
}
