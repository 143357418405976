import randomColor from "randomcolor";
import { useMemo } from "react";
import { Link } from "react-router-dom";

const MAX_TO_SHOW = 5;

export default function ClinicianAvatars({ users }) {
  const sorted = useMemo(() => [...users]?.sort(), [users]);
  const hasMore = sorted?.length > MAX_TO_SHOW;
  const avatars = hasMore ? sorted.slice(0, MAX_TO_SHOW) : sorted;
  const remainingUsers = sorted.slice(MAX_TO_SHOW);
  const remainder = hasMore && remainingUsers?.length;

  return (
    <div className="avatar-group -space-x-4 rtl:space-x-reverse overflow-visible">
      {avatars.map((user) => {
        const color = randomColor({
          luminosity: "light",
          hue: "random",
          seed: user.id
        });
        const initials = user.name
          .split(" ")
          .map((part) => part[0].toUpperCase())
          .join("");

        return (
          <div
            key={user.id}
            data-tip={user.name}
            className="tooltip tooltip-left"
          >
            <div className="avatar border-white border-2">
              <div
                style={{ backgroundColor: color }}
                className="!flex justify-center items-center text-center w-12 h-full select-none cursor-default font-bold"
              >
                <span>{initials}</span>
              </div>
            </div>
          </div>
        );
      })}

      {remainder && (
        <Link
          className={`avatar placeholder border-2`}
          to="all_assigned_clinicians"
          state={{ users: remainingUsers }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-12 bg-white text-base-100 select-none cursor-default font-bold">
            <span>+{remainder}</span>
          </div>
        </Link>
      )}
    </div>
  );
}
