import USER_UPDATE from "@/api/user/userUpdate";
import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser";
import { error } from "@/utilities/log";
import { toaster } from "@/utilities/toaster";
import { useApolloClient, useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function useSubmit(form, refetch) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const { getValues } = form;
  const [userUpdate] = useMutation(USER_UPDATE);
  const client = useApolloClient();
  const submit = useCallback(async () => {
    setLoading(true);
    try {
      await userUpdate({
        variables: {
          cognitoID: currentUser?.cognitoID,
          facility: getValues("facility").id,
        },
      });

      await client.resetStore();

      await refetch();

      toaster.success(
        `${t("You will now see data from facility:")} ${
          getValues("facility")?.name
        }`
      );

      setLoading(false);

      navigate(-1, { replace: true });
    } catch (e) {
      setLoading(false);
      toaster.error(t("Failed to update facility"));
      error(e);
    }
  }, [getValues, userUpdate, currentUser, navigate, refetch, client, t]);

  return { submit, loading };
}
