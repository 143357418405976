import { gql } from "@apollo/client";
import SESSION_FRAGMENT from "./sessionFragment";

const SESSION_LOCK = gql`
  mutation SESSION_LOCK($sessionID: ID!, $locked: String) {
    sessionLock(sessionID: $sessionID, locked: $locked) {
      ...SessionFragment
    }
  }

  ${SESSION_FRAGMENT}
`;

export default SESSION_LOCK;
