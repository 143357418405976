import SESSION_UPDATE from "@/api/session/sessionUpdate";
import useSelectSession from "@/hooks/apolloState/useSelectSession/useSelectSession";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import useArchiveSession from "./useArchiveSession";
import useRestoreSession from "./useRestoreSession";
import { RootContext } from "@/context/rootContext";
import { useContext } from "react";

export default function ArchiveSession() {
  const { sessionID } = useParams();
  const location = useLocation();
  const archived = location.pathname.includes("restore");
  const session = useSelectSession(sessionID);
  const { t } = useTranslation();
  const [sessionUpdate] = useMutation(SESSION_UPDATE);
  const { sessionManyQuery } = useContext(RootContext);
  const [_, { refetch }] = sessionManyQuery;
  const { archive, loading: loadingArchive } = useArchiveSession(
    refetch,
    sessionID,
    sessionUpdate
  );
  const { restore, loading: loadingRestore } = useRestoreSession(
    refetch,
    sessionID,
    sessionUpdate,
    false
  );
  const loading = loadingArchive || loadingRestore;

  return (
    <div className="flex flex-col">
      <h1>
        {archived
          ? t("Are you sure you want to restore this session?")
          : t("Are you sure you want to archive this session?")}
      </h1>

      <div className="py-5 flex flex-col">
        <span className="font-bold text-md">{t("Session Notes")}</span>
        <div className="truncate">{session?.notes}</div>
      </div>

      <div className="flex justify-end gap-5">
        <Link disabled={loading} className="btn btn-sm" to="../">
          {t("Cancel")}
        </Link>

        <button
          disabled={loading}
          className={`btn btn-sm ${archived ? "btn-warning" : "btn-error"}`}
          onClick={archived ? restore : archive}
        >
          {loading ? (
            <span className="loading loading-ring loading-sm"></span>
          ) : archived ? (
            t("Restore")
          ) : (
            t("Archive")
          )}
        </button>
      </div>
    </div>
  );
}
