export const LOCAL_ENVIRONMENT = {
  API_URL: "http://localhost:8004",
  TCP_PORT: 8731,
  DOWNLOAD_REPO: "intelligent-insoles-dev-releases",

  COGNITO_REGION: "ca-central-1",
  COGNITO_CLIENT_ID: "v2r1sm8f2v21qiphfc3tmkhio",
  USER_POOL_ID: "ca-central-1_tKx5BKWFf",
  SENTRY_AUTH_TOKEN:
    "https://3e71e0ad2af45bb9d5c106a1490f112d@us.sentry.io/4506638773452800",
  STAGE: "local",
};

export const FALLBACK_PRODUCTION_ENVIRONMENT = {
  API_URL: "https://prod-fg-api.xsensorcloud.com",
  TCP_PORT: 8731,
  DOWNLOAD_REPO: "intelligent-insoles-releases",

  COGNITO_REGION: "ca-central-1",
  COGNITO_CLIENT_ID: "4fogv3b9n023dkihpjp3lgq9g9",
  USER_POOL_ID: "ca-central-1_Nm4H3ArWc",
  SENTRY_AUTH_TOKEN:
    "https://3e71e0ad2af45bb9d5c106a1490f112d@us.sentry.io/4506638773452800",
  STAGE: "production",
};
