import { gql } from "@apollo/client";
import SESSION_FRAGMENT from "./sessionFragment";

const SESSION_INSERT = gql`
  mutation SESSION_INSERT(
    $sessionID: ID!
    $parentID: ID
    $parentIDs: [ID]
    $rootID: ID
    $clientID: ID
    $version: String
    $sessionType: String
    $exportType: String
    $createdAt: Date
    $notes: String
    $sensors: [String]
    $locked: String
    $extension: String
  ) {
    sessionInsert(
      sessionID: $sessionID
      parentID: $parentID
      parentIDs: $parentIDs
      rootID: $rootID
      clientID: $clientID
      version: $version
      sessionType: $sessionType
      exportType: $exportType
      createdAt: $createdAt
      notes: $notes
      sensors: $sensors
      locked: $locked
      extension: $extension
    ) {
      ...SessionFragment
    }
  }

  ${SESSION_FRAGMENT}
`;

export default SESSION_INSERT;
