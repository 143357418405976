import { useQuery } from "@apollo/client";

import ClientIcon from "@/assets/vectors/clients.svg?react";
import CLIENT_COUNT from "@/api/client/clientCount";
import { useTranslation } from "react-i18next";

export default function ClientCount({ className }) {
  const { t } = useTranslation();
  const { data } = useQuery(CLIENT_COUNT);
  const clientCount = data?.clientCount;

  return (
    <div className={`stat ${className}`}>
      <div className="stat-figure place-self-start">
        <ClientIcon />
      </div>

      <div className="stat-title opacity-100">{t("Clients")}</div>

      <div className="stat-value font-bold mb-2">{clientCount || "0"}</div>

      {/* <div className="stat-desc opacity-100">25% more than last month</div> */}
    </div>
  );
}
