import { info } from "@/utilities/log";
import { getMetaPath } from "@/utilities/paths/getSessionPaths";
import { BaseDirectory, readTextFile } from "@tauri-apps/api/fs";
import { useCallback } from "react";

export default function useReadMetaFile() {
  const readMetaFile = useCallback(async (sessionID) => {
    info("[useReadMetaFile] started ", sessionID);
    try {
      const metaPath = await getMetaPath(sessionID);
      const metaText = await readTextFile(metaPath, {
        dir: BaseDirectory.App,
      });
      const metaJSON = JSON.parse(metaText);

      info("[useReadMetaFile] completed ", sessionID);

      return metaJSON;
    } catch (e) {
      info(
        "[useReadMetaFile] NO META FILE PRESENT FOR THIS SESSION...",
        sessionID
      );
      return null;
    }
  }, []);

  return readMetaFile;
}
