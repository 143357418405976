import { gql } from "@apollo/client";

const ROLE = gql`
  fragment RoleFragment on Role {
    id
    name
    allowedOperations
    features
  }
`;

export default ROLE;
