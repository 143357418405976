import { listen } from "@tauri-apps/api/event";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { info } from "@/utilities/log";
import useSyncDesktopSettings from "@/hooks/io/useSyncDesktopSettings/useSyncDesktopSettings";
import useCheckIsOnline from "@/hooks/account/useCheckIsOnline";
import { selectIsLoggedIn } from "@/redux/account/selectors";

export default function useDesktopSettingsSyncListener() {
  const syncDesktopSettings = useSyncDesktopSettings();
  const checkIsOnline = useCheckIsOnline();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    const unlisten = listen("user_settings_sync", async (event) => {
      const { settings } = event.payload;
      info("[useDesktopSettingsSyncListener] event recevied", settings);
      
      const isOnline = await checkIsOnline();

      if (!isOnline || !isLoggedIn) return;

      await syncDesktopSettings(settings, true);
    });

    return () => {
      unlisten.then((f) => f());
    };
  }, [checkIsOnline, isLoggedIn, syncDesktopSettings]);
}
