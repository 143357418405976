import CLIENT_UPDATE from "@/api/client/clientUpdate";
import { RootContext } from "@/context/rootContext";
import useSelectClient from "@/hooks/apolloState/useSelectClient";
import { useMutation } from "@apollo/client";
import { useCallback, useContext } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export default function DisableClient() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientID } = useParams();
  const client = useSelectClient(clientID);
  const { clientManyQuery } = useContext(RootContext);
  const [_, { refetch }] = clientManyQuery;
  const [updateClient, { loading }] = useMutation(CLIENT_UPDATE, {
    onCompleted: () => {
      refetch();

      toaster.success(t("Client Archived!"));

      navigate("../");
    },
  });
  const submit = useCallback(
    (e) => {
      e.preventDefault();
      updateClient({ variables: { clientID, disabled: true } });
    },
    [updateClient, clientID]
  );

  return (
    <form onSubmit={submit}>
      <h1 className="flex justify-center">{t("Archive Client")}</h1>

      <div className="my-5">
        <span>{t("Are you sure you want to archive client ")}</span>
        <span className="text-success">{client?.fullname}</span>?
      </div>

      <div className="flex w-full justify-end">
        <button
          disabled={loading}
          className={`btn-error btn-sm btn`}
          type="submit"
        >
          {loading ? (
            <span className="loading loading-ring loading-md text-neutral"></span>
          ) : (
            t("Archive")
          )}
        </button>
      </div>
    </form>
  );
}
