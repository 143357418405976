import { DATA_PATH } from "@/utilities/paths/paths";
import { invoke } from "@tauri-apps/api/tauri";
import { useCallback } from "react";
import { error, info } from "@/utilities/log";
import { useLazyGetLocalXsns } from "../../integration/useGetLocalXsns/useGetLocalXsns";
import useDownloadFile from "../useDownloadFile/useDownloadFile";
import { basename } from "@tauri-apps/api/path";

export default function useDownloadAndSave() {
  const [getSessions] = useLazyGetLocalXsns(DATA_PATH.ACTIVE);
  const download = useDownloadFile();
  const downloadAndSave = useCallback(
    async (fullPath) => {
      info("[useDownloadAndSave] started ", fullPath);

      try {
        const fileName = await basename(fullPath);
        const preSignedUrl = await download(fileName);

        await invoke("download_and_write", {
          url: preSignedUrl?.data?.getDownloadUrl,
          path: fullPath,
        });

        await getSessions();

        info("[useDownloadAndSave] completed ", fullPath);
      } catch (e) {
        error("[useDownloadAndSave] error", e);
        throw e;
      }
    },
    [download, getSessions]
  );

  return downloadAndSave;
}
