import isSessionReport from "@/utilities/isSessionReport";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

export default function Actions({ session }) {
  const { t } = useTranslation();
  const { locked, sessionID, archived, rootID, exportType } = session;
  const isReport = isSessionReport(exportType);
  const navigate = useNavigate();

  return (
    <div className={`w-full flex justify-center gap-2`}>
      <div
        data-tip={t("Please close the session to edit it.")}
        className={`${locked && "tooltip tooltip-left"}`}
      >
        <button
          disabled={locked}
          className={`btn-ghost btn-xs btn ${
            locked ? "text-base-200 btn-disabled" : "text-success"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`edit/${session.sessionID}`);
          }}
        >
          {t("Edit")}
        </button>
      </div>

      <div
        data-tip={t("Please archive or restore the parent session.")}
        className={`${rootID && "tooltip tooltip-left"}`}
      >
        <Link
          disabled={locked || rootID || isReport}
          className={`btn-ghost btn-xs btn ${
            locked
              ? "!text-base-200 btn-disabled"
              : archived
                ? "text-success"
                : "text-error"
          } `}
          to={`${archived ? "restore" : "archive"}/${sessionID}`}
        >
          {archived ? t("Restore") : t("Archive")}
        </Link>
      </div>
    </div>
  );
}
