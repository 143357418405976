import { selectCheckedRows } from "@/redux/table/selectors";
import { setCheckedRow } from "@/redux/table/slice";

import { useDispatch, useSelector } from "react-redux";

export default function SelectRowCheckbox({ session }) {
  const dispatch = useDispatch();
  const checkedRows = useSelector(selectCheckedRows);

  return (
    <div className="flex items-center">
      <input
        onChange={(e) => {
          dispatch(
            setCheckedRow({
              session,
              isChecked: e.target.checked,
            })
          );
        }}
        type="checkbox"
        checked={checkedRows?.includes(session)}
        className="checkbox-accent checkbox checkbox-xs"
      />
    </div>
  );
}
