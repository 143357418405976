import GROUP_LIST from "@/api/lists/groupList";
import { useQuery } from "@apollo/client";

import SuperSelect from "../SuperSelect/SuperSelect";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const SELECT_GROUPS_TOOLTIP =
  "To create a new group simply start typing the name then click create";

export default function SelectGroups({ control, rules, ...props }) {
  const { t } = useTranslation();
  const { data, previousData, loading } = useQuery(GROUP_LIST, {
    fetchPolicy: "cache-and-network",
  });
  const groups = data?.groupList || previousData?.groupList;

  return (
    <Controller
      control={control}
      name="groups"
      rules={rules}
      render={({ field }) => {
        return (
          <SuperSelect
            placeholder={t("Groups")}
            options={groups?.map((group) => ({
              value: group,
              label: group,
            }))}
            createableLabel={t("Create")}
            isLoading={loading}
            closeMenuOnSelect={false}
            isMulti
            isDisabled={props?.disabled}
            isClearable={true}
            {...field}
            {...props}
          />
        );
      }}
    />
  );
}
