import useSelectClient from "@/hooks/apolloState/useSelectClient";
import { useLazyOfflineMeta } from "@/hooks/io/useOfflineMeta";
import { useCallback } from "react";
import { useParams } from "react-router-dom";

export default function useGetDefaultFormValues(session, setSession) {
  const { sessionID } = useParams();
  const [fetchMeta] = useLazyOfflineMeta();
  const cachedClient = useSelectClient(session?.clientID);
  const getDefaultFormValues = useCallback(async () => {
    const data = await fetchMeta();
    const session = data.find((meta) => meta.sessionID === sessionID);
    setSession(session);

    const defaults = {
      ...session,
      client: session?.clientID &&
        cachedClient && {
          fullname: cachedClient?.fullname,
          clientID: session?.clientID,
        },
      sessionType: session?.sessionType && {
        value: session?.sessionType,
        label: session?.sessionType,
      },
    };

    return defaults;
  }, [sessionID, setSession, cachedClient, fetchMeta]);

  return getDefaultFormValues;
}
