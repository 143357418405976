import { setIsOnline } from "@/redux/account/slice";
import { selectEnvironment } from "@/redux/environment/selectors";
import getApiUrl from "@/utilities/getApiUrl";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function useCheckIsOnline() {
  const dispatch = useDispatch();
  const environment = useSelector(selectEnvironment);
  const url = getApiUrl(environment);
  const checkIsOnline = useCallback(async () => {
    try {
      await fetch(url + "/is_online", {
        headers: {
          "Apollographql-Client-Version": import.meta.env.APP_VERSION,
        },
      });

      dispatch(setIsOnline(true));
      return true;
    } catch (e) {
      dispatch(setIsOnline(false));
      return false;
    }
  }, [dispatch, url]);

  return checkIsOnline;
}
