import { error } from "@/utilities/log";
import { invoke } from "@tauri-apps/api/tauri";
import { useCallback } from "react";

export default function useIsDesktopChannelOpen() {
  const checkDesktopChannel = useCallback(async () => {
    try {
      await invoke("get_desktop_status");

      return true;
    } catch (e) {
      error("[useIsDesktopChannelOpen] error", e);

      return false;
    }
  }, []);

  return checkDesktopChannel;
}
