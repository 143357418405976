export default function checkCognitoPasswordRequirements(value) {
  if (value.length < 8 || value.length > 99) {
    return "Password must be 8-99 characters long";
  }
  if (!/\d/.test(value)) {
    return "Password must contain at least one number";
  }
  if (!/[a-z]/.test(value)) {
    return "Password must contain at least one lowercase letter";
  }
  if (!/[A-Z]/.test(value)) {
    return "Password must contain at least one uppercase letter";
  }
  if (!/[^a-zA-Z0-9]/.test(value)) {
    return "Password must contain at least one special character";
  }

  return true;
}
