import { gql } from "@apollo/client";

const SUB_SESSION_FRAGMENT = gql`
  fragment SubSessionFragment on Session {
    id
    version
    sessionID
    clientID
    rootID
    parentID
    parentIDs
    facility
    sessionType
    exportType
    createdAt
    sensors
    notes
    locked
    archived
    lastUpdated
    extension
  }
`;

// This is a recursive fragment that will allow us to get three levels of exported sessions
const SESSION_FRAGMENT = gql`
  fragment SessionFragment on Session {
    ...SubSessionFragment

    children {
      ...SubSessionFragment
      children {
        ...SubSessionFragment
        children {
          ...SubSessionFragment
        }
      }
    }
  }

  ${SUB_SESSION_FRAGMENT}
`;

export default SESSION_FRAGMENT;
