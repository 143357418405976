import { flexRender } from "@tanstack/react-table";
import { SlArrowDown, SlArrowRight } from "react-icons/sl";

export default function Expander({ cell, virtualizer }) {
  const {
    getContext,
    row: { getCanExpand, toggleExpanded, getIsExpanded, depth },
    column: { columnDef },
  } = cell;
  const btnMargin =
    depth === 1 ? "ml-6" : depth === 2 ? "ml-12" : depth === 3 ? "ml-16" : "";
  const emptyPadding =
    depth === 1 ? "pl-6" : depth === 2 ? "pl-12" : depth === 3 ? "pl-16" : "";

  return (
    <div className="Expander flex justify-start !overflow-visible w-full">
      {getCanExpand() ? (
        <button
          onClick={() => {
            toggleExpanded();

            // recalculate the total height when expanding/collapsing
            virtualizer.measure();
          }}
          className={`btn btn-ghost btn-sm btn-circle cursor-pointer hover:!bg-base-200 mr-2 ${btnMargin}`}
        >
          {getIsExpanded() ? (
            <SlArrowDown size={14} />
          ) : (
            <SlArrowRight size={14} />
          )}
        </button>
      ) : (
        <div className={`mx-4 min-w-[0.5rem] !pr-2 ${emptyPadding}`}></div>
      )}

      <div className="ExpanderContent flex justify-center items-center truncate !overflow-visible w-full">
        {flexRender(columnDef.cell, getContext())}
      </div>
    </div>
  );
}
