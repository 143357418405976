import { Link } from "react-router-dom"

export default function ViewNotesLink({ session }) {
  const notes = session.notes;

  return (
    <div className="truncate">
      <Link to={`notes/${session.sessionID}`}>{notes}</Link>
    </div>
  )
}
