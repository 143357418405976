import { useEffect, useState } from "react";
import { components } from "react-select";
import { FaPlusCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { toaster } from "@/utilities/toaster";

export const Option = (props) => {
  const isCreate = props.data.__isNew__;
  const { t } = useTranslation();
  const label = props?.data?.label || props?.label;
  const [optionCount, setOptionCount] = useState(0);
  const isCreateOnly = optionCount === 1 && isCreate;

  // this is a vanity hack to hide the divider when the only option is the create option
  useEffect(() => {
    const options = document.querySelectorAll(".super-select__option");
    setOptionCount(options ? options.length : 0);

    // use props as a dependency to force the effect to update
  }, [props]);

  return (
    <components.Option
      {...props}
      className={`Option !cursor-pointer ${
        isCreate
          ? "!bg-base-100"
          : "hover:!text-neutral-content hover:!bg-neutral !bg-base-100"
      } ${props.isSelected && "!bg-neutral"} `}
    >
      {isCreate ? (
        <div>
          <div
            className={`divider mt-0 mb-2 p-0 h-0 ${isCreateOnly && "hidden"}`}
          ></div>

          <div
            onClick={() => toaster.success(`"${props?.value}" ${t("Created")}`)}
            className="btn btn-sm btn-ghost !text-success flex gap-2 justify-center items-center"
          >
            <FaPlusCircle size={16} />

            {`${label} "${props?.value}"`}
          </div>
        </div>
      ) : (
        label
      )}
    </components.Option>
  );
};
