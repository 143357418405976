import { selectIsOnline } from "../../redux/account/selectors";
import useAutoReconcile from "@/hooks/io/useAutoReconcile";
import { useCallback } from "react";
import { info } from "@/utilities/log";

export default function useOnReconnect() {
  const autoReconcile = useAutoReconcile();
  const onReconnect = useCallback(
    async (action, listenerApi) => {
      const previousIsOnline = selectIsOnline(listenerApi.getState());
      const isOnline = action.payload;

      // check if device was offline and is now online
      if (!previousIsOnline && isOnline) {
        info("[useOnReconnect] CLIENT RECONNECTED...");
        await autoReconcile();
      }
    },
    [autoReconcile]
  );

  return onReconnect;
}
