import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { emptyObj } from "@/utilities/empties";
import useReleaseSessionLocks from "./useReleaseSessionLocks";
import { Auth } from "aws-amplify";
import { error, info } from "@/utilities/log";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setCurrentUserId } from "@/redux/account/slice";
import { PURGE } from "@/redux/purge";
import { ResetApolloContext } from "@/configurations/apollo/resetApolloContext";
import * as Sentry from "@sentry/react";
import useCleanupBadMetaData from "../io/useCleanupBadMetaData";
import useRemoveSettingsDirectory from "../io/useRemoveSettingsDirectory";
import { toast } from "react-hot-toast";

const DEFAULT_TO = "/";

export default function useLogout(to = DEFAULT_TO) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resetApollo = useContext(ResetApolloContext);
  const releaseSessionLocks = useReleaseSessionLocks();
  const cleanupBadMetaData = useCleanupBadMetaData();
  const removeSettingsDirectory = useRemoveSettingsDirectory();
  const logout = useCallback(
    async (args = emptyObj) => {
      import.meta.env.DEV && console.clear();
      info("[useLogout] started ");

      const {
        onStartMessage = t("Logging out..."),
        onCompleteMessage = t("Logged out"),
      } = args;

      toast(onStartMessage, { id: "logout" });

      // we want the logout process to continue even if these steps fail
      try {
        await cleanupBadMetaData();
        await releaseSessionLocks();
      } catch (e) {
        error("[useLogout] releaseSessionLocks", e);
      }
      try {
        await Auth.signOut();
      } catch (e) {
        error("[useLogout] Auth.signOut", e);
      }

      dispatch(PURGE());

      await resetApollo();

      dispatch(setCurrentUserId(null));
      await removeSettingsDirectory();

      Sentry.setUser(null);

      navigate(to);

      setTimeout(() => {
        toast.dismiss("subscription");
      }, 250);

      toast.success(onCompleteMessage, { id: "logout" });

      info("[useLogout] completed ");
    },
    [
      t,
      dispatch,
      resetApollo,
      removeSettingsDirectory,
      navigate,
      to,
      cleanupBadMetaData,
      releaseSessionLocks,
    ]
  );

  return logout;
}
