import { error } from "@/utilities/log";
import { useCallback, useState } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function useArchiveSession(refetch, sessionID, sessionUpdate) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const archive = useCallback(
    async (e) => {
      setLoading(true);
      e.stopPropagation();

      try {
        await sessionUpdate({
          variables: { sessionID, archived: true },
        });

        await refetch();
        toaster.success(t("Session archived"));
      } catch (e) {
        toaster.error(t("Failed to archive session"));
        error(e);
      }

      setLoading(false);
      navigate("../");
    },
    [sessionID, sessionUpdate, navigate, t, refetch]
  );

  return { archive, loading };
}
