import { DATA_PATH } from "@/utilities/paths/paths";
import { info } from "@/utilities/log";
import { useCallback } from "react";
import { appConfigDir, join } from "@tauri-apps/api/path";
import { createDir, exists } from "@tauri-apps/api/fs";
import useSettingsDownload from "./useSettingsDownload";
import useGetDesktopSettings from "@/hooks/account/useGetDesktopSettings";

export default function useDownloadDesktopSettings() {
  const desktopSettings = useGetDesktopSettings();
  const settingsDownload = useSettingsDownload();

  const downloadDesktopSettings = useCallback(async () => {
    info("[useDownloadDesktopSettings] started ");

    if (!desktopSettings) {
      info(
        "[useDownloadDesktopSettings] no desktop settings found for download",
        desktopSettings
      );

      return;
    }

    const appPath = await appConfigDir();
    const settingsActiveFileDir = await join(
      appPath,
      DATA_PATH.SETTINGS_ACTIVE
    );

    if (!(await exists(settingsActiveFileDir))) {
      await createDir(settingsActiveFileDir, { recursive: true });
    }

    return settingsDownload(desktopSettings, settingsActiveFileDir);
  }, [desktopSettings, settingsDownload]);

  return downloadDesktopSettings;
}
