import { emptyArr, emptyObj } from "@/utilities/empties";
import {
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import Body from "./Body/Body";
import Header from "./Header/Header";
import { useVirtualizer } from "@tanstack/react-virtual";
import InfiniteProgressBar from "@/components/InfiniteProgressBar/InfiniteProgressBar";
import EmtpyTable from "./EmptyTable/EmptyTable";
import TableSkeleton from "./TableSkeleton/TableSkeleton";
import useFetchNextPage from "./useFetchNextPage";
import { ROW_HEIGHT } from "./constants";

export default function Table({
  data = emptyArr,
  columns,
  rowClassName,
  cellClassName,
  rowOnClick,
  rowOnDoubleClick,
  fetchMore,
  loading,
  pageInfo = emptyObj,
  // the current sort
  sort,
  // the reducer function to update the current sort
  setSort,
  emptyMessage,
  emptyDescription,
  manualSorting = true,
  subRowKey = "",
  columnVisibility,
}) {
  const dispatch = useDispatch();
  const headerRef = useRef(null);
  const table = useReactTable({
    data,
    columns,
    getSubRows: (row) => row[subRowKey],
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    pageCount: pageInfo.totalPages,
    manualPagination: true,
    onSortingChange: (_setSort) => {
      const newSort = _setSort(sort);
      dispatch(setSort(newSort));
    },
    manualSorting,
    state: {
      sorting: sort,
      columnVisibility,
      pagination: {
        pageIndex: pageInfo.currentPage,
        pageSize: pageInfo.currentLimit,
      },
    },
  });
  const { rows } = table.getRowModel();
  const parentRef = React.useRef();
  const { hasNextPage } = pageInfo;
  const virtualizer = useVirtualizer({
    count: hasNextPage ? rows?.length + 1 : rows?.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => ROW_HEIGHT,
    overscan: 5,
  });

  useFetchNextPage(virtualizer, data, pageInfo, fetchMore, loading);

  if (data.length === 0 && !loading) {
    return (
      <EmtpyTable
        emptyMessage={emptyMessage}
        emptyDescription={emptyDescription}
      />
    );
  }

  if (data.length === 0 && loading) return <TableSkeleton />;

  return (
    <div
      ref={parentRef}
      className="TableContainer h-full overflow-y-auto w-full"
    >
      <div style={{ height: `${virtualizer.getTotalSize()}px` }}>
        <Header ref={headerRef} table={table} loading={loading} />

        <div className="Table w-full">
          <Body
            rows={rows}
            cellClassName={cellClassName}
            rowClassName={rowClassName}
            rowOnClick={rowOnClick}
            rowOnDoubleClick={rowOnDoubleClick}
            virtualizer={virtualizer}
          />
        </div>
      </div>

      <div
        className={`absolute bottom-0 z-10 w-full ${loading ? "visible" : "invisible"}`}
      >
        <InfiniteProgressBar />
      </div>
    </div>
  );
}
