import { gql } from "@apollo/client";
import FACILITY_FRAGMENT from "./facilityFragment";

const FACILITY_CREATE = gql`
  mutation FACILITY_CREATE(
    $name: String!
    $shareAllClients: Boolean
    $subscriptionExpirationDate: Date
    $RMA: String
  ) {
    facilityCreate(
      name: $name
      shareAllClients: $shareAllClients
      subscriptionExpirationDate: $subscriptionExpirationDate
      RMA: $RMA
    ) {
      ...FacilityFragment
    }
  }

  ${FACILITY_FRAGMENT}
`;

export default FACILITY_CREATE;
