import { gql } from "@apollo/client";
import USER_FRAGMENT from "./userFragment";

const USER_UPDATE = gql`
  mutation USER_UPDATE(
    $cognitoID: ID!
    $name: String
    $email: String
    $role: String
    $currentRole: String
    $facility: ID
    $clients: [ID]
    $language: String
    $desktopSettings: DesktopSettingsInput
  ) {
    userUpdate(
      cognitoID: $cognitoID
      name: $name
      email: $email
      role: $role
      currentRole: $currentRole
      facility: $facility
      clients: $clients
      language: $language
      desktopSettings: $desktopSettings
    ) {
      ...UserFragment
    }
  }

  ${USER_FRAGMENT}
`;

export default USER_UPDATE;
