import getRandomInt from "@/utilities/getRandomInt";
import { useMemo } from "react";

export default function useGetRandomInt(min, max) {
  const randomInt = useMemo(() => {
    return getRandomInt(min, max);
  }, [min, max]);

  return randomInt;
}
