export const LANGUAGE = {
  en: "English",
  de: "Deutsch",
};

export const LANGUAGE_OPTIONS = [
  { value: "en", label: LANGUAGE.en },
  { value: "de", label: LANGUAGE.de },
];

export const CONVERTED_LANGUAGE = {
  en: "en",
  "en-CA": "en",
  "en-GB": "en",
  "en-US": "en",

  de: "de",
  "de-DE": "de",
  "de-AT": "de",
  "de-CH": "de",
  "de-CA": "de",
  "de-US": "de",
};
