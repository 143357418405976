import useLogout from "@/hooks/account/useLogout";
import { selectIsLoggedIn, selectIsOnline } from "@/redux/account/selectors";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AUTO_LOGOUT_TIME_SECONDS } from "./constants";

export default function useOfflineAutoLogout() {
  const { t } = useTranslation();
  const isOnline = useSelector(selectIsOnline);
  const [timeoutId, setTimeoutId] = useState();
  const logout = useLogout();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    if (!timeoutId) return;
    // kill the auto logout timer if the user is online or already logged out
    if (isOnline || !isLoggedIn) clearTimeout(timeoutId);
  }, [isOnline, isLoggedIn, timeoutId]);

  useEffect(() => {
    if (isOnline) return;
    if (!isLoggedIn) return;

    setTimeoutId((prevTimeout) => {
      if (prevTimeout) return prevTimeout;

      const timeoutId = setTimeout(() => {
        logout({
          onCompleteMessage: t("Logged out due to loss of connection"),
        });

        setTimeoutId(null);
      }, AUTO_LOGOUT_TIME_SECONDS * 1000);

      return timeoutId;
    });
  }, [logout, isLoggedIn, isOnline, t]);
}
