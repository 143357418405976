import { addListener } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export default function useActionListener(action, ...effects) {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = dispatch(
      addListener({
        type: action.type,
        effect: (action, listenerApi) => {
          effects.forEach((effect) => effect(action, listenerApi));
        },
      })
    );

    return () => {
      unsubscribe();
    };
  }, [dispatch, effects, action]);
}
