import useOpenSession from "@/hooks/integration/useOpenSession/useOpenSession";
import useDownloadDesktopSettings from "@/hooks/io/useDownloadDesktopSettings/useDownloadDesktopSettings";
import { selectIsLoggedIn } from "@/redux/account/selectors";
import { selectOpenSessions } from "@/redux/sessions/selectors";
import { DATA_PATH } from "@/utilities/paths/paths";
import { appDataDir, join } from "@tauri-apps/api/path";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { open } from "@tauri-apps/api/shell";
import isSessionReport from "@/utilities/isSessionReport";

export default function OpenOfflineSessionButton({ session }) {
  const { t } = useTranslation();
  const openSessions = useSelector(selectOpenSessions);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [openSession, { loading }] = useOpenSession();
  const disabled = useMemo(() => {
    return openSessions?.includes(session.sessionID);
  }, [openSessions, session]);
  const downloadDesktopSettings = useDownloadDesktopSettings();
  const isReport = isSessionReport(session?.exportType);
  const openSessionHandler = useCallback(() => {
    const downloadSettings = isLoggedIn && downloadDesktopSettings;

    return openSession(session, undefined, downloadSettings);
  }, [downloadDesktopSettings, isLoggedIn, openSession, session]);
  const openReport = useCallback(async () => {
    const appDir = await appDataDir();
    const filePath = await join(
      appDir,
      DATA_PATH.OFFLINE,
      session?.sessionID + session?.extension
    );

    await open(filePath);
  }, [session]);
  const tooltipMessage = disabled && t("Session is already open");

  return (
    <div
      className={`${tooltipMessage ? "tooltip tooltip-left" : ""}`}
      data-tip={tooltipMessage}
    >
      <button
        className="btn-outline btn-info btn-xs btn disabled:text-white"
        data-testid="btnOpenOfflineSession"
        disabled={disabled || loading}
        onClick={isReport ? openReport : openSessionHandler}
      >
        {loading ? t("Opening") : t("Open")}
      </button>
    </div>
  );
}
