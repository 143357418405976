import USER from "@/api/user/userFragment";
import { selectCurrentUserId } from "@/redux/account/selectors";
import { useApolloClient } from "@apollo/client";
import { useSelector } from "react-redux";

export default function useSelectCurrentUser() {
  const client = useApolloClient();
  const currentUserId = useSelector(selectCurrentUserId);
  const reference = client.cache.identify({
    __typename: "User",
    cognitoID: currentUserId,
  });
  const myUser = client.readFragment({
    id: reference,
    fragmentName: "UserFragment",
    fragment: USER,
  });

  return myUser;
}
