import { useMemo } from "react";
import CompareSessionsButton from "@/components/CompareSessionsButton/CompareSessionsButton";
import NavBar from "@/components/NavBar/NavBar";
import Search from "@/components/Search/Search";
import Table from "@/components/Table/Table";
import View from "@/components/View/View";
import NewSessionButton from "@/components/buttons/NewSessionButton/NewSessionButton";
import useOfflineMeta from "@/hooks/io/useOfflineMeta";
import useLocalSearch from "@/hooks/searchAndSort/useLocalSearch";
import useLocalSorting from "@/hooks/searchAndSort/useLocalSorting";
import useColumnScaffold from "./hooks/useColumnScaffold";
import { SEARCHABLE_FIELDS } from "./constants";
import Header from "@/components/Header/Header";
import useCollapseToSubArray from "./hooks/useCollapseToSubArray";
import useRefreshOpenSessionsList from "@/hooks/integration/useRefreshOpenSessionsList";
import MacTitleBar from "@/components/TitleBar/MacTitleBar/MacTitleBar";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectSortOfflineSessions } from "@/redux/ui/selectors";
import { setSortOfflineSessions } from "@/redux/ui/slice";
import { selectIsLoggedIn } from "@/redux/account/selectors";
import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser";
import { Outlet } from "react-router-dom";

export default function OfflineSessionList() {
  const { t } = useTranslation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { data, loading } = useOfflineMeta();
  const sessionsWithSubRows = useMemo(() => {
    return data?.map((session) => ({
      ...session,
      isSubRow: !!session?.parentID || session?.parentIDs?.length > 1,
    }));
  }, [data]);
  const filteredSessions = useLocalSearch(
    sessionsWithSubRows,
    SEARCHABLE_FIELDS
  );
  const currentUser = useCurrentUser();
  const onlyOffline = useMemo(() => {
    return filteredSessions?.filter(
      (session) =>
        session?.offline &&
        (!session.facility || session.facility === currentUser?.facility)
    );
  }, [filteredSessions, currentUser]);
  const sortOfflineSessions = useSelector(selectSortOfflineSessions);
  const sortedSessions = useLocalSorting(onlyOffline, sortOfflineSessions);
  const columns = useColumnScaffold(sortedSessions);
  const collapsedSessions = useCollapseToSubArray(sortedSessions);
  useRefreshOpenSessionsList();

  return (
    <View className="max-h-screen">
      <MacTitleBar />

      <Outlet />

      <Header />

      <NavBar login={!isLoggedIn} title={t("Offline Sessions")}>
        <Search />

        <CompareSessionsButton className="hidden md:flex" />

        <NewSessionButton className="hidden md:flex" />
      </NavBar>

      <Table
        data={collapsedSessions}
        subRowKey="children"
        loading={loading}
        columns={columns}
        sort={sortOfflineSessions}
        setSort={setSortOfflineSessions}
        manualSorting={false}
        emptyDescription={t("There are no offline sessions")}
      />
    </View>
  );
}
