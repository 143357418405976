import { onError } from "@apollo/client/link/error";
import handleGraphqlErrors from "@/utilities/errors/handleGraphqlErrors";
import handleNetworkErrors from "@/utilities/errors/handleNetworkErrors";

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(function (graphqlError) {
        handleGraphqlErrors(graphqlError);
      });

      // return Observable.of(operation);
    }

    if (networkError) {
      handleNetworkErrors(networkError);
    }

    forward(operation);
  }
);
export default errorLink;
