import { useNavigate } from "react-router-dom";
import StatusIcon from "@/assets/vectors/status-bar.svg?react";
import ReconcileIcon from "@/assets/vectors/reconcile.svg?react";
import useOfflineMeta from "@/hooks/io/useOfflineMeta";
import useGetLocalXsns from "@/hooks/integration/useGetLocalXsns/useGetLocalXsns";
import { DATA_PATH } from "@/utilities/paths/paths";
import { useTranslation } from "react-i18next";

export default function SessionsToReconcile({ className }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: offlineXSNs } = useGetLocalXsns(DATA_PATH.OFFLINE);
  const { data: offlineSessions } = useOfflineMeta();
  const offlineIDs = offlineSessions?.map((session) => session?.sessionID);
  const completeOffline = offlineXSNs?.filter((xsn) =>
    offlineIDs?.includes(xsn?.sessionID)
  );
  const offlineCount = completeOffline?.length;

  return (
    <div className={`stat ${className}`}>
      <div className="stat-figure place-self-start">
        <StatusIcon />
      </div>

      <div className="stat-title opacity-100">{t("Sessions to Reconcile")}</div>

      <div className="stat-value font-bold mb-2">{offlineCount || 0}</div>

      <div
        onClick={() => navigate("/auth/offline")}
        className="link-hover stat-desc link items-center flex text-primary opacity-100"
      >
        <ReconcileIcon />

        <span className="ml-1 font-bold">
          {t("Reconcile ")}
          {offlineCount && `(${offlineCount})`}
        </span>
      </div>
    </div>
  );
}
