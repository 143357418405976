import USER_UPDATE from "@/api/user/userUpdate";
import FACILITY_UPDATE from "@/api/facility/facilityUpdate";
import { ROLE } from "@/views/UserList/constants";
import { DATA_PATH } from "@/utilities/paths/paths";
import { error, info } from "@/utilities/log";
import { useMutation, useQuery } from "@apollo/client";
import useSelectCurrentFacility from "@/hooks/apolloState/useSelectCurrentFacility";
import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser";
import { useCallback, useMemo } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import { appConfigDir, join } from "@tauri-apps/api/path";
import useZip from "../useZip/useZip";
import { createDir, exists, removeFile } from "@tauri-apps/api/fs";
import useLazyMoveSettings from "./useLazyMoveSettings";
import useMergeSettings from "./useMergeSettings";
import MY_FACILITY from "@/api/facility/myFacility";
import useSyncToS3 from "../useSyncToS3";

export default function useSyncDesktopSettings() {
  const { t } = useTranslation();
  const currentFacility = useSelectCurrentFacility();
  const currentUser = useCurrentUser();
  const currentRole = useMemo(
    () => currentUser?.currentRole?.name,
    [currentUser?.currentRole?.name]
  );
  const zipFiles = useZip();
  const syncToS3 = useSyncToS3();
  const [updateSettings] = useMutation(FACILITY_UPDATE);
  const { refetch } = useQuery(MY_FACILITY);
  const [userUpdate] = useMutation(USER_UPDATE);
  const moveSettings = useLazyMoveSettings();
  const mergeSettings = useMergeSettings();
  const syncDesktopSettings = useCallback(
    async (settings) => {
      info("[useSyncDesktopSettings] started ", settings);

      const syncing = async () => {
        if (![ROLE.STANDARD, ROLE.ADMIN].includes(currentRole)) {
          info(
            "[useSyncDesktopSettings] aborting desktop settings sync for unsupproted role",
            currentRole
          );

          return;
        }

        const appConfigDirPath = await appConfigDir();
        const settingsFileDir = await join(
          appConfigDirPath,
          DATA_PATH.SETTINGS_OFFLINE
        );
        if (!(await exists(settingsFileDir))) {
          await createDir(settingsFileDir, { recursive: true });
        }
        const settingsActiveFileDir = await join(
          appConfigDirPath,
          DATA_PATH.SETTINGS_ACTIVE
        );
        if (!(await exists(settingsActiveFileDir))) {
          await createDir(settingsActiveFileDir, { recursive: true });
        }

        try {
          const settingsMap = await moveSettings(settings, appConfigDirPath);
          const filteredSettings = await mergeSettings(
            settingsMap?.filter((setting) => !!setting),
            appConfigDirPath
          );

          const userID =
            currentRole === ROLE.STANDARD ? currentUser?.id : undefined;
          const facilityID =
            currentRole === ROLE.ADMIN ? currentFacility?.id : undefined;
          const zippedFileName = `${userID ?? facilityID}.zip`;
          const zipToPath = await join(settingsFileDir, zippedFileName);

          await zipFiles(
            filteredSettings.map(({ toPath }) => toPath),
            zipToPath
          );

          await syncToS3(zipToPath);

          const desktopSettings = {
            zippedFileName,
            settings: filteredSettings.map(({ file, setting }) => ({
              file,
              setting,
            })),
          };

          userID &&
            (await userUpdate({
              variables: {
                cognitoID: currentUser?.cognitoID,
                desktopSettings,
              },
            }));

          facilityID &&
            (await updateSettings({
              variables: {
                desktopSettings,
              },
            }));
          facilityID && (await refetch());

          await removeFile(zipToPath);
          toaster.success(t("Desktop settings successfully synced"));

          info("[useSyncDesktopSettings] completed ", settings);
        } catch (e) {
          error("[useSyncDesktopSettings] Failed to sync settings ", e);
          toaster.error(t("Failed to Sync settings"));

          throw new Error(e);
        }
      };

      await syncing();
    },
    [
      currentRole,
      moveSettings,
      mergeSettings,
      currentUser?.id,
      currentUser?.cognitoID,
      currentFacility?.id,
      zipFiles,
      syncToS3,
      userUpdate,
      updateSettings,
      refetch,
      t,
    ]
  );

  return syncDesktopSettings;
}
