import React, { useRef } from "react";
import { FaInfoCircle } from "react-icons/fa";
import useShowTooltip from "./useToggleShowingTooltip";

export default function InputField({
  label,
  className,
  labelClassName,
  children,
  error,
  tooltip,
  disabled,
}) {
  const requiredMessage =
    (error?.required || error?.type === "required") && `${label} is required`;
  const errorMessage = error?.message || requiredMessage;
  const ref = useRef(null);
  const showTooltip = useShowTooltip(ref);

  return (
    <div
      ref={ref}
      className={`InputField relative flex flex-col gap-1 ${className}`}
    >
      {!!label && (
        <div className="flex justify-between">
          <div className={`mb-1 font-medium ${labelClassName}`}>{label}</div>

          {tooltip && (
            <div
              data-tip={tooltip}
              className={`tooltip tooltip-left ${showTooltip && "tooltip-open"}`}
            >
              <FaInfoCircle />
            </div>
          )}
        </div>
      )}

      <div className={`${disabled && "hover:!cursor-not-allowed"}`}>
        {children}
      </div>

      {!!errorMessage && (
        <div className="InputFieldError text-xs text-red-600">
          {errorMessage}
        </div>
      )}
    </div>
  );
}
