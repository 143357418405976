import useGetLocalXsns from "@/hooks/integration/useGetLocalXsns/useGetLocalXsns";
import { DATA_PATH } from "@/utilities/paths/paths";
import { listen } from "@tauri-apps/api/event";
import { useEffect } from "react";
import useCheckIsOnline from "@/hooks/account/useCheckIsOnline";
import { useDispatch, useSelector } from "react-redux";
import getParentSessionID from "@/utilities/paths/getParentSessionID";
import useReadMetaFile from "@/hooks/io/useReadMetaFile";
import { info } from "@/utilities/log";
import getSessionPaths from "@/utilities/paths/getSessionPaths";
import { useTranslation } from "react-i18next";
import { selectIsLoggedIn } from "@/redux/account/selectors";
import { DEFAULT_SETTINGS_SESSION_ID } from "@/utilities/constants";
import useGetXsnMeta from "@/hooks/integration/useGetXsnMeta/useGetXsnMeta";
import useSyncSession from "@/hooks/io/useSyncSession";
import { FILE_EXTENSION } from "@/constants";
import { setSyncingSession } from "@/redux/sessions/slice";

export default function useSyncSessionListener() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const syncSession = useSyncSession();
  const checkIsOnline = useCheckIsOnline();
  const getXsnMeta = useGetXsnMeta();
  const readMetaFile = useReadMetaFile();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { data: activeSessions } = useGetLocalXsns(DATA_PATH.ACTIVE);

  useEffect(() => {
    const unlisten = listen("sync_session", async (event) => {
      const { sessionID } = event.payload;

      if (sessionID === DEFAULT_SETTINGS_SESSION_ID) return;

      const handler = async () => {
        try {
          const parentID = getParentSessionID(sessionID);
          const parentMeta = parentID && (await readMetaFile(parentID));
          const isOnline = await checkIsOnline();
          const meta = await readMetaFile(sessionID);
          info("[useSyncSessionListener] event recevied", sessionID);
          if (!isOnline || meta?.offline || !isLoggedIn) return;

          const { activePath } = await getSessionPaths({
            sessionID,
            extension: FILE_EXTENSION.XSN,
          });
          const xsn_meta = await getXsnMeta(activePath);

          const mergedMeta = {
            ...meta,
            clientID: parentMeta?.clientID || meta?.clientID,
            sensors: xsn_meta?.sensors,
          };

          await syncSession(activePath, mergedMeta, true);
        } catch (e) {
          info("[useSyncSessionListener] error", e);
        }

        dispatch(
          setSyncingSession({ sessionID, promise: null, isSynced: true })
        );
      };

      const promise = handler();
      // the sync promise is stored in redux so other services can await it
      dispatch(setSyncingSession({ sessionID, promise }));
    });

    return () => {
      unlisten.then((f) => f());
    };
  }, [
    dispatch,
    syncSession,
    checkIsOnline,
    isLoggedIn,
    activeSessions,
    readMetaFile,
    getXsnMeta,
    t,
  ]);
}
