import { gql } from "@apollo/client";
import ROLE from "../role/roleFragment";

const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    facility
    cognitoID
    name
    email
    role {
      ...RoleFragment
    }
    currentRole {
      ...RoleFragment
    }
    subscription
    enabled
    clients {
      clientID
      fullname
    }
    language
    desktopSettings {
      zippedFileName
      updatedAt
      settings {
        setting
        file
      }
    }
  }

  ${ROLE}
`;

export default USER_FRAGMENT;
