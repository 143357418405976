import { useApolloClient } from "@apollo/client";
import useSelectCurrentUser from "./useSelectCurrentUser";
import FACILITY_FRAGMENT from "@/api/facility/facilityFragment";

export default function useSelectCurrentFacility() {
  const currentUser = useSelectCurrentUser();
  const client = useApolloClient();
  const reference = client.cache.identify({
    __typename: "Facility",
    id: currentUser?.facility,
  });
  const facility = client.readFragment({
    id: reference,
    fragmentName: "FacilityFragment",
    fragment: FACILITY_FRAGMENT,
  });

  return facility;
}
