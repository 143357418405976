import { useLazyHasPermission } from "@/hooks/account/useHasPermission/useHasPermission";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function NewSessionButton({ className, disabled }) {
  const { t } = useTranslation();
  const hasPermission = useLazyHasPermission();
  const _disabled =
    disabled ||
    (hasPermission("spoofFacility") &&
      t("Xsensor admins cannot create sessions"));

  return (
    <div
      data-tip={_disabled}
      className={`tooltip-left ${_disabled && "tooltip"}`}
    >
      <Link
        to="new_session"
        disabled={_disabled}
        className={`btn-success btn-sm btn ${className}`}
      >
        {t("New Session")}
      </Link>
    </div>
  );
}
