import timeout from "@/utilities/events/timeout";
import { error, info } from "@/utilities/log";
import { useCallback } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";

export default function useRetry() {
  const { t } = useTranslation();
  const retry = useCallback(
    // make x attempts with y time between each attempt
    async (numberOfRetries, timeBetweenAttempts, callback, onFail) => {
      const attempts = [...Array(numberOfRetries).keys()];

      for (const attempt of attempts) {
        try {
          const connection = await callback();

          return connection;
        } catch (e) {
          error(e);
          info(`[useRetry] Attempt ${attempt + 1} failed. Retrying...`);
        }
        await timeout(timeBetweenAttempts);
      }

      onFail?.();

      if (!onFail) {
        toaster.error(t(`Ran out of retries, giving up...`));
        throw new Error(t("Ran out of retries, giving up."));
      }
    },
    [t]
  );

  return retry;
}
