import { useQuery } from "@apollo/client";

import { Controller } from "react-hook-form";
import SuperSelect from "../SuperSelect/SuperSelect";
import { useMemo } from "react";
import SESSION_TYPE_LIST from "@/api/session/sessionTypeList";
import { useTranslation } from "react-i18next";

export const SELECT_SESSION_TYPE_TOOLTIP =
  "To create a new session type simply start typing the name then click create";

export default function SelectSessionType({
  control,
  disabled,
  rules,
  ...props
}) {
  const { t } = useTranslation();
  const { data, loading } = useQuery(SESSION_TYPE_LIST, {
    fetchPolicy: "cache-and-network",
  });
  const options = useMemo(() => {
    return data?.sessionTypeList?.map((option) => ({
      value: option,
      label: option,
    }));
  }, [data]);

  return (
    <Controller
      control={control}
      name="sessionType"
      rules={rules}
      render={({ field }) => {
        return (
          <SuperSelect
            isLoading={loading}
            createableLabel={t("Create")}
            options={options}
            isDisabled={disabled}
            isClearable={true}
            {...field}
            {...props}
          />
        );
      }}
    />
  );
}
