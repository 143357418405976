import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectIsLoggedIn } from "@/redux/account/selectors";
import { selectOpenSessions } from "@/redux/sessions/selectors";
import { FILE_EXTENSION } from "@/constants";

export default function DeleteOfflineSessionButton({ session }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const openSessions = useSelector(selectOpenSessions);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const disabled = useMemo(() => {
    return openSessions?.includes(session.sessionID) || !isLoggedIn;
  }, [openSessions, session, isLoggedIn]);
  const tooltipMessage =
    disabled && t("You're either not logged in or session is open");

  const deleteSession = useCallback(
    (e) => {
      e.stopPropagation();

      navigate(`/auth/offline/delete/${session.sessionID}`, {
        state: { extension: session.extension || FILE_EXTENSION.XSN },
      });
    },
    [navigate, session]
  );

  return (
    <div
      className={`${tooltipMessage ? "tooltip tooltip-left" : ""}`}
      data-tip={tooltipMessage}
    >
      <button
        className="btn-outline btn-error btn-xs btn disabled:text-white mx-2"
        data-testid="btnDeleteSession"
        disabled={disabled}
        onClick={deleteSession}
      >
        {t("Delete")}
      </button>
    </div>
  );
}
