import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser"
import { LANGUAGE } from "@/components/selects/SelectLanguage/constants"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Card from "../../Card/Card"

export default function SettingsCard() {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()

  return (
    <Card
      className="mb-5"
      title={t("Settings")}
      buttons={
        <Link
          to="settings"
          className="btn btn-primary btn-sm ml-auto text-base-100 w-52"
        >
          {t("Change Settings")}
        </Link>
      }
    >
      <div className="flex gap-5">
        <span className="text-base-200 font-bold text-sm w-28">
          {t("Language")}:
        </span>
        <span>{LANGUAGE[currentUser?.language]}</span>
      </div>

      <div className="flex gap-5">
        <span className="text-base-200 font-bold text-sm w-28">
          {t("Current Role")}:
        </span>
        <span> {t(currentUser?.currentRole?.name)}</span>
      </div>
    </Card>
  )
}
