import { useLazyHasPermission } from "@/hooks/account/useHasPermission/useHasPermission"

import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Card from "../../Card/Card"
import useSelectCurrentFacility from "@/hooks/apolloState/useSelectCurrentFacility"

export default function FacilityCard() {
  const { t } = useTranslation()
  const hasPermission = useLazyHasPermission()
  const myFacility = useSelectCurrentFacility()

  return (
    hasPermission("spoofFacility") && (
      <Card
        className="mb-5"
        title={t("Facility")}
        buttons={
          <div className="flex gap-5">
            <Link
              to="new_facility"
              className="btn btn-primary btn-sm ml-auto text-base-100 w-52"
            >
              {t("Create Facility")}
            </Link>

            <Link
              to="edit_facility"
              className="btn btn-primary btn-sm ml-auto text-base-100 w-52"
            >
              {t("Edit Facility")}
            </Link>

            <Link
              to="admin"
              className="btn btn-primary btn-sm ml-auto text-base-100 w-52"
            >
              {t("Switch Facility")}
            </Link>
          </div>
        }
      >
        <div className="flex gap-5">
          <span className="text-base-200 font-bold text-sm w-28">
            {t("Current Facility")}:
          </span>

          <span>{myFacility?.name}</span>
        </div>
      </Card>
    )
  )
}
