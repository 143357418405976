import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";

export default function ScrollButton({ className, toggleScroll, isBottom }) {
  return (
    <div
      data-theme="insoles-light"
      className={`ScrollButton fixed bottom-0 left-0 right-0 mb-5 z-50 m-auto flex w-44 justify-center 
          bg-transparent ${className}`}
    >
      <button
        className="text-base-200-content btn-ghost btn-square btn-xs btn z-10 w-full 
            rounded-full bg-base-200/75 hover:bg-base-200"
        onClick={toggleScroll}
      >
        {isBottom ? (
          <BsChevronCompactUp size={25} />
        ) : (
          <BsChevronCompactDown size={25} />
        )}
      </button>
    </div>
  );
}
