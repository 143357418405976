import useGetXsnMeta from "@/hooks/integration/useGetXsnMeta/useGetXsnMeta";
import { useCallback } from "react";
import useSyncDocument from "./useSyncDocument";
import { info } from "@/utilities/log";

export default function useSyncSession() {
  const getXsnMeta = useGetXsnMeta();
  const syncDocument = useSyncDocument();
  const syncSession = useCallback(
    async (filePath, meta, shouldToast) => {
      info("[useSyncSession] started ", meta, filePath);

      const xsn_meta = await getXsnMeta(filePath);
      const _meta = {
        ...meta,
        sensors: xsn_meta?.sensors,
      };

      await syncDocument(filePath, _meta, shouldToast);
    },
    [getXsnMeta, syncDocument]
  );

  return syncSession;
}
