import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import CustomInput from "./CustomInput/CustomInput";

export default function DateInput({ control, label, name, ...props }) {
  return (
    <div className="flex flex-col">
      <span className="font-medium mb-1">{label}</span>

      <div className="flex justify-center items-center border border-slate-300 rounded-lg p-2 h-[45px]">
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange } }) => {
            const placeholder = value
              ? `Selected Date: ${format(value, "P")}`
              : "Select date";

            return (
              <DatePicker
                {...props}
                customInput={<CustomInput />}
                className="cursor-pointer"
                placeholderText={placeholder}
                onSelect={(date) => {
                  onChange(date);
                }}
                selected={value}
              />
            );
          }}
        />
      </div>
    </div>
  );
}
