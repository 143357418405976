import { createSelector } from "@reduxjs/toolkit";

export const selectCheckedRows = (state) => state.table.checkedRows;
export const selectSort = (state) => state.table.sort;
export const selectSortAsObject = createSelector(selectSort, (sort) => {
  const asObject = sort?.reduce((acc, cur) => {
    acc[cur.id] = cur.desc ? -1 : 1;

    return acc;
  }, {});

  return asObject;
});
