import useClickOutside from "@/hooks/ui/useClickOutside";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdFilterList } from "react-icons/md";

export default function Filters({ children, isFiltered, clearAllFilters }) {
  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const [show, setShow] = useState(false);
  useClickOutside(dropdownRef, () => {
    setShow(false);
  });

  return (
    <>
      <button
        className={`btn btn-primary btn-circle btn-sm ${
          isFiltered ? "btn-primary" : "btn-ghost text-primary"
        }`}
        onClick={() => setShow(!show)}
      >
        <MdFilterList size={20} />
      </button>

      <div
        className={`z-50 dropdown dropdown-end relative dropdown-open`}
        ref={dropdownRef}
      >
        {show && (
          <div
            className="dropdown-content rounded-box bg-base-100 shadow-lg drop-shadow-lg w-96 p-8"
            data-theme="insoles-light"
          >
            <div className="flex items-center mb-5">
              <div className="text-3xl font-bold">{t("Filters")}</div>
              {clearAllFilters && <button
                className="ml-auto btn btn-ghost btn-xs"
                onClick={clearAllFilters}
              >
                {t("Clear All")}
              </button>}
            </div>

            <ul tabIndex={0}>{children}</ul>
          </div>
        )}
      </div>
    </>
  );
}
