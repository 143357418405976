import SearchIcon from "@/assets/vectors/search.svg?react";
import i18n from "@/configurations/i18n";

export default function EmtpyTable({
  emptyMessage = i18n.t("No data"),
  emptyDescription = i18n.t("There are no items"),
}) {
  return (
    <div
      className="h-100 flex w-full flex-1 flex-col items-center justify-center 
      bg-base-content pb-40 text-base-200"
    >
      <div className="mb-2">
        <SearchIcon />
      </div>
      <h2 className="mb-1">{emptyMessage}</h2>
      <p>{emptyDescription}</p>
    </div>
  );
}
