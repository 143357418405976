import { selectIsLoggedIn } from "@/redux/account/selectors";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function EditSessionButton({ session }) {
  const { t } = useTranslation();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <Link
      to={
        isLoggedIn
          ? `/auth/offline/edit/${session?.sessionID}`
          : `/offline/edit/${session?.sessionID}`
      }
      className="btn-outline btn-success btn-xs btn mx-2"
    >
      {t("Edit")}
    </Link>
  );
}
