import MY_FACILITY from "@/api/facility/myFacility";
import { emptyObj } from "@/utilities/empties";
import { useQuery } from "@apollo/client";

export default function useAccountSubscription() {
  const { data } = useQuery(MY_FACILITY);
  const subscription = data?.myFacility?.subscription;

  return subscription || emptyObj;
}
