import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import SessionForm from "../SessionForm";
import useAccountSubscription from "@/hooks/account/useAccountSubscription";
import useSelectClient from "@/hooks/apolloState/useSelectClient";
import { useTranslation } from "react-i18next";
import useSubmit from "./useSubmit";
import { useContext, useMemo } from "react";
import { RootContext } from "@/context/rootContext";

export default function NewSessionForm({ offline, ...props }) {
  const { t } = useTranslation();
  const params = useParams();
  const { clientID, sessionID } = params;
  const client = useSelectClient(clientID);
  const { sessionManyQuery } = useContext(RootContext);
  const [_, { refetch }] = sessionManyQuery;
  const form = useForm({
    defaultValues: {
      client: client && { clientID, fullname: client?.fullname },
      sessionType: null,
      notes: "",
    },
  });
  const { getValues, watch } = form;
  const { isActive } = useAccountSubscription();
  const { submit, loading } = useSubmit(
    getValues,
    offline,
    sessionID && refetch
  );
  const { isTestSession } = watch();
  const hiddenFields = useMemo(() => !isActive || isTestSession? ["client"] : [], [isActive, isTestSession]);

  return (
    <SessionForm
      hideNewClientButton
      hiddenFields={hiddenFields}
      submitButtonLabel={t("Start Session")}
      title={t("New Session")}
      form={form}
      submit={submit}
      loading={loading}
      submitButton={
        isTestSession && (
          <div>
            <button
              data-tip={t("This session will be deleted after recording")}
              className="tooltip tooltip-left btn btn-sm btn-error"
            >
              {t("Start Test Session")}
            </button>
          </div>
        )
      }
      {...props}
    />
  );
}
