import SelectGroups, {
  SELECT_GROUPS_TOOLTIP,
} from "@/components/selects/SelectGroups/SelectGroups";
import SelectUsers from "@/components/selects/SelectUsers/SelectUsers";
import { useNavigate } from "react-router-dom";
import InputField from "@/components/forms/formComponents/InputField/InputField";
import FormInput from "../formComponents/FormInput";
import { ROLE } from "@/views/UserList/constants";
import { emptyArr } from "@/utilities/empties";
import { useTranslation } from "react-i18next";

export default function ClientForm({
  submit,
  loading,
  title,
  hiddenFields = emptyArr,
  disabledFields = emptyArr,
  form,
  buttonLabel,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = form;

  return (
    <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-5">
      <h1 data-testid="clientFormTitle">{title}</h1>

      {!hiddenFields.includes("fullname") && (
        <FormInput
          name="fullname"
          label={t("Full Name")}
          errors={errors}
          placeholder={t("Full Name")}
          {...register("fullname", { required: true })}
        />
      )}

      {!hiddenFields.includes("privateID") && (
        <FormInput
          name="privateID"
          label={t("Client ID")}
          errors={errors}
          placeholder={t("Client ID")}
          {...register("privateID", { required: true })}
        />
      )}

      {!hiddenFields.includes("group") && (
        <InputField
          label={t("Group")}
          error={errors["groups"]}
          tooltip={t(SELECT_GROUPS_TOOLTIP)}
        >
          <SelectGroups
            control={control}
            creatable={(newValue) => {
              setValue("groups", newValue);
            }}
          />
        </InputField>
      )}

      {!hiddenFields.includes("users") && (
        <InputField label={t("Assign to")} error={errors["users"]}>
          <SelectUsers
            roles={[ROLE.STANDARD, ROLE.ADMIN]}
            control={control}
            isDisabled={disabledFields.includes("users")}
            rules={{ required: true }}
          />
        </InputField>
      )}

      <div className="flex justify-end">
        <button
          type="button"
          className="btn-outline btn btn-sm mr-5 px-8"
          data-testid="clientFormCancleBtn"
          onClick={() => navigate(-1, { replace: true })}
          disabled={loading}
        >
          Cancel
        </button>

        <button
          type="submit"
          data-testid="clientFormSubmitBtn"
          disabled={loading}
          className={`btn-success btn btn-sm px-8 `}
        >
          {loading ? (
            <span className="loading loading-ring loading-md text-neutral"></span>
          ) : (
            buttonLabel
          )}
        </button>
      </div>
    </form>
  );
}
