import View from "../View/View";
import logo from "@/assets/vectors/xsensor-logo.svg";
import login_logo from "@/assets/vectors/login_logo.svg";

export default function SplashScreen() {
  return (
    <View data-theme="insoles" className="bg-base-100">
      <div className="flex justify-center items-center h-full w-full">
        <div className="flex flex-col justify-center">
          <img src={logo} className="w-36 mb-10 self-center" />

          <img src={login_logo} className="w-96" />
        </div>
      </div>
    </View>
  );
}
