import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";
import { error, info } from "@/utilities/log";
import GET_DOWNLOAD_URL from "@/api/sync/getDownloadUrl";

export default function useDownloadFile() {
  const [download] = useLazyQuery(GET_DOWNLOAD_URL);
  const downloadFile = useCallback(
    async (fileName = undefined) => {
      info("[useDownloadFile] started ", fileName);

      try {
        const preSignedUrl = await download({
          variables: {
            fileName,
          },
        });

        info("[useDownloadFile] completed ", fileName);

        return preSignedUrl;
      } catch (e) {
        error("[useDownloadFile] error", e);
        throw e;
      }
    },
    [download]
  );

  return downloadFile;
}
