export default function Card({ title, children, className, buttons }) {
  return (
    <div className={`card w-full bg-base-100 shadow-xl ${className}`}>
      <div className="card-body">
        <div className="relative flex">
          <div className="flex flex-col">
            {title && <h2 className="card-title mb-5 text-2xl">{title}</h2>}

            <div className="flex flex-col w-full">{children}</div>
          </div>

          {buttons && (
            <div className="flex items-center h-full my-auto ml-auto">
              {buttons}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
