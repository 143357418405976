import { noop } from "@/utilities/empties";
import Cell from "../Cell/Cell";
import { ROW_COLOR } from "../constants";

export default function Row({
  row,
  className,
  rowOnClick = noop,
  virtualizer,
  rowOnDoubleClick = noop,
  ...props
}) {
  const isSubRow = row?.original?.isSubRow;
  const { depth } = row;

  return (
    <div
      className={`Row flex text-neutral relative ${isSubRow ? "bg-gray-50" : ""} ${
        ROW_COLOR[depth]
      } ${className}`}
      onClick={() => rowOnClick(row)}
      onDoubleClick={rowOnDoubleClick}
      {...props}
    >
      {row.getVisibleCells().map((cell) => {
        return (
          <Cell
            cell={cell}
            key={cell.id}
            isSubRow={isSubRow}
            virtualizer={virtualizer}
          />
        );
      })}
    </div>
  );
}
