import { gql } from "@apollo/client";
import SUBSCRIPTION_FRAGMENT from "./subscriptionFragment";
import USER_FRAGMENT from "../user/userFragment";

const FACILITY_FRAGMENT = gql`
  fragment FacilityFragment on Facility {
    id
    name
    shareAllClients
    RMA
    subscription {
      ...SubscriptionFragment
    }
    desktopSettings {
      zippedFileName
      lastUpdatedBy {
        ...UserFragment
      }
      updatedAt
      settings {
        setting
        file
      }
    }
  }

  ${SUBSCRIPTION_FRAGMENT}
  ${USER_FRAGMENT}
`;

export default FACILITY_FRAGMENT;
