export const getAmplifyConfig = (envVars) => {
  return {
    aws_project_region: envVars.COGNITO_REGION,
    aws_cognito_region: envVars.COGNITO_REGION,
    aws_user_pools_id: envVars.USER_POOL_ID,
    aws_user_pools_web_client_id: envVars.COGNITO_CLIENT_ID,
    oauth: {},
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ["NAME", "EMAIL"],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: [],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [
        "REQUIRES_LOWERCASE",
        "REQUIRES_UPPERCASE",
        "REQUIRES_NUMBERS",
        "REQUIRES_SYMBOLS",
      ],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
    Auth: {
      storage: sessionStorage,
    },
  };
};
