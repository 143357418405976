import { selectSort } from "@/redux/table/selectors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function LastUsedSessionHeader() {
  const { t } = useTranslation();
  const sorting = useSelector(selectSort);
  const sort = sorting?.find((sort) => sort.id === "latestSession.createdAt");

  return (
    <span>
      {sort
        ? sort?.desc
          ? t("Last Used Session (Desc)")
          : t("Last Used Session (Asc)")
        : t("Last Used Session")}
    </span>
  );
}
