import { createSlice } from "@reduxjs/toolkit";
import purge, { PURGE } from "../purge";

const initialState = {
  checkedRows: [],
  sort: [],
};

export const tableSlice = createSlice({
  name: "TABLE",
  initialState,
  reducers: {
    setCheckedRow: (state, { payload }) => {
      const { session, isChecked } = payload;
      const checkedRows = isChecked
        ? [...state.checkedRows, session]
        : state.checkedRows.filter((s) => s.sessionID !== session.sessionID);

      state.checkedRows = checkedRows;
    },
    clearCheckedRows: (state) => {
      state.checkedRows = [];
    },
    setSort: (state, { payload }) => {
      state.sort = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, purge(initialState));
  },
});

export const { setCheckedRow, setSort, clearCheckedRows } = tableSlice.actions;

export default tableSlice;
