import logo from "@/assets/vectors/xsensor-logo.svg";
import Tabs from "./Tabs/Tabs";
import AccountDropdown from "./AccountDropdown/AccountDropdown";

export default function Header() {
  return (
    <div data-theme="insoles" className={`Header`}>
      <div
        data-tauri-drag-region
        className="flex items-center border-b border-base-content pl-5 py-5 gap-8"
      >
        <img src={logo} className="h-[30px]" />

        <Tabs />

        <div className="grow"></div>

        <AccountDropdown />
      </div>
    </div>
  );
}
