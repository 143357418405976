import useReconcileSession from "@/hooks/io/useReconcileSession";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import SessionForm from "../SessionForm";
import { useTranslation } from "react-i18next";
import useGetDefaultFormValues from "./useGetDefaultFormValues";

const REQUIRED_FIELDS = ["client"];

export default function ReconcileSessionForm(props) {
  const { t } = useTranslation();
  const [session, setSession] = useState();
  const getDefaultFormValues = useGetDefaultFormValues(session, setSession);
  const form = useForm({
    defaultValues: getDefaultFormValues,
  });
  const { watch, reset } = form;
  const { client, sessionType, notes } = watch();
  const parentMeta = useMemo(
    () => ({
      sessionID: session?.sessionID,
      clientID: client?.clientID,
      createdAt: session?.createdAt,
      sessionType: sessionType?.value,
      notes,
    }),
    [session, client, sessionType, notes]
  );
  const { submit, loading: loadingSubmit } = useReconcileSession({
    session,
    parentMeta,
    formMeta: {
      notes,
      sessionType: sessionType?.value,
    },
    reset,
  });
  const hiddenFields = useMemo(
    () => [
      "isTestSession",
      session?.parentID && "client",
      session?.parentID && "sessionType",
    ],
    [session]
  );

  return (
    <SessionForm
      {...props}
      hiddenFields={hiddenFields}
      requiredFields={REQUIRED_FIELDS}
      subTitle={
        session?.parentID &&
        `${t("Parent session:")} ${session.parentID || session.parentIDs?.join(", ")}`
      }
      submitButtonLabel={t("Reconcile")}
      title={t("Reconcile Session")}
      form={form}
      submit={submit}
      loading={loadingSubmit}
      hideNewClientButton
    />
  );
}
