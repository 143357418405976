import { useEffect } from "react";
import useCurrentUser from "./account/useCurrentUser/useCurrentUser";
import { useTranslation } from "react-i18next";

export default function useSetLanguage() {
  const currentUser = useCurrentUser();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (currentUser?.language) {
      console.info("[change language] ", currentUser.language);
      i18n.changeLanguage(currentUser.language);
    }
  }, [i18n, currentUser]);
}
