import { selectChildProcess } from "@/redux/sidecar/selectors";
import { listen } from "@tauri-apps/api/event";
import { invoke } from "@tauri-apps/api/tauri";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function useSetForegroundWindowListener() {
  const dispatch = useDispatch();
  const child = useSelector(selectChildProcess);

  useEffect(() => {
    const unlisten = listen("set_foreground_window", async () => {
      await invoke("allow_set_foreground_window", { processId: child?.pid });
    });

    return () => {
      unlisten.then((f) => f());
    };
  }, [dispatch, child]);
}
