import useGetRandomInt from "@/hooks/useGetRandomInt";

const CELL_WIDTHS = ["w-20", "w-28", "w-32", "w-36", "w-40", "w-44", "w-48"];

export default function HeaderSkeleton() {
  const randomInt = useGetRandomInt(0, 6);

  return (
    <div data-theme="insoles" className="flex gap-5 w-full border-b">
      <div className="h-[60px] w-1/5 flex justify-center items-center">
        <div
          className={`skeleton !bg-neutral h-5 ${CELL_WIDTHS[randomInt]}`}
        ></div>
      </div>
      <div className="h-[60px] w-1/5 flex justify-center items-center">
        <div
          className={`skeleton !bg-neutral h-5 ${CELL_WIDTHS[randomInt]}`}
        ></div>
      </div>
      <div className="h-[60px] w-1/5 flex justify-center items-center">
        <div
          className={`skeleton !bg-neutral h-5 ${CELL_WIDTHS[randomInt]}`}
        ></div>
      </div>
      <div className="h-[60px] w-1/5 flex justify-center items-center">
        <div
          className={`skeleton !bg-neutral h-5 ${CELL_WIDTHS[randomInt]}`}
        ></div>
      </div>
      <div className="h-[60px] w-1/5 flex justify-center items-center">
        <div
          className={`skeleton !bg-neutral h-5 ${CELL_WIDTHS[randomInt]}`}
        ></div>
      </div>
    </div>
  );
}
