import { useMediaQuery } from "@uidotdev/usehooks";
import { useMemo } from "react";

export default function usePlatform() {
  const platform = import.meta.env.TAURI_PLATFORM;
  const isSmallDevice = useMediaQuery("only screen and (max-width: 768px)");
  const meta = useMemo(() => {
    const isIos = platform === "ios";
    const isAndroid = platform === "android";
    const isWindows = platform === "windows";
    const isLinux = platform === "linux";
    const isMacOs = platform === "macos";

    return {
      platform: platform,
      isWeb: !platform,
      isIos,
      isAndroid,
      isWindows,
      isLinux,
      isMacOs,
      isDesktop: isWindows || isLinux || isMacOs,
      isTouch: isIos || isAndroid,
      isMobile: isIos || isAndroid || isSmallDevice,
    };
  }, [platform, isSmallDevice]);

  return meta;
}
