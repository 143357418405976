import NavBar from "@/components/NavBar/NavBar"
import Search from "@/components/Search/Search"
import Table from "@/components/Table/Table"
import View from "@/components/View/View"
import NewClientButton from "@/components/buttons/NewClientButton/NewClientButton"
import NewSessionButton from "@/components/buttons/NewSessionButton/NewSessionButton"
import { selectSearch } from "@/redux/listFilter/selectors"
import { useSelector } from "react-redux"
import { Outlet, useNavigate } from "react-router-dom"
import ClientFilters from "./ClientFilters/ClientFilters"
import useColumnScaffold from "./hooks/useColumnScaffold"
import useColumnVisibility from "./hooks/useColumnVisibility"
import Header from "@/components/Header/Header"
import useAccountSubscription from "@/hooks/account/useAccountSubscription"
import MacTitleBar from "@/components/TitleBar/MacTitleBar/MacTitleBar"
import { useTranslation } from "react-i18next"
import { setSortClients } from "@/redux/ui/slice"
import { selectSortClients } from "@/redux/ui/selectors"
import useClientManyQuery from "./hooks/useClientManyQuery"

export default function ClientList() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const search = useSelector(selectSearch)
  const { isActive } = useAccountSubscription()
  const sort = useSelector(selectSortClients)
  const columns = useColumnScaffold()
  const columnVisibility = useColumnVisibility()
  const { pageInfo, clients, fetchMore, loading } = useClientManyQuery()

  return (
    <View>
      <MacTitleBar />

      <Header />

      <Outlet />

      <NavBar title={t("Clients")}>
        <Search />

        <ClientFilters />

        <NewSessionButton
          className="hidden md:flex"
          disabled={
            (!isActive &&
              t("Renew your subscription to create new sessions")) ||
            (clients &&
              clients.length === 0 &&
              t("You need to create a new client in order to start a session"))
          }
        />

        <NewClientButton className="hidden md:flex" />
      </NavBar>

      <Table
        data={clients}
        pageInfo={pageInfo}
        loading={loading}
        columns={columns}
        sort={sort}
        setSort={setSortClients}
        columnVisibility={columnVisibility}
        fetchMore={fetchMore}
        emptyMessage={search ? t("No search results found") : undefined}
        emptyDescription={
          search
            ? t(
                "Try adjusting your search criteria to find what you’re looking for."
              )
            : t("There are no clients")
        }
        rowOnClick={({ original }) => navigate(`${original.clientID}/sessions`)}
      />
    </View>
  )
}
