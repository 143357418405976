import { useEffect } from "react";
import useAccountSubscription from "./useAccountSubscription";
import SubscriptionToast from "@/components/toasts/SubscriptionToast";
import { useDispatch, useSelector } from "react-redux";
import { selectExpirationToasted } from "@/redux/ui/selectors";
import { setExpirationToasted } from "@/redux/ui/slice";
import { ROLE } from "@/views/UserList/constants";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { useLazyCurrentUser } from "./useCurrentUser/useCurrentUser";
import { toast } from "react-hot-toast";

const DURATION = 20000;
const TOAST_ID = "subscription";

export default function useCheckRemainingSubscription() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasToasted = useSelector(selectExpirationToasted);
  const subscription = useAccountSubscription();
  const getCurrentUser = useLazyCurrentUser();

  useEffect(() => {
    const fn = async () => {
      const currentUser = await getCurrentUser();
      const authUser = await Auth.currentAuthenticatedUser();

      if (!subscription.isActive) return;
      if (!authUser) return;
      if (currentUser?.role?.name === ROLE.XSENSOR_ADMIN) return;
      if (subscription.remainingDays > 30) return;
      if (hasToasted) return;

      toast(
        <SubscriptionToast
          title={t("Your subscription is about to expire")}
          message={`${t(
            "Your XSENSOR Clinical Foot and Gait Cloud subscription will expire in"
          )} ${subscription.remainingDays} ${
            subscription.remainingDays === 1 ? t("day") : t("days")
          } ${t("and")} ${subscription.andRemainingHours} ${
            subscription.andRemainingHours === 1 ? t("hour") : t("hours")
          }.`}
        />,
        {
          duration: DURATION,
          position: "bottom-center",
          id: TOAST_ID,
          style: { maxWidth: 400 },
          onClose: () => {
            dispatch(setExpirationToasted(true));
          },
        }
      );
    };

    fn();
  }, [dispatch, hasToasted, getCurrentUser, subscription, t]);

  useEffect(() => {
    const fn = async () => {
      const currentUser = await getCurrentUser();
      const authUser = await Auth.currentAuthenticatedUser();

      if (!subscription.expiredByHours) return;
      if (!authUser) return;
      if (currentUser?.role?.name === ROLE.XSENSOR_ADMIN) return;
      if (subscription.isActive) return;
      if (hasToasted) return;

      toast(
        <SubscriptionToast
          title={t("Your subscription has expired")}
          message={`${t(
            "Your XSENSOR Clinical Foot and Gait Cloud subscription expired"
          )} ${subscription.expiredByDays} ${
            subscription.expiredByDays === 1 ? t("day") : t("days")
          } ${t("and")} ${subscription.andExpiredHours} ${
            subscription.andExpiredHours === 1 ? t("hour") : t("hours")
          } ${t("ago.")}`}
        />,
        {
          duration: DURATION,
          position: "bottom-center",
          id: TOAST_ID,
          style: { maxWidth: 400 },
        }
      );

      dispatch(setExpirationToasted(true));
    };

    fn();
  }, [dispatch, hasToasted, subscription, getCurrentUser, t]);
}
