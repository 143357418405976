import RoleIcon from "@/assets/vectors/role-check.svg?react";
import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser";
import { useTranslation } from "react-i18next";
import SessionsToReconcile from "./SessoinsToReconcile.jsx/SessionsToReconcile";
import usePlatform from "@/hooks/tauri/usePlatform";
import ClientCount from "./ClientCount/ClientCount";
import { useQuery } from "@apollo/client";
import MY_FACILITY from "@/api/facility/myFacility";

export default function StatsCard({ className }) {
  const { t } = useTranslation();
  const { isWeb } = usePlatform();
  const currentUser = useCurrentUser();
  const { data } = useQuery(MY_FACILITY);
  const facility = data?.myFacility;

  return (
    <div className={`${className}`}>
      <div className="text-3xl text-base-100 font-bold mb-3 pb-2">
        {t("Account")}
      </div>

      <div className="stats shadow w-full p-3">
        {!isWeb && <ClientCount />}

        <div className="stat">
          <div className="stat-figure place-self-start">
            <RoleIcon />
          </div>

          <div className="stat-title opacity-100">{t("Role")}</div>

          <div className="stat-value mb-2 font-bold">
            {t(currentUser?.role?.name)}
          </div>

          <div className="stat-desc opacity-100">
            {t("Facility:")}
            {` `}
            {facility?.name}
          </div>
        </div>

        {!isWeb && <SessionsToReconcile />}
      </div>
    </div>
  );
}
