import { error } from "@/utilities/log";
import { Auth } from "aws-amplify";
import { useCallback, useState } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function useSendResetPasswordEmail(formValues) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const sendResetPasswordEmail = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        setLoading(true);
        await Auth.forgotPassword(formValues.email);

        navigate("../");
        toaster.success(t("Check email for confirmation code"));
      } catch (e) {
        toaster.error(t("Error sending reset password code"));
        error(e.message);
      } finally {
        setLoading(false);
      }
    },
    [formValues, navigate, t]
  );

  return { sendResetPasswordEmail, loading };
}
