import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";
import rootReducer from "@/redux/rootReducer";
import { persistReducer, persistStore } from "redux-persist";
import session from "redux-persist/lib/storage/session";
import { PERSIST_WHITELIST } from "../redux/rootReducer";

const persistConfig = {
  key: "root",
  storage: session,
  whitelist: PERSIST_WHITELIST,
};

export const listenerMiddleware = createListenerMiddleware();
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).prepend(
      listenerMiddleware.middleware
    ),
});

export const persistor = persistStore(store);
