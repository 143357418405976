import { useCallback } from "react";

export default function useDefaultFormValues(fetchFacility) {
  const getDefaultFormValues = useCallback(async () => {
    const { data } = await fetchFacility();

    return {
      name: data?.myFacility?.name,
      share: data?.myFacility?.shareAllClients,
      date:
        data?.myFacility?.subscription?.date &&
        new Date(data?.myFacility?.subscription?.date),
      RMA: data?.myFacility?.RMA,
    };
  }, [fetchFacility]);

  return getDefaultFormValues;
}
