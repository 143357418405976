import { useEffect } from "react";

export default function useCheckInputFocus(ref, callback) {
  useEffect(() => {
    const input = ref.current.querySelector("input");

    input?.addEventListener("focus", callback(input));
    input?.addEventListener("blur", callback(input));

    // Cleanup the event listeners
    return () => {
      input?.removeEventListener("focus", callback(input));
      input?.removeEventListener("blur", callback(input));
    };
  }, [ref, callback]);
}
