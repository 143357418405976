import { useMemo } from "react";
import DownloadSessionButton from "@/components/buttons/DownloadSessionButton/DownloadSessionButton";
import { DATA_PATH } from "@/utilities/paths/paths";
import { format } from "date-fns";
import Actions from "../Actions/Actions";
import { useTranslation } from "react-i18next";
import { CONVERTED_LANGUAGE } from "@/components/selects/SelectLanguage/constants";
import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser";
import RowCheckExpander from "@/components/RowCheckExpander/RowCheckExpander";
import ViewNotesLink from "@/components/buttons/ViewNotesLink/ViewNotesLink";
import { toaster } from "@/utilities/toaster";
import DownloadSessionReportButton from "@/components/buttons/DownloadSessionReportButton/DownloadSessionReportButton";
import { EXPORT_TYPE } from "@/constants";

export default function useColumnScaffold() {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const scaffold = useMemo(
    () =>
      [
        {
          id: "sessionType",
          accessorKey: "sessionType",
          header: t("Session Type"),
          size: 300,
          cell: ({ row: { original } }) => {
            return <RowCheckExpander original={original} />;
          },
          meta: {
            // cellClassName: "!justify-start",
            className: "!overflow-visible",
            isExpander: true,
          },
        },

        import.meta.env.DEV && {
          id: "sessionID",
          accessorKey: "sessionID",
          header: t("Session ID"),
          size: 200,
          cell: (info) => {
            return (
              <div
                className="cursor-pointer"
                onClick={() => {
                  // copy to clipboard
                  navigator.clipboard.writeText(info.getValue());
                  toaster.success(t("Session ID copied to clipboard"));
                }}
              >
                {info.getValue()}
              </div>
            );
          },
        },

        {
          id: "createdAt",
          accessorKey: "createdAt",
          header: t("Session Date"),
          cell: (info) => {
            return (
              <div className="flex justify-center w-full">
                {format(new Date(info.getValue()), "PPpp")}
              </div>
            );
          },
          enableResizing: false,
          meta: {
            className: "grow",
          },
        },

        {
          id: "sensors",
          accessorKey: "sensors",
          header: t("Sensors"),
          cell: (info) => {
            const val = info.getValue();
            return val?.join(", ");
          },
          meta: {
            className: "grow",
          },
        },

        {
          id: "notes",
          accessorKey: "notes",
          header: () => <span>{t("Notes")}</span>,
          cell: ({ row: { original } }) => <ViewNotesLink session={original} />,

          size: 250,

          meta: {
            className: "py-0 !overflow-visible",
          },
        },

        {
          id: "filePath",
          accessorKey: "filePath",
          header: "",
          cell: ({ row: { original } }) => {
            return original?.exportType === EXPORT_TYPE.CSV_REPORT ||
              original?.exportType === EXPORT_TYPE.PDF_REPORT ? (
              <DownloadSessionReportButton
                session={original}
                path={DATA_PATH.ACTIVE}
                isSubRow={original?.isSubRow}
              />
            ) : (
              <DownloadSessionButton
                session={original}
                path={DATA_PATH.ACTIVE}
                isSubRow={original?.isSubRow}
              />
            );
          },
          size: currentUser?.language === CONVERTED_LANGUAGE.de ? 150 : 120,
          enableResizing: false,
          enableSorting: false,
          meta: {
            className: "!overflow-visible justify-center relative",
          },
        },

        {
          id: "actions",
          accessorKey: "action",
          header: "",
          size: currentUser?.language === CONVERTED_LANGUAGE.de ? 130 : 90,
          cell: ({ row: { original } }) => <Actions session={original} />,
          enableResizing: false,
          enableSorting: false,
          meta: {
            className: "!overflow-visible grow",
          },
        },
      ].filter((column) => !!column),
    [currentUser, t]
  );

  return scaffold;
}
