import { gql } from "@apollo/client";

const PAGE_INFO = gql`
  fragment PageInfoFragment on PageInfo {
    hasNextPage
    hasPreviousPage
    currentOffset
    currentLimit
    currentPage
    totalPages
    totalDocuments
    cursor
  }
`;

export default PAGE_INFO;
