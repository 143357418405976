import { selectChildProcess } from "@/redux/sidecar/selectors";
import { info } from "@/utilities/log";
import { invoke } from "@tauri-apps/api/tauri";
import { useCallback } from "react";
import { useSelector } from "react-redux";

export default function useCheckIsDesktopRunning() {
  const childProcess = useSelector(selectChildProcess);
  const checkIsDesktopRunning = useCallback(
    async (chainProcess) => {
      info("[useCheckIsDesktopRunning] started ");
      const _childProcess = childProcess || chainProcess;
      if (_childProcess?.pid) {
        const isRunning = await invoke("is_pid_active", {
          desktopPid: _childProcess.pid,
        });

        if (isRunning) {
          info("[useCheckIsDesktopRunning] APPLICATION IS RUNNING...");

          return _childProcess;
        }
      }

      info("[useCheckIsDesktopRunning] APPLICATION IS NOT RUNNING...");
      return false;
    },
    [childProcess]
  );

  return checkIsDesktopRunning;
}
