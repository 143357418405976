import { components } from "react-select";
import { useTranslation } from "react-i18next";

export const SingleValue = ({ children, ...props }) => {
  const { t } = useTranslation();

  return (
    <components.SingleValue
      {...props}
      className={`SingleValue !text-base-content ${props.isDisabled && "!text-base-300"}`}
    >
      {t(children)}
    </components.SingleValue>
  );
};
