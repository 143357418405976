import { gql } from "@apollo/client";
import PAGE_INFO from "../pageInfo/pageInfoFragment";
import CLIENT from "./clientFragment";

const CLIENT_MANY = gql`
  query (
    $sort: JSON
    $filter: ClientFilterInput
    $privateIDs: [ID]
    $clientIDs: [ID]
    $groups: [String]
    $users: [ID]
    $limit: Int
    $offset: Int
  ) {
    clientMany(
      sort: $sort
      filter: $filter
      privateIDs: $privateIDs
      clientIDs: $clientIDs
      groups: $groups
      users: $users
      limit: $limit
      offset: $offset
    ) {
      payload {
        ...ClientFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }

  ${CLIENT}
  ${PAGE_INFO}
`;

export default CLIENT_MANY;
