import View from "@/components/View/View";
import Card from "./Card/Card";
import Header from "@/components/Header/Header";
import ScrollManager from "@/components/ScrollManager/ScrollManager";
import Version from "@/components/Account/Version/Version";
import MacTitleBar from "@/components/TitleBar/MacTitleBar/MacTitleBar";
import PersonalInfoCard from "./cards/PersonalInfoCard/PersonalnfoCard";
import AccountSecurityCard from "./cards/AccountSecurityCard/AccountSecurityCard";
import SettingsCard from "./cards/SettingsCard/SettingsCard";
import FacilityCard from "./cards/FacilityCard/FacilityCard";
import StatsCard from "./cards/StatsCard/StatsCard";
import DesktopSettingsCard from "./cards/DesktopSettingsCard/DesktopSettingsCard";
import useDefaultSettingsInfo from "@/hooks/account/useDefaultSettingsInfo/useDefaultSettingsInfo";
import { Outlet } from "react-router-dom";

export default function Account() {
  const { showCard } = useDefaultSettingsInfo();

  return (
    <View data-theme="insoles-light">
      <MacTitleBar />

      <Header />

      <Outlet />

      <ScrollManager>
        <div className="flex flex-col p-5">
          <StatsCard className="mb-5" />

          {showCard && <DesktopSettingsCard />}

          <Card className="mb-5">
            <Version className="w-full" />
          </Card>

          <PersonalInfoCard />

          <AccountSecurityCard />

          <SettingsCard />

          <FacilityCard />
        </div>
      </ScrollManager>
    </View>
  );
}
