import { selectOfflineMeta } from "@/redux/sessions/selectors";
import { setOfflineMeta } from "@/redux/sessions/slice";
import { info } from "@/utilities/log";
import { DATA_PATH } from "@/utilities/paths/paths";
import { BaseDirectory, readDir, readTextFile } from "@tauri-apps/api/fs";
import { basename } from "@tauri-apps/api/path";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function useOfflineMeta() {
  const meta = useSelector(selectOfflineMeta);
  const [getOfflineMeta, { loading }] = useLazyOfflineMeta();

  useEffect(() => {
    getOfflineMeta();
  }, [getOfflineMeta]);

  return { data: meta, refetch: getOfflineMeta, loading };
}

export function useLazyOfflineMeta() {
  const dispatch = useDispatch();
  const meta = useSelector(selectOfflineMeta);
  const [loading, setLoading] = useState(false);
  const fetch = useCallback(async () => {
    info("[useLazyOfflineMeta] started ");
    setLoading(true);
    const entries = await readDir(DATA_PATH.META, {
      dir: BaseDirectory.App,
      recursive: false,
    });

    const jsons =
      entries &&
      (await Promise.all(
        entries.map(async (entry) => {
          const filePath = entry.path;
          if (!filePath?.includes(".json")) return;

          const data = await readTextFile(filePath, {
            dir: BaseDirectory.App,
          });
          const key = await basename(filePath, ".json");

          return { key: key, data: data };
        })
      ));

    const _meta = jsons
      ?.map((json) => JSON.parse(json.data))
      .filter((meta) => meta);

    setLoading(false);
    dispatch(setOfflineMeta(_meta));

    info("[useOfflineMeta] completed ");

    return _meta;
  }, [dispatch]);

  return [fetch, { loading, refetch: fetch, data: meta }];
}
