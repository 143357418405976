import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Actions({ client }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { disabled, clientID } = client;

  return (
    <div className="flex w-full justify-center">
      <button
        className="text-decoration-none btn-ghost btn-xs btn text-success"
        onClick={(e) => {
          e.stopPropagation();
          navigate(`edit/${clientID}`);
        }}
      >
        {t("Edit")}
      </button>

      <button
        className={`text-decoration-none btn-ghost btn-xs btn ${
          disabled ? "text-success" : "text-red-500"
        } `}
        onClick={(e) => {
          e.stopPropagation();
          disabled
            ? navigate(`enable_client/${clientID}`)
            : navigate(`disable_client/${clientID}`);
        }}
      >
        {disabled ? t("Restore") : t("Archive")}
      </button>
    </div>
  );
}
