import { info } from "@/utilities/log";
import { DATA_PATH } from "@/utilities/paths/paths";
import {
  BaseDirectory,
  readDir,
  readTextFile,
  writeTextFile,
} from "@tauri-apps/api/fs";
import { useCallback } from "react";

export default function useUpdateAllMeta() {
  const updateAllMeta = useCallback(async (update) => {
    try {
      info("[useUpdateAllMeta] started ");
      const path = DATA_PATH.META;
      const entries = await readDir(path, {
        dir: BaseDirectory.App,
        recursive: false,
      });

      info("[useUpdateAllMeta] updating meta ", entries);

      entries &&
        (await Promise.all(
          entries.map(async (entry) => {
            const filePath = entry.path;
            if (!filePath?.includes(".json")) return;

            const data = await readTextFile(filePath, {
              dir: BaseDirectory.App,
            });

            const updated = {
              ...JSON.parse(data),
              ...update,
            };

            await writeTextFile(filePath, JSON.stringify(updated), {
              dir: BaseDirectory.App,
            });
          })
        ));

      info("[useUpdateAllMeta] completed ");
    } catch (e) {
      info("[useUpdateAllMeta] error: failed to update meta", e);
    }
  }, []);

  return updateAllMeta;
}
