import { useLazyHasPermission } from "@/hooks/account/useHasPermission/useHasPermission";
import useAccountSubscription from "@/hooks/account/useAccountSubscription";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function NewClientButton({ className }) {
  const { t } = useTranslation();
  const hasPermission = useLazyHasPermission();
  const { isActive } = useAccountSubscription();
  const disabled =
    (!isActive && t("Renew your subscription to create new clients")) ||
    (!hasPermission("clientCreate") &&
      t("You do not have permission to create new clients")) ||
    (hasPermission("spoofFacility") &&
      t("Xsensor admins cannot create clients"));

  return (
    <div
      data-tip={disabled}
      className={`tooltip-left ${disabled && "tooltip"}`}
    >
      <Link
        to="new_client"
        disabled={disabled}
        className={`btn-success btn-sm btn ${className}`}
      >
        {t("New Client")}
      </Link>
    </div>
  );
}
