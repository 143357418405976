import useInitializeApolloClient from "@/configurations/apollo/useInitializeApolloClient";
import { ApolloProvider } from "@apollo/client";
import { StrictMode } from "react";
import { RouterProvider } from "react-router-dom";
import SplashScreen from "../SplashScreen/SplashScreen";
import usePrepareForWeb from "@/hooks/tauri/usePrepareForWeb";
import useInitializeEnvironment from "@/hooks/environment/useInitializeEnvironment";
import useInitializeLanguage from "@/hooks/useInitializeLanguage";
import { ResetApolloContext } from "@/configurations/apollo/resetApolloContext";
import { persistor, store } from "@/configurations/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import router from "../Router/router";
import { Toaster } from "react-hot-toast";

export default function App() {
  usePrepareForWeb();
  useInitializeLanguage();
  const { client, reset, key } = useInitializeApolloClient();
  const isEnvironmentReady = useInitializeEnvironment(client);

  if (!isEnvironmentReady || !client) return <SplashScreen />;

  return (
    <>
      <Toaster toastOptions={{ duration: 2000 }} />

      <ApolloProvider client={client} key={key}>
        <StrictMode>
          <ResetApolloContext.Provider value={reset}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <RouterProvider router={router} />
              </PersistGate>
            </Provider>
          </ResetApolloContext.Provider>
        </StrictMode>
      </ApolloProvider>
    </>
  );
}
