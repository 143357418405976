import { useLazyQuery } from "@apollo/client";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FacilityForm from "../FacilityForm";
import useDefaultFormValues from "./useDefaultFormValues";
import MY_FACILITY from "@/api/facility/myFacility";
import useSubmit from "./useSubmit";

export default function EditFacilityForm({
  hiddenFields,
  className,
  title,
  goBack = false,
  ...props
}) {
  const { t } = useTranslation();
  const [fetchFacility, { refetch }] = useLazyQuery(MY_FACILITY);
  const defaultValues = useDefaultFormValues(fetchFacility);
  const form = useForm({
    defaultValues,
  });
  const { submit, loading } = useSubmit(form, refetch, goBack);

  return (
    <FacilityForm
      form={form}
      submit={submit}
      loading={loading}
      hiddenFields={hiddenFields}
      title={title}
      submitLabel={t("Update")}
      className={className}
      {...props}
    />
  );
}
