import useGetVersion from "@/hooks/tauri/useGetVersion";
import { selectDesktopBuildDate, selectDesktopVersion } from "@/redux/account/selectors";
import { selectEnvironment } from "@/redux/environment/selectors";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function Version({ className }) {
  const getVersion = useGetVersion();
  const [version, setVersion] = useState("");
  const { t } = useTranslation();
  const environment = useSelector(selectEnvironment);
  const desktopVersion = useSelector(selectDesktopVersion);
  const desktopBuildDate = useSelector(selectDesktopBuildDate);
  // global var defined in vite.config.js (comes from package.json version)
  // eslint-disable-next-line
  const buildDate = BUILD_TIMESTAMP;

  useEffect(() => {
    getVersion().then((version) => {
      setVersion(version);
    });
  }, [getVersion]);

  return (
    <div className={`mb-5 flex justify-center gap-10 ${className}`}>
      <div>
        <h4 className="mb-3 text-center">{t("Version")}</h4>

        <div className="text-center">{version}</div>
      </div>

      <div>
        <h4 className="mb-3 text-center">{t("Build Date")}</h4>

        <div className="text-center">{buildDate}</div>
      </div>

      {desktopVersion && (<div>
        <h4 className="mb-3 text-center">{t("Desktop Version")}</h4>

        <div className="text-center">{desktopVersion}</div>
      </div>)}

      {desktopBuildDate && (<div>
        <h4 className="mb-3 text-center">{t("Desktop Build Date")}</h4>

        <div className="text-center">{desktopBuildDate}</div>
      </div>)}

      {environment.STAGE !== "production" && (
        <div>
          <h4 className="mb-3 text-center">{t("Environment")}</h4>

          <div className="text-center">{environment.STAGE}</div>
        </div>
      )}
    </div>
  );
}
