import { DATA_PATH } from "@/utilities/paths/paths";
import { error, info } from "@/utilities/log";
import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";
import { basename, join } from "@tauri-apps/api/path";
import { removeFile, writeBinaryFile } from "@tauri-apps/api/fs";
import useUnzip from "../useUnzip/useUnzip";
import GET_DOWNLOAD_URL from "@/api/sync/getDownloadUrl";

export default function useSettingsDownload() {
  const unzipFiles = useUnzip();
  const [download] = useLazyQuery(GET_DOWNLOAD_URL);

  const settingsDownload = useCallback(
    async (desktopSettings, settingsActiveFilePath) => {
      info("[useSettingsDownload] started ", desktopSettings);

      const { zippedFileName, settings } = desktopSettings;
      const zippedFilePath = await join(settingsActiveFilePath, zippedFileName);

      try {
        const preSignedUrl = await download({
          variables: {
            fileName: zippedFileName,
          },
        });
        const response = await fetch(preSignedUrl?.data?.getDownloadUrl, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
        });
        const fileBlob = await response.blob();

        const fileContent = await fileBlob.arrayBuffer();

        await writeBinaryFile({ path: zippedFilePath, contents: fileContent });
        await unzipFiles(
          await join(DATA_PATH.SETTINGS_ACTIVE, zippedFileName),
          DATA_PATH.SETTINGS_ACTIVE
        );

        const downloadedSettings = await Promise.all(
          settings?.map(async ({ file, setting }) => {
            const fileName = await basename(file);
            const toPath = await join(settingsActiveFilePath, fileName);

            return {
              file: toPath,
              setting,
            };
          })
        );
        await removeFile(zippedFilePath);

        info("[useSettingsDownload] completed ", settings);

        return downloadedSettings;
      } catch (e) {
        error("[useSettingsDownload] Failed to download settings ", e);

        throw new Error(e);
      }
    },
    [download, unzipFiles]
  );

  return settingsDownload;
}
