import { format } from "date-fns";
import { ROLE } from "@/views/UserList/constants";
import useSelectCurrentFacility from "@/hooks/apolloState/useSelectCurrentFacility";
import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useDefaultSettingsInfo() {
  const { t } = useTranslation();
  const currentFacility = useSelectCurrentFacility();
  const currentUser = useCurrentUser();
  const currentRole = useMemo(() => currentUser?.currentRole?.name, [currentUser?.currentRole?.name]);

  const defaultSettingsInfo = useMemo(() => {
    const userDesktopSettings = currentRole === ROLE.STANDARD ? currentUser?.desktopSettings : undefined;
    const facilityDesktopSettings = currentRole === ROLE.ADMIN ? currentFacility?.desktopSettings : undefined;
    const desktopSettings = userDesktopSettings ?? facilityDesktopSettings;
    const updatedByYou = userDesktopSettings ? t("you") : undefined;

    return {
      showCard: [ROLE.ADMIN, ROLE.STANDARD].includes(currentRole),
      cardTitle: currentRole === ROLE.ADMIN ? t("Facility-wide Default Settings") : t("User Default Settings"),
      lastUpdatedBy: currentRole === ROLE.ADMIN ? desktopSettings?.lastUpdatedBy?.name : updatedByYou,
      lastUpdatedAt:  desktopSettings?.updatedAt ? format(new Date(desktopSettings.updatedAt), "PPpp") : undefined,
    };
  }, [currentFacility?.desktopSettings, currentRole, currentUser?.desktopSettings, t]);

  return defaultSettingsInfo;
}
