import { selectChildProcess } from "@/redux/sidecar/selectors";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appWindow } from "@tauri-apps/api/window";
import { invoke } from "@tauri-apps/api/tauri";

export default function useOnFocusChangedListener() {
  const dispatch = useDispatch();
  const child = useSelector(selectChildProcess);

  useEffect(() => {
    const unlisten = appWindow.onFocusChanged(async ({ payload: focused }) => {
      if (!child?.pid) return;

      await invoke("allow_set_foreground_window", { processId: child?.pid });
    });

    return () => {
      unlisten.then((f) => f());
    };
  }, [dispatch, child]);
}
