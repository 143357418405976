import { useMemo } from "react"
import Actions from "../Actions/Actions"
import { useTranslation } from "react-i18next"
import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser"
import { CONVERTED_LANGUAGE } from "@/components/selects/SelectLanguage/constants"

export default function useColumnScaffold() {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const scaffold = useMemo(
    () => [
      {
        id: "role",
        accessorKey: "role.name",
        header: t("Role"),
        cell: (info) => info.getValue(),
        meta: {
          className: "justify-center grow"
        }
      },

      {
        id: "name",
        accessorKey: "name",
        header: t("Name"),
        cell: (info) => info.getValue(),
        meta: {
          className: "justify-center grow"
        }
      },

      {
        id: "email",
        accessorKey: "email",
        header: t("Email"),
        cell: (info) => info.getValue(),
        meta: {
          className: "justify-center grow"
        }
      },

      {
        id: "actions",
        accessorKey: "name",
        header: t("Edit"),
        cell: ({ row: { original } }) => {
          return <Actions user={original} />
        },
        size: currentUser?.language === CONVERTED_LANGUAGE.de ? 370 : 270,
        enableSorting: false
      }
    ],
    [t, currentUser]
  )

  return scaffold
}
