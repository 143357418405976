import { useLocation } from "react-router-dom";
import { useLazyHasPermission } from "@/hooks/account/useHasPermission/useHasPermission";
import { useTranslation } from "react-i18next";
import usePlatform from "@/hooks/tauri/usePlatform";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "@/redux/account/selectors";
import { Link } from "react-router-dom";

export default function Tabs() {
  const location = useLocation();
  const { isWeb } = usePlatform();
  const { t } = useTranslation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const hasPermission = useLazyHasPermission();

  return (
    <>
      {isLoggedIn && !isWeb && (
        <Link
          to="/auth/clients"
          className={`cursor-pointer text-xl font-medium ${
            location.pathname.startsWith("/auth/clients") &&
            "border-b-2 border-primary text-primary"
          }`}
        >
          {t("Clients")}
        </Link>
      )}

      {isLoggedIn && isWeb && (
        <Link
          to="/auth/downloads"
          className={`cursor-pointer text-xl font-medium ${
            location.pathname.startsWith("/auth/downloads") &&
            "border-b-2 border-primary text-primary"
          }`}
        >
          {t("Downloads")}
        </Link>
      )}

      {isLoggedIn && hasPermission("userMany") && (
        <Link
          to="/auth/admin"
          className={`cursor-pointer text-xl font-medium ${
            location.pathname.startsWith("/auth/admin") &&
            "border-b-2 border-primary text-primary"
          }`}
        >
          {t("Users")}
        </Link>
      )}

      {!isWeb && (
        <Link
          to={isLoggedIn ? "/auth/offline" : "/offline"}
          className={`cursor-pointer text-xl font-medium ${
            location.pathname.includes("/offline") &&
            "border-b-2 border-primary text-primary"
          }`}
        >
          {t("Offline Sessions")}
        </Link>
      )}
    </>
  );
}
