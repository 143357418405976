import { DATA_PATH } from "@/utilities/paths/paths";
import { useCallback } from "react";
import { useLazyGetLocalXsns } from "./useGetLocalXsns/useGetLocalXsns";

export default function useGetLocalSessions() {
  const [fetchOfflineSessions] = useLazyGetLocalXsns(DATA_PATH.OFFLINE);
  const [fetchActiveSessions] = useLazyGetLocalXsns(DATA_PATH.ACTIVE);

  const getLocalSessions = useCallback(async () => {
    const offlineSessions = await fetchOfflineSessions();
    const activeSessions = await fetchActiveSessions();

    return [...offlineSessions, ...activeSessions];
  }, [fetchOfflineSessions, fetchActiveSessions]);

  return getLocalSessions;
}
