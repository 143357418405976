import useOfflineMeta from "@/hooks/io/useOfflineMeta";
import { getMetaPath } from "@/utilities/paths/getSessionPaths";
import { toaster } from "@/utilities/toaster";
import { BaseDirectory, writeTextFile } from "@tauri-apps/api/fs";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function useSubmit(form, session) {
  const navigate = useNavigate();
  const { getValues } = form;
  const { t } = useTranslation();
  const sessionID = session?.sessionID;
  const { refetch: refetchOffline } = useOfflineMeta();
  const submit = useCallback(async () => {
    const _session = {
      ...session,
      sessionType: getValues("sessionType")?.value,
      notes: getValues("notes"),
      createdAt: new Date().toISOString(),
    };
    const metaPath = await getMetaPath(sessionID);

    await writeTextFile(metaPath, JSON.stringify(_session), {
      dir: BaseDirectory.App,
    });

    refetchOffline();

    toaster.success(t("Session Updated"));
    navigate("../");
  }, [getValues, navigate, sessionID, /* refetch, */ session, t, refetchOffline]);

  return submit;
}
