import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import SessionForm from "../SessionForm";
import useAccountSubscription from "@/hooks/account/useAccountSubscription";
import { useMutation } from "@apollo/client";
import SESSION_INSERT from "@/api/session/sessionInsert";
import useSelectSession from "@/hooks/apolloState/useSelectSession/useSelectSession";
import useSelectClient from "@/hooks/apolloState/useSelectClient";
import { useTranslation } from "react-i18next";
import useSubmit from "./useSubmit";

export default function EditSessionForm() {
  const { sessionID } = useParams();
  const { t } = useTranslation();
  const [sessionInsert, { loading }] = useMutation(SESSION_INSERT);
  const session = useSelectSession(sessionID);
  const client = useSelectClient(session?.clientID);
  const form = useForm({
    defaultValues: {
      client,
      sessionType: session?.sessionType && {
        value: session?.sessionType,
        label: session?.sessionType,
      },
      notes: session?.notes,
    },
  });
  const { isActive } = useAccountSubscription();
  const submit = useSubmit(form, session, sessionInsert);
  const hiddenFields = useMemo(
    () => [
      (!isActive || session?.parentID) && "client",
      session?.parentID && "sessionType",
      "isTestSession",
    ],
    [isActive, session]
  );

  return (
    <SessionForm
      hideNewClientButton
      hiddenFields={hiddenFields}
      submitButtonLabel={t("Update Session")}
      title={t("Edit Session")}
      form={form}
      submit={submit}
      loading={loading}
    />
  );
}
