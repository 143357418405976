import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import SessionForm from "../SessionForm";
import useOfflineMeta from "@/hooks/io/useOfflineMeta";
import { useTranslation } from "react-i18next";
import useSubmit from "./useSubmit";

export default function EditOfflineSessionForm({ ...props }) {
  const { t } = useTranslation();
  const { sessionID } = useParams();
  const { data } = useOfflineMeta();
  const session = data.find((meta) => meta.sessionID === sessionID);
  const form = useForm({
    defaultValues: {
      ...session,
      sessionType: session?.sessionType && {
        value: session?.sessionType,
        label: session?.sessionType,
      },
    },
  });
  const hasParent = session?.parentID || session?.parentIDs;
  const submit = useSubmit(form, session);
  const hiddenFields = useMemo(
    () => ["isTestSession", "client", hasParent && "sessionType"],
    [session]
  );

  return (
    <SessionForm
      hideNewClientButton
      hiddenFields={hiddenFields}
      submitButtonLabel={t("Save")}
      title={t("Edit Session")}
      form={form}
      submit={submit}
      {...props}
    />
  );
}
