import { gql } from "@apollo/client";
import USER_FRAGMENT from "./userFragment";

const USER_CREATE = gql`
  mutation USER_CREATE(
    $email: String!
    $name: String!
    $role: String!
    $clients: [ID]
    $facility: ID
  ) {
    userCreate(
      email: $email
      name: $name
      role: $role
      clients: $clients
      facility: $facility
    ) {
      ...UserFragment
    }
  }

  ${USER_FRAGMENT}
`;

export default USER_CREATE;
