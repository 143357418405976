import toast from "react-hot-toast";

const dismissableToast = (toastFn) => (message) => {
  const toastId = toastFn(message);

  setTimeout(() => {
    toast.dismiss(toastId);
  }, 2000);
};

export const toaster = {
  defaultToast: dismissableToast(toast),
  success: dismissableToast(toast.success),
  error: dismissableToast(toast.error),
};
