import { setContext } from "@apollo/client/link/context";
import { Auth } from "aws-amplify";

const authLink = setContext((_, { headers }) => {
  return Auth.currentSession().then((tokens) => {
    return {
      headers: {
        ...headers,
        Authorization: tokens?.accessToken?.jwtToken,
        "authorization-id": tokens?.idToken?.jwtToken,
      },
    };
  });
});

export default authLink;
