import Filters from "@/components/NavBar/Filters/Filters";
import InputField from "@/components/forms/formComponents/InputField/InputField";
import SelectClients from "@/components/selects/SelectClients/SelectClients";
import SelectGroups from "@/components/selects/SelectGroups/SelectGroups";
import SelectPrivateIDs from "@/components/selects/SelectPrivateIDs/SelectPrivateIDs";
import SelectUsers from "@/components/selects/SelectUsers/SelectUsers";
import { useLazyHasPermission } from "@/hooks/account/useHasPermission/useHasPermission";
import {
  selectFilterClientIDs,
  selectFilterGroups,
  selectFilterHideDisabledClients,
  selectFilterPrivateIDs,
  selectFilterUsers,
} from "@/redux/listFilter/selectors";
import {
  clearOffset,
  setFilterClientIDs,
  setFilterGroups,
  setFilterHideDisabledClients,
  setFilterPrivateIDs,
  setFilterUsers,
} from "@/redux/listFilter/slice";
import { ROLE } from "@/views/UserList/constants";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ToggleShowDisabled from "./ToggleShowDisabled/ToggleShowDisabled";

export default function ClientFilters() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const hasPermission = useLazyHasPermission();
  const filterPrivateIDs = useSelector(selectFilterPrivateIDs);
  const filterGroups = useSelector(selectFilterGroups);
  const filterUsers = useSelector(selectFilterUsers);
  const filterClientIDs = useSelector(selectFilterClientIDs);
  const filterHideDisabledClients = useSelector(
    selectFilterHideDisabledClients
  );
  const { control, reset, watch } = useForm({
    defaultValues: {
      privateIDs: filterPrivateIDs,
      groups: filterGroups,
      users: filterUsers,
      clientIDs: filterClientIDs,
    },
  });
  const { privateIDs, groups, users, clientIDs } = watch();

  const isFiltered =
    privateIDs?.length > 0 ||
    groups?.length > 0 ||
    users?.length > 0 ||
    clientIDs?.length > 0 ||
    !filterHideDisabledClients;

  // sync form state with redux
  useEffect(() => {
    dispatch(setFilterPrivateIDs(privateIDs));
    dispatch(setFilterGroups(groups));
    dispatch(setFilterUsers(users));
    dispatch(setFilterClientIDs(clientIDs));
  }, [dispatch, privateIDs, groups, users, clientIDs]);

  return (
    <div>
      <Filters
        isFiltered={isFiltered}
        clearAllFilters={() => {
          dispatch(clearOffset());
          reset({ privateIDs: [], groups: [], users: [], clientIDs: [] });
          dispatch(setFilterHideDisabledClients(true));
        }}
      >
        {hasPermission("spoofFacility") ? (
          <InputField label={t("Clients")}>
            <SelectClients control={control} />
          </InputField>
        ) : (
          <InputField label={t("Clients")} className="!pb-0 mb-3">
            <SelectPrivateIDs control={control} />
          </InputField>
        )}

        <InputField label={t("Groups")} className="!pb-0 mb-3">
          <SelectGroups control={control} />
        </InputField>

        <InputField label={t("Clinicians")} className="!pb-0">
          <SelectUsers roles={[ROLE.STANDARD]} control={control} />
        </InputField>

        <div className="divider"></div>

        <ToggleShowDisabled />
      </Filters>
    </div>
  );
}
