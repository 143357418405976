import { gql } from "@apollo/client";

const CLIENT_LIST = gql`
  query CLIENT_LIST {
    clientList {
      id
      fullname
      clientID
    }
  }
`;

export default CLIENT_LIST;
