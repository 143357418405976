import SelectRowCheckbox from "@/components/buttons/SelectRowCheckbox/SelectRowCheckBox";
import isSessionReport from "@/utilities/isSessionReport";
import formatExportType from "@/utilities/text/formatExportType";
import { CiLink } from "react-icons/ci";

export default function RowCheckExpander({ original }) {
  const isReport = isSessionReport(original?.exportType);
  const isCompare = original?.parentIDs?.length > 1;
  const parents = original?.parentIDs?.join(" 🔗 ");

  return (
    <div className="RowCheckExpander w-full flex items-center gap-3 !overflow-visible">
      <div className="CheckExpander flex items-center justify-start gap-3 truncate">
        {!isReport && <SelectRowCheckbox session={original} />}

        <span className="truncate">
          {formatExportType(original?.exportType) || original?.sessionType}
        </span>
      </div>

      {isReport && isCompare && (
        <span
          data-tip={parents}
          className="tooltip tooltip-right before:!max-w-3xl before:overflow-wrap before:whitespace-pre-line 
            before:break-words before:content-[attr(data-tip)]"
        >
          <CiLink />
        </span>
      )}
    </div>
  );
}
