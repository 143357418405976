import useRetry from "@/hooks/io/useRetry/useRetry";
import { removeChildProcess, setChildProcess } from "@/redux/sidecar/slice";
import { error, info } from "@/utilities/log";
import { invoke } from "@tauri-apps/api/tauri";
import { Command } from "@tauri-apps/api/shell";
import { useCallback } from "react";
import { toaster } from "@/utilities/toaster";
import { useDispatch, useSelector } from "react-redux";
import useVerifyDesktop from "../useVerifyDesktopApp.js/useVerifyDesktop";
import { useTranslation } from "react-i18next";
import { selectEnvironment } from "@/redux/environment/selectors";
import { AUTO_LOGOUT_TIME_SECONDS } from "@/hooks/account/constants";

const LANGUAGE_TO_EXE = {
  en: "xsensorFGCC",
  de: "xsensorFGCCDEU",
};

export default function useOpenDesktopApp() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const verifyDesktop = useVerifyDesktop();
  const retry = useRetry();
  const environment = useSelector(selectEnvironment);
  const openDesktopApp = useCallback(
    async (chainProcess, downloadDesktopSettings = undefined) => {
      const { isDesktopRunning } = await verifyDesktop(chainProcess);

      if (isDesktopRunning) return isDesktopRunning;

      info("[useOpenDesktopApp] started ");
      const language = i18n.language || "en";
      // open NEW desktop instance
      const command = new Command(LANGUAGE_TO_EXE[language], [
        `--DCS ${environment.TCP_PORT} --nosplash`,
      ]);

      // remove stored child process when desktop app is closed and destroy connection
      command.on("close", async () => {
        info("[useOpenDesktopApp] CLOSING CHILD PROCESS...");
        dispatch(removeChildProcess());

        await invoke("destroy_connection");
      });

      const child = await command.spawn();

      dispatch(setChildProcess(child));

      info("[useOpenDesktopApp] OPENING DESKTOP APP...");
      await retry(
        // retries
        10,
        // time between retries
        1000,
        // callback
        async () => await invoke("start_desktop_connection"),
        // onFail cleanup
        async () => {
          await child?.kill?.();
          dispatch(removeChildProcess());
          const message = t("Failed to open Foot and Gait Desktop");

          toaster.error(message);
          error(message);
        }
      );
      info("[useOpenDesktopApp] DESKTOP APP OPENED...");

      await invoke("init_connection", {
        activityLogoutPeriod: AUTO_LOGOUT_TIME_SECONDS,
      });

      const settings =
        downloadDesktopSettings && (await downloadDesktopSettings?.());

      settings &&
        (await invoke("user_settings_sync", {
          settings,
        }));

      // allows desktop app to take focus
      await invoke("allow_set_foreground_window", { processId: child?.pid });

      info("[useOpenDesktopApp] completed ");

      return child;
    },
    [verifyDesktop, i18n.language, environment.TCP_PORT, dispatch, retry, t]
  );

  return openDesktopApp;
}
