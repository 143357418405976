import { useCallback, useState } from "react";
import useCheckInputFocus from "./useCheckInputFocus";

export default function useShowTooltip(ref) {
  const [showTooltip, setShowTooltip] = useState(false);
  const checkFocus = useCallback(
    (input) => {
      return () => {
        if (input && document.activeElement === input) {
          setShowTooltip(true);
        } else {
          setShowTooltip(false);
        }
      };
    },
    [setShowTooltip]
  );

  useCheckInputFocus(ref, checkFocus);

  return showTooltip;
}
