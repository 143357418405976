import { gql } from "@apollo/client";
import ENVIRONMENT from "./environmentFragment";

const CURRENT_ENVIRONMENT = gql`
  query CURRENT_ENVIRONMENT($version: String!) {
    currentEnvironment(version: $version) {
      ...EnvironmentFragment
    }
  }

  ${ENVIRONMENT}
`;

export default CURRENT_ENVIRONMENT;
