import AuthenticatedRoutes from "@/views/AuthenticatedRoutes/AuthenticatedRoutes";
import Login from "@/views/Login/Login";
import OfflineSessionList from "@/views/OfflineSessionsList/OfflineSessionsList";
import Root from "@/views/Root/Root";
import {
  Link,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import Downloads from "@/views/Downloads/Downloads";
import SessionList from "@/views/SessionList/SessionList";
import Account from "@/views/Account/Account";
import UserList from "@/views/UserList/UserList";
import SwitchAuthenticatedRoute from "../App/SwtichAuthenticatedRoute/SwitchAuthenticatedRoute";
import Modal from "../Modal/Modal";
import { Trans } from "react-i18next";
import RequestResetPassword from "../Login/RequestResetPassword/RequestResetPassword";
import ResetPasswordCode from "../Login/ResetPasswordCode/ResetPasswordCode";
import CompleteChangePasswordForm from "@/views/Login/CompleteChangePasswordForm/CompleteChangePasswordForm";
import ResetPassword from "../forms/ResetPasswordForm/ResetPasswordForm";
import VerificationCodeForm from "@/views/Login/VerificationCodeForm/VerificationCodeForm";
import ClientListCrumb from "./crumbs/ClientListCrumb";
import ClientList from "@/views/ClientList/ClientList";
import NewSessionForm from "../forms/SessionForm/NewSessionForm/NewSessionForm";
import NewClientForm from "../forms/ClientForm/NewClientForm/NewClientForm";
import EditClientForm from "../forms/ClientForm/EditClientForm/EditClientForm";
import AllAssignedClinicians from "@/views/ClientList/AllAssignedClinicians/AllAssignedClinicians";
import EnableClient from "@/views/ClientList/EnableClient/EnableClient";
import DisableClient from "@/views/ClientList/DisableClient/DisableClient";
import CurrentRoleForm from "../forms/CurrentRoleForm/CurrentRoleForm";
import DisplaySessionNotes from "../DisplaySessionNotes/DisplaySessionNotes";
import DeleteOfflineSession from "@/views/OfflineSessionsList/DeleteOfflineSession/DeleteOfflineSession";
import EditOfflineSessionForm from "../forms/SessionForm/EditOfflineSessionForm/EditOfflineSessionForm";
import ReconcileSessionForm from "../forms/SessionForm/ReconcileSessionForm/ReconcileSessionForm";
import ForceOpenForm from "../ForceOpenForm/ForceOpenForm";
import { OFFLINE_SESSION_FORM_FIELDS } from "@/views/ClientList/constants";
import EditSessionForm from "../forms/SessionForm/EditSessionForm/EditSessionForm";
import ArchiveSession from "@/views/SessionList/ArchiveSession/ArchiveSession";
import NewUserForm from "../forms/UserForm/NewUserForm/NewUserForm";
import EditUserForm from "../forms/UserForm/EditUserForm/EditUserForm";
import DisableUser from "@/views/UserList/DisableUser/DisableUser";
import EnableUser from "@/views/UserList/EnableUser/EnableUser";
import SendResetPasswordEmail from "@/views/UserList/SendResetPasswordEmail/SendResetPasswordEmail";
import PersonalInfoForm from "../forms/PersonalInfoForm/PersonalInfoForm";
import SwitchFacilityForm from "../forms/FacilityForm/SwitchFacilityForm/SwitchFacilityForm";
import SettingsForm from "../forms/SettingsForm/SettingsForm";
import CreateFacilityForm from "../forms/FacilityForm/CreateFacilityForm/CreateFacilityForm";
import EditFacilityForm from "../forms/FacilityForm/EditFacilityForm/EditFacilityForm";
import Listeners from "@/views/Root/Listeners/Listeners";

export default createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      <Route path="/*" element={<ErrorBoundary />}>
        <Route path="*" element={<Login />}>
          <Route element={<Modal />}>
            <Route
              path="send_reset_password_email"
              element={<RequestResetPassword />}
            />
            <Route path="reset_password_code" element={<ResetPasswordCode />} />
            <Route
              path="complete_change_password"
              element={<CompleteChangePasswordForm />}
            />
            <Route path="reset_password" element={<ResetPassword />} />
            <Route path="verify" element={<VerificationCodeForm />} />
          </Route>
        </Route>

        <Route element={<Listeners />}>
          <Route
            path="offline/*"
            element={<OfflineSessionList />}
            handle={{ crumb: () => "Offline" }}
          >
            <Route element={<Modal />}>
              <Route
                path="delete/:sessionID"
                element={<DeleteOfflineSession />}
                handle={{ crumb: () => <Trans>Delete Session</Trans> }}
              />
              <Route
                path="edit/:sessionID"
                element={<EditOfflineSessionForm />}
                handle={{ crumb: () => <Trans>Edit Session</Trans> }}
              />
              <Route
                path="reconcile/:sessionID"
                element={
                  <ReconcileSessionForm
                    submitButtonLabel="Reconcile"
                    title="Reconcile Session"
                  />
                }
                handle={{ crumb: () => <Trans>Reconcile Session</Trans> }}
              />
              <Route
                path="new_session"
                element={<NewSessionForm offline hiddenFields={["client"]} />}
                handle={{ crumb: () => <Trans>New Session</Trans> }}
              />
              <Route
                path="notes/:id"
                element={<DisplaySessionNotes />}
                handle={{ crumb: () => <Trans>Notes Session</Trans> }}
              />
            </Route>
          </Route>

          <Route path="auth/*" element={<AuthenticatedRoutes />}>
            <Route
              path="*"
              element={<SwitchAuthenticatedRoute />}
              handle={{ crumb: () => "AuthenticatedRoutes" }}
            />

            <Route
              path="downloads/*"
              element={<Downloads />}
              handle={{ crumb: () => <Trans>Downloads</Trans> }}
            />

            <Route
              path="account/*"
              handle={{ crumb: () => <Trans>Account</Trans> }}
            >
              <Route path="*" element={<Account />}>
                <Route element={<Modal />}>
                  <Route
                    path="edit_personal_info"
                    element={<PersonalInfoForm />}
                  />
                  <Route path="edit_password" element={<ResetPassword />} />
                  <Route path="admin" element={<SwitchFacilityForm />} />
                  <Route path="settings" element={<SettingsForm />} />
                  <Route path="new_facility" element={<CreateFacilityForm />} />
                  <Route
                    path="edit_facility"
                    element={
                      <EditFacilityForm
                        hiddenFields={["share"]}
                        title={<Trans>Edit Facility</Trans>}
                        className="h-full"
                        goBack
                      />
                    }
                  />
                </Route>
              </Route>
            </Route>

            <Route
              path="admin/*"
              handle={{ crumb: () => <Trans>Admin</Trans> }}
            >
              <Route path="*" element={<UserList />}>
                <Route element={<Modal />}>
                  <Route
                    path="create_user"
                    element={<NewUserForm />}
                    handle={{ crumb: () => <Trans>Create User</Trans> }}
                  />
                  <Route
                    path="edit/:userID"
                    element={<EditUserForm />}
                    handle={{ crumb: () => <Trans>Edit User</Trans> }}
                  />
                  <Route
                    path="disable_user/:userID"
                    element={<DisableUser />}
                    handle={{ crumb: () => <Trans>Disable User</Trans> }}
                  />
                  <Route
                    path="enable_user/:userID"
                    element={<EnableUser />}
                    handle={{ crumb: () => <Trans>Enable User</Trans> }}
                  />
                  <Route
                    path="reset_password/:userID"
                    element={<ResetPassword />}
                    handle={{ crumb: () => <Trans>Reset Password</Trans> }}
                  />
                  <Route
                    path="send_reset_password_email/:userID"
                    element={<SendResetPasswordEmail />}
                    handle={{
                      crumb: () => <Trans>Send Reset Password Email</Trans>,
                    }}
                  />
                </Route>
              </Route>
            </Route>

            <Route
              path="clients/*"
              handle={{
                crumb: () => (
                  <Link to="/auth/clients">
                    <Trans>Clients</Trans>
                  </Link>
                ),
              }}
            >
              <Route path="*" element={<ClientList />}>
                <Route element={<Modal />}>
                  <Route
                    path="new_session"
                    element={<NewSessionForm offline={false} />}
                    handle={{ crumb: () => <Trans>New Session</Trans> }}
                  />

                  <Route
                    path="new_client"
                    element={<NewClientForm />}
                    handle={{ crumb: () => <Trans>New Client</Trans> }}
                  />

                  <Route
                    path="edit/:clientID"
                    element={<EditClientForm />}
                    handle={{ crumb: () => <Trans>Edit Client</Trans> }}
                  />

                  <Route
                    path="all_assigned_clinicians"
                    element={<AllAssignedClinicians />}
                    handle={{
                      crumb: () => <Trans>All Assigned Clinicians</Trans>,
                    }}
                  />

                  <Route
                    path="enable_client/:clientID"
                    element={<EnableClient />}
                    handle={{ crumb: () => <Trans>Enable Client</Trans> }}
                  />

                  <Route
                    path="disable_client/:clientID"
                    element={<DisableClient />}
                    handle={{ crumb: () => <Trans>Disable Client</Trans> }}
                  />

                  <Route
                    path="select_role"
                    element={<CurrentRoleForm />}
                    handle={{ crumb: () => <Trans>Select Role</Trans> }}
                  />

                  <Route
                    path="notes/:id"
                    element={<DisplaySessionNotes />}
                    handle={{ crumb: () => <Trans>Notes</Trans> }}
                  />
                </Route>
              </Route>

              <Route
                path=":clientID/sessions/*"
                handle={{
                  crumb: ClientListCrumb,
                }}
              >
                <Route path="*" element={<SessionList />}>
                  <Route element={<Modal />}>
                    <Route
                      path="new_session"
                      element={
                        <NewSessionForm fields={OFFLINE_SESSION_FORM_FIELDS} />
                      }
                      handle={{ crumb: () => <Trans>New Session</Trans> }}
                    />
                    <Route
                      path=":sessionID/force_open"
                      element={<ForceOpenForm />}
                      handle={{ crumb: () => <Trans>New Session</Trans> }}
                    />
                    <Route
                      path="new_client"
                      element={<NewClientForm />}
                      handle={{ crumb: () => <Trans>New Client</Trans> }}
                    />
                    <Route
                      path="edit/:sessionID"
                      element={<EditSessionForm />}
                      handle={{ crumb: () => <Trans>Edit Session</Trans> }}
                    />
                    <Route
                      path="notes/:id"
                      element={<DisplaySessionNotes />}
                      handle={{ crumb: () => <Trans>Notes</Trans> }}
                    />
                    <Route
                      path="archive/:sessionID/"
                      element={<ArchiveSession />}
                      handle={{ crumb: () => <Trans>Archive</Trans> }}
                    />
                    <Route
                      path="restore/:sessionID/"
                      element={<ArchiveSession />}
                      handle={{ crumb: () => <Trans>Restore</Trans> }}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route
              path="offline/*"
              handle={{ crumb: () => <Trans>Offline</Trans> }}
            >
              <Route path="*" element={<OfflineSessionList />}>
                <Route element={<Modal />}>
                  <Route
                    path="delete/:sessionID"
                    element={<DeleteOfflineSession />}
                    handle={{ crumb: () => <Trans>Delete Session</Trans> }}
                  />
                  <Route
                    path="edit/:sessionID"
                    element={<EditOfflineSessionForm />}
                    handle={{ crumb: () => <Trans>Edit Session</Trans> }}
                  />
                  <Route
                    path="reconcile/:sessionID"
                    element={
                      <ReconcileSessionForm
                        submitButtonLabel="Reconcile"
                        title="Reconcile Session"
                      />
                    }
                    handle={{ crumb: () => <Trans>Reconcile Session</Trans> }}
                  />
                  <Route
                    path="new_session"
                    element={
                      <NewSessionForm offline hiddenFields={["client"]} />
                    }
                    handle={{ crumb: () => <Trans>New Session</Trans> }}
                  />
                  <Route
                    path="notes/:id"
                    element={<DisplaySessionNotes />}
                    handle={{ crumb: () => <Trans>Notes Session</Trans> }}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  )
);
