import CompareSessionsButton from "@/components/CompareSessionsButton/CompareSessionsButton";
import NavBar from "@/components/NavBar/NavBar";
import Search from "@/components/Search/Search";
import Table from "@/components/Table/Table";
import View from "@/components/View/View";
import NewSessionButton from "@/components/buttons/NewSessionButton/NewSessionButton";
import useSelectClient from "@/hooks/apolloState/useSelectClient";
import { selectSearch } from "@/redux/listFilter/selectors";
import { useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import useColumnScaffold from "./hooks/useColumnScaffold";
import Header from "@/components/Header/Header";
import toNameCase from "@/utilities/text/toNameCase";
import useAccountSubscription from "@/hooks/account/useAccountSubscription";
import MacTitleBar from "@/components/TitleBar/MacTitleBar/MacTitleBar";
import { useTranslation } from "react-i18next";
import { selectSortSessions } from "@/redux/ui/selectors";
import { setSortSessions } from "@/redux/ui/slice";
import SessionFilters from "./SessionFilters/SessionFilters";
import useSessionManyQuery from "./hooks/useSessionManyQuery";
import useDownloadDesktopSettings from "@/hooks/io/useDownloadDesktopSettings/useDownloadDesktopSettings";

export default function SessionList() {
  const { t } = useTranslation();
  const { clientID } = useParams();
  const columns = useColumnScaffold();
  const search = useSelector(selectSearch);
  const { isActive } = useAccountSubscription();
  const client = useSelectClient(clientID);
  const sort = useSelector(selectSortSessions);
  const { sessions, loading, pageInfo, fetchMore } = useSessionManyQuery();
  const downloadDesktopSettings = useDownloadDesktopSettings();

  return (
    <View>
      <MacTitleBar />

      <Outlet />

      <Header />

      <NavBar title={toNameCase(`${client?.fullname}`)}>
        <Search />
        <SessionFilters />
        <CompareSessionsButton
          downloadDesktopSettings={downloadDesktopSettings}
        />
        <NewSessionButton
          disabled={
            !isActive && t("Renew your subscription to create new sessions")
          }
        />
      </NavBar>

      <Table
        data={sessions}
        subRowKey="children"
        pageInfo={pageInfo}
        loading={loading}
        columns={columns}
        sort={sort}
        setSort={setSortSessions}
        fetchMore={fetchMore}
        emptyMessage={search ? t("No search results found") : t("No data")}
        emptyDescription={
          search
            ? t(
                "Try adjusting your search criteria to find what you’re looking for."
              )
            : t("There are no sessions for this client")
        }
      />
    </View>
  );
}
