import PRIVATE_ID_LIST from "@/api/lists/privateIDList";
import { useQuery } from "@apollo/client";

import SuperSelect from "../SuperSelect/SuperSelect";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function SelectPrivateIDs({ control, rules, ...props }) {
  const { t } = useTranslation();
  const { data, previousData, loading } = useQuery(PRIVATE_ID_LIST);
  const privateIDs = data?.privateIDList || previousData?.privateIDList;

  return (
    <Controller
      control={control}
      name="privateIDs"
      rules={rules}
      render={({ field }) => {
        return (
          <SuperSelect
            placeholder={t("Client ID's")}
            options={privateIDs?.map((privateID) => ({
              value: privateID,
              label: privateID,
            }))}
            isLoading={loading}
            closeMenuOnSelect={false}
            isMulti
            isDisabled={props?.disabled}
            isClearable={true}
            {...field}
            {...props}
          />
        );
      }}
    />
  );
}
