import SuperSelect from "../SuperSelect/SuperSelect";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LANGUAGE_OPTIONS } from "./constants";

export default function SelectLanguage({ control, ...props }) {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name="language"
      render={({ field }) => {
        return (
          <SuperSelect
            placeholder={t("Language")}
            options={LANGUAGE_OPTIONS}
            {...field}
            {...props}
          />
        );
      }}
    />
  );
}
