import { info } from "@/utilities/log";
import { listen } from "@tauri-apps/api/event";
import { useEffect } from "react";

export default function useListenToUpdateStatus() {
  useEffect(() => {
    const unlisten = listen("tauri://update-status", async (status) => {
      info("UPDATE STATUS: ", status);
    });

    return () => {
      unlisten.then((f) => f());
    };
  }, []);
}
