import { listen } from "@tauri-apps/api/event";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLazyOfflineMeta } from "../io/useOfflineMeta";
import { useLazyGetLocalXsns } from "../integration/useGetLocalXsns/useGetLocalXsns";
import { setOpenSessions } from "@/redux/sessions/slice";
import { setChildProcess } from "@/redux/sidecar/slice";

export default function useShutdownChildListener() {
  const dispatch = useDispatch();
  const [refetchOfflineMeta] = useLazyOfflineMeta();
  const [refetchLocalXsns] = useLazyGetLocalXsns();

  useEffect(() => {
    const unlisten = listen("shutdown_child", async () => {
      refetchOfflineMeta();
      refetchLocalXsns();

      dispatch(setOpenSessions([]));
      dispatch(setChildProcess(null));
    });

    return () => {
      unlisten.then((f) => f());
    };
  }, [dispatch, refetchOfflineMeta, refetchLocalXsns]);
}
