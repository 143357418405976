import { flexRender } from "@tanstack/react-table";
import Expander from "./Expander/Expander";

export default function Cell({ cell, virtualizer }) {
  const {
    getContext,
    column: { columnDef, getSize },
  } = cell;
  const isExpander = cell.column.columnDef.meta?.isExpander;

  return (
    <div
      style={{ width: `${getSize()}px` }}
      className={`Cell relative flex items-center px-3 select-none truncate border-b border-slate-200 
      text-center font-medium 
      ${cell.column.columnDef.meta?.className} ${cell.column.columnDef.meta?.cellClassName}`}
      key={cell.id}
    >
      {isExpander ? (
        <Expander cell={cell} virtualizer={virtualizer} />
      ) : (
        flexRender(columnDef.cell, getContext())
      )}
    </div>
  );
}
