import SessionLockedButton from "./SessionLockedButton/SessionLockedButton";
import useProgressInterval from "@/hooks/io/useProgressInterval";
import useSubmit from "./hooks/useSubmit";
import { useTranslation } from "react-i18next";

export default function DownloadSessionButton({
  path,
  session,
  forceOpen,
  isSubRow,
  children,
  buttonClassName,
}) {
  const { t } = useTranslation();
  const { sessionID } = session;
  const { start, stop, progress } = useProgressInterval();
  const { submit, opening } = useSubmit({
    session,
    path,
    startProgress: start,
    stopProgress: stop,
    forceOpen,
  });

  if (session.locked && !forceOpen)
    return <SessionLockedButton session={session} />;

  return (
    <div className="h-100 flex items-center justify-center">
      {opening ? (
        <button
          disabled
          className={`btn-accent btn-xs btn ${
            isSubRow ? "btn-ghost text-success" : "btn-outline"
          } ${buttonClassName}`}
        >
          {t(`Opening...`)}
        </button>
      ) : progress[sessionID] ? (
        <progress
          className="w-24 progress progress-success shadow-inner"
          value={progress[sessionID] || 0}
          max="100"
        />
      ) : (
        <button
          className={`btn-success btn-xs btn ${
            isSubRow ? "btn-ghost text-success" : "btn-outline"
          } ${buttonClassName}`}
          onClick={submit}
        >
          {children || t("Download")}
        </button>
      )}
    </div>
  );
}
