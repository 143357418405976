import { createSlice } from "@reduxjs/toolkit";
import purge, { PURGE } from "../purge";

const initialState = {
  syncing: {},
  openSessions: [],
  reconcilingSessions: {},
  // this is a cache of the meta data of newly created sessions (used for exported sessions to reference clientID)
  metaCache: {},
  openingSessions: {},
  offlineMeta: [],
  // this is xsn meta data that is read directly from the xsn file (different than the json meta data)
  active: [],
  offline: [],
};

export const sessionsSlice = createSlice({
  name: "SESSION",
  initialState,
  reducers: {
    setSyncingSession: (state, { payload }) => {
      const { sessionID, promise, isSynced } = payload;

      if (!state.syncing[sessionID]) {
        state.syncing[sessionID] = { promise: undefined, isSynced: false };
      }

      state.syncing[sessionID].promise = promise;
      state.syncing[sessionID].isSynced = isSynced;
    },
    setOpenSessions: (state, { payload }) => {
      state.openSessions = payload;
    },
    addReconcilingSession: (state, { payload }) => {
      state.reconcilingSessions[payload] = true;
    },
    removeReconcilingSession: (state, { payload }) => {
      state.reconcilingSessions[payload] = false;
    },
    addOpenSession: (state, { payload }) => {
      state.openSessions.push(payload);
    },
    removeOpenSession: (state, { payload }) => {
      state.openSessions = state.openSessions.filter((id) => id !== payload);
    },
    addSessionMeta: (state, { payload }) => {
      const { sessionID } = payload;

      state.metaCache[sessionID] = payload;
    },
    setOpeningSession: (state, { payload }) => {
      state.openingSessions[payload] = true;
    },
    removeOpeningSession: (state, { payload }) => {
      state.openingSessions[payload] = false;
    },
    setOfflineMeta: (state, { payload }) => {
      state.offlineMeta = payload;
    },
    setSessionsByPath: (state, { payload }) => {
      state[payload.path] = payload.sessions;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, purge(initialState));
  },
});

export const {
  setSyncingSession,
  setOpenSessions,
  addOpenSession,
  addSessionMeta,
  setOpeningSession,
  removeOpeningSession,
  addReconcilingSession,
  removeReconcilingSession,
  removeOpenSession,
  setOfflineMeta,
  setSessionsByPath,
} = sessionsSlice.actions;

export default sessionsSlice;
