import { useCallback, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROLE } from "@/views/UserList/constants";
import { useApolloClient, useMutation } from "@apollo/client";
import USER_UPDATE from "@/api/user/userUpdate";
import { useLazyCurrentUser } from "@/hooks/account/useCurrentUser/useCurrentUser";
import ToggleCurrentRole from "@/components/forms/SettingsForm/ToggleCurrentRole/ToggleCurrentRole";
import { RootContext } from "@/context/rootContext";

export default function CurrentRoleForm() {
  const { t } = useTranslation();
  const { clientManyQuery } = useContext(RootContext);
  const getCurrentUser = useLazyCurrentUser();
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const form = useForm({
    defaultValues: async () => {
      const currentUser = await getCurrentUser();

      return {
        currentRole: currentUser.currentRole?.name === ROLE.ADMIN
      };
    }
  });
  const navigate = useNavigate();
  const [clientMany] = clientManyQuery;
  const { register, handleSubmit, getValues } = form;
  const [updateUser] = useMutation(USER_UPDATE);
  const submit = useCallback(async () => {
    setLoading(true);

    try {
      const currentUser = await getCurrentUser();
      await updateUser({
        variables: {
          cognitoID: currentUser?.cognitoID,
          currentRole: getValues("currentRole") ? ROLE.ADMIN : ROLE.STANDARD
        }
      });
      // refresh the current user in apollo cache
      await getCurrentUser();

      await client.resetStore();

      clientMany();

      navigate("/auth/clients");
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [client, getValues, updateUser, getCurrentUser, navigate, clientMany]);

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="SelectRoleForm flex flex-col gap-5 w-full"
    >
      <h3 className="font-bold text-3xl">{t("Select your current role")}</h3>

      <ToggleCurrentRole {...register("currentRole")} />

      <div className="modal-action">
        <button
          disabled={loading}
          type="button"
          onClick={() => navigate("/auth/clients")}
          className="btn btn-sm btn-outline"
        >
          {t("Cancel")}
        </button>

        <button
          disabled={loading}
          type="submit"
          className="btn btn-sm btn-success"
        >
          {loading ? (
            <span className="loading loading-ring loading-sm"></span>
          ) : (
            t("Select")
          )}
        </button>
      </div>
    </form>
  );
}
