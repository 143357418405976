import { useMemo } from "react";

export default function useExpandSubArray(items, expansionKey) {
  const expanded = useMemo(() => {
    if (!expansionKey)
      throw new Error("expansionKey is required (useExpandSubArray)");

    return expand(items, expansionKey);
  }, [items, expansionKey]);

  return expanded;
}

// uses recursion to flatten the keyed array
function expand(items, expansionKey, isSubRow = false) {
  if (!expansionKey)
    throw new Error("expansionKey is required (useExpandSubArray)");

  return items?.reduce((acc, item) => {
    acc.push(item);

    const expansionRow = item?.[expansionKey];

    if (expansionRow) {
      const children = expand(expansionRow, expansionKey, true);
      children?.forEach((child) => acc.push({ ...child, isSubRow }));
    }

    return acc;
  }, []);
}
