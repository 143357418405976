import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function NewUserButton({ className }) {
  const { t } = useTranslation();

  return (
    <Link
      to="create_user"
      className={`btn btn-success btn-sm mr-3 ${className}`}
    >
      {t("New User")}
    </Link>
  );
}
