import { invoke } from "@tauri-apps/api/tauri";
import { useCallback } from "react";
import useOpenDesktopApp from "./useOpenDesktopApp/useOpenDesktopApp";
import { info } from "@/utilities/log";
import { addOpenSession } from "@/redux/sessions/slice";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoggedIn } from "@/redux/account/selectors";
import useDownloadDesktopSettings from "../io/useDownloadDesktopSettings/useDownloadDesktopSettings";

export default function useOpenNewSession() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const downloadDesktopSettings = useDownloadDesktopSettings();
  const openDesktopApp = useOpenDesktopApp();
  const openNewSession = useCallback(
    async (session, path, chainProcess) => {
      info("[useOpenNewSession] started ", session, path);
      const chain = await openDesktopApp(
        chainProcess,
        isLoggedIn && downloadDesktopSettings
      );

      await invoke("new_session", {
        path,
        sessionId: session.sessionID,
        clientId: session.clientID,
        clientName: session?.fullname,
      });

      dispatch(addOpenSession(session.sessionID));

      info("[useOpenNewSession] completed ", session, path);

      return chain;
    },
    [openDesktopApp, isLoggedIn, downloadDesktopSettings, dispatch]
  );

  return openNewSession;
}
