import { createSlice } from "@reduxjs/toolkit";
import purge, { PURGE } from "../purge";

const initialState = {};

export const sidecarSlice = createSlice({
  name: "SIDECAR",
  initialState,
  reducers: {
    setChildProcess: (state, { payload }) => {
      if (state.childProcess) {
        state.childProcess?.kill?.();
      }

      state.childProcess = payload;
    },

    removeChildProcess: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, purge(initialState));
  },
});

export const { setChildProcess, removeChildProcess } = sidecarSlice.actions;

export default sidecarSlice;
