import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { selectIsLoggedIn } from "@/redux/account/selectors";

export default function NavBar({ children, className }) {
  const { t } = useTranslation();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <nav
      data-theme="insoles"
      className={`NavBar relative flex justify-between px-5 py-5 ${className}`}
    >
      {!isLoggedIn ? (
        <Link to="/" className="btn btn-primary btn-sm">
          {t("Login")}
        </Link>
      ) : (
        <Breadcrumbs />
      )}

      <div className="flex flex-1 items-center justify-end gap-3">
        {children}
      </div>
    </nav>
  );
}
