import { useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { selectIsOnline } from "@/redux/account/selectors";
import ErrorBoundary from "@/components/ErrorBoundary/ErrorBoundary";
import LoginForm from "./LoginForm/LoginForm";
import logo from "@/assets/vectors/xsensor-logo.svg";
import login_logo from "@/assets/vectors/login_logo.svg";
import View from "@/components/View/View";
import MacTitleBar from "@/components/TitleBar/MacTitleBar/MacTitleBar";
import WindowsTitleBar from "@/components/TitleBar/WindowsTitleBar/WindowsTitleBar";
import { useTranslation } from "react-i18next";
import SelectLocalLanguage from "@/components/selects/SelectLanguage/SelectLocalLanguage";
import { selectEnvironment } from "@/redux/environment/selectors";
import useLogout from "@/hooks/account/useLogout";
import usePlatform from "@/hooks/tauri/usePlatform";
import { toast } from "react-hot-toast";

export default function Login() {
  const { t } = useTranslation();
  const logout = useLogout();
  const { isWeb } = usePlatform();
  const isOnline = useSelector(selectIsOnline);
  const environment = useSelector(selectEnvironment);

  return (
    <ErrorBoundary>
      <View className="bg-hero-image bg-cover bg-right bg-no-repeat">
        <MacTitleBar />

        <WindowsTitleBar />

        <Outlet />

        <div className="flex h-screen flex-col items-center w-[400px] ml-32">
          <div className="absolute left-10 top-10 flex">
            <img src={logo} className="w-36" />
          </div>

          <div className="mt-auto mb-10">
            <img src={login_logo} className="w-96" />
          </div>

          <LoginForm className="mb-auto" />

          {!isWeb && (
            <Link
              to="/offline"
              className={`self-start mb-20 ${
                !isOnline ? "btn-ghost" : "btn-outline"
              } btn-success btn-sm btn shadow-lg`}
            >
              {t("Offline Mode")}
            </Link>
          )}

          <div data-theme="insoles" className="absolute right-10 bottom-10">
            <SelectLocalLanguage
              rollover
              className="w-[200px]"
              menuPlacement="top"
            />
          </div>

          <div className="absolute bottom-0 right-0">
            <button
              className="w-6 h-6 cursor-default"
              onDoubleClick={() =>
                toast(`current environment: ${environment.STAGE}`)
              }
            ></button>
          </div>

          <div className="absolute bottom-0 left-0">
            <button
              className="w-6 h-6 cursor-default"
              onDoubleClick={() => logout()}
            ></button>
          </div>
        </div>
      </View>
    </ErrorBoundary>
  );
}
