import { useCallback } from "react";
import { DATA_PATH } from "@/utilities/paths/paths";
import { useLazyOfflineMeta } from "./useOfflineMeta";
import { info } from "@/utilities/log";
import { getMetaPath } from "@/utilities/paths/getSessionPaths";
import { BaseDirectory, exists, readDir, removeFile } from "@tauri-apps/api/fs";
import { removeFileExtension } from "@/utilities/paths/removeFileExtension";

export default function useCleanupBadMetaData() {
  const [fetchOfflineMeta] = useLazyOfflineMeta();
  const cleanupBadMetaData = useCallback(async () => {
    try {
      info("[useCleanupBadMetaData] started ");
      const offlineMeta = await fetchOfflineMeta();
      const entries = await readDir(DATA_PATH.OFFLINE, {
        dir: BaseDirectory.App,
        recursive: false,
      });
      const fileNames = entries
        .filter((entry) => entry.children !== null) // Filter out directories
        .map((entry) => removeFileExtension(entry.name));

      // look for sessions that have json meta but no matching offline file
      const badMeta =
        offlineMeta?.filter((meta) => {
          return !fileNames.includes(meta?.sessionID);
        }) || [];

      info("[useCleanupBadMetaData] badMeta ", badMeta);

      // // delete bad meta data
      for await (const meta of badMeta) {
        const metaPath = await getMetaPath(meta?.sessionID);

        info("[useCleanupBadMetaData] deleting bad meta data file", metaPath);

        const metaExists = await exists(metaPath, { dir: BaseDirectory.App });
        metaExists && (await removeFile(metaPath, { dir: BaseDirectory.App }));
      }

      info("[useCleanupBadMetaData] completed ");
    } catch (e) {
      info("[useCleanupBadMetaData] error: failed to auto reconcile", e);
    }
  }, [fetchOfflineMeta]);

  return cleanupBadMetaData;
}
