import { gql } from "@apollo/client";
import PAGE_INFO from "../pageInfo/pageInfoFragment";
import USER_FRAGMENT from "./userFragment";

const USER_MANY = gql`
  query USER_MANY(
    $sort: JSONObject
    $filter: UserFilterInput
    $limit: Int
    $offset: Int
  ) {
    userMany(sort: $sort, filter: $filter, limit: $limit, offset: $offset) {
      payload {
        ...UserFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }

  ${USER_FRAGMENT}
  ${PAGE_INFO}
`;

export default USER_MANY;
