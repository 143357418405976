import { DATA_PATH } from "@/utilities/paths/paths";
import { BaseDirectory, readDir } from "@tauri-apps/api/fs";
import { useCallback } from "react";
import useMoveFile from "./useMoveFile/useMoveFile";
import { basename, join } from "@tauri-apps/api/path";
import { info } from "@/utilities/log";

export default function useCleanupActiveSessions() {
  const moveFile = useMoveFile();
  const cleanupActiveSessions = useCallback(async () => {
    try {
      info("[useCleanupActiveSessions] started ");
      const path = DATA_PATH.ACTIVE;
      const entries = await readDir(path, {
        dir: BaseDirectory.App,
        recursive: false,
      });

      info("[useCleanupActiveSessions] cleaning up ", entries);

      entries &&
        (await Promise.all(
          entries.map(async (entry) => {
            const filePath = entry.path;
            if (!filePath?.includes(".xsn")) return;

            const fileName = await basename(filePath);
            const from = await join(path, fileName);
            const to = await join(DATA_PATH.OFFLINE, fileName);

            await moveFile(from, to);
          })
        ));

      info("[useCleanupActiveSessions] completed ");
    } catch (e) {
      info(
        "[useCleanupActiveSessions] error: failed to cleanup active sessions",
        e
      );
    }
  }, [moveFile]);

  return cleanupActiveSessions;
}
