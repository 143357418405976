import { useCallback } from "react";
import { getAmplifyConfig } from "@/configurations/amplify";
import { Amplify } from "aws-amplify";
import { PURGE } from "@/redux/purge";
import { store } from "@/configurations/store";

export default function useInitializeAmplify() {
  const initalizeAmplify = useCallback(async (environment) => {
    store.dispatch(PURGE());
    const amplifyConfig = getAmplifyConfig(environment);

    Amplify.configure(amplifyConfig);
  }, []);

  return initalizeAmplify;
}
