import GITHUB_LATEST_RELEASE from "@/api/github/latestRelease";
import { selectEnvironment } from "@/redux/environment/selectors";
import { emptyObj } from "@/utilities/empties";
import { error, info } from "@/utilities/log";
import { useLazyQuery } from "@apollo/client";
import { useCallback, useState } from "react";
import { toaster } from "@/utilities/toaster";
import { useSelector } from "react-redux";

export default function useGitRelease() {
  const [loading, setLoading] = useState(false);
  const [releaseInfo, setReleaseInfo] = useState(emptyObj);
  const [latestRelease] = useLazyQuery(GITHUB_LATEST_RELEASE);
  const environment = useSelector(selectEnvironment);
  const getRelease = useCallback(async () => {
    setLoading(true);

    info("[useGitRelease] started ");
    try {
      const response = await latestRelease({
        variables: { repo: environment.DOWNLOAD_REPO },
      });

      setReleaseInfo(response?.data?.githubLatestRelease);
      setLoading(false);

      info("[useGitRelease] completed ");
    } catch (e) {
      error("[useGitRelease] error ", e);

      toaster.error("Problem fetching latest release.");

      setLoading(false);
    }
  }, [environment, latestRelease]);

  return [getRelease, { data: releaseInfo, loading }];
}
