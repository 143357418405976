import { error } from "@/utilities/log";
import { Auth } from "aws-amplify";
import { useCallback, useEffect } from "react";

// refresh token every 30 minutes
const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 30;

export default function useRefreshToken() {
  const refreshToken = useCallback(async () => {
    try {
      await Auth.currentAuthenticatedUser({ bypassCache: true })

    } catch (e) {
      error(e);
    }
  }, []);

  useEffect(() => {
    const timeout = setInterval(refreshToken, REFRESH_TOKEN_INTERVAL);

    return () => clearInterval(timeout);
  }, [refreshToken]);
}
