import { gql } from "@apollo/client";

const SUBSCRIPTION_FRAGMENT = gql`
  fragment SubscriptionFragment on Subscription {
    date
    isActive
    expiredByHours
    remainingHours
    remainingDays
    andRemainingHours
    expiredByDays
    andExpiredHours
  }
`;

export default SUBSCRIPTION_FRAGMENT;
