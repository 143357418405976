import { error } from "@/utilities/log";
import React from "react";
import { Trans } from "react-i18next";
import { Link, Outlet } from "react-router-dom";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(err, errorInfo) {
    // You can also log the error to an error reporting service
    error(err);
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="h-screen w-screen flex flex-col justify-center items-center bg-neutral">
          <h1 className="text-center mb-5">
            <Trans>Something went wrong.</Trans>
          </h1>

          <Link
            to={-1}
            onClick={() => setTimeout(() => window.location.reload(), 1000)}
            className="btn btn-primary"
          >
            <Trans>Go Back</Trans>
          </Link>
        </div>
      );
    }

    return (
      <>
        {this.props.children}
        <Outlet />
      </>
    );
  }
}
