import { gql } from "@apollo/client";
import CLIENT from "./clientFragment";

const CLIENT_UPDATE = gql`
  mutation CLIENT_UPDATE(
    $clientID: ID!
    $privateID: ID
    $fullname: String
    $groups: [String]
    $users: [ID]
    $disabled: Boolean
  ) {
    clientUpdate(
      clientID: $clientID
      privateID: $privateID
      fullname: $fullname
      groups: $groups
      users: $users
      disabled: $disabled
    ) {
      ...ClientFragment
    }
  }

  ${CLIENT}
`;

export default CLIENT_UPDATE;
