import { DATA_PATH } from "@/utilities/paths/paths"
import { Link, useParams } from "react-router-dom"
import DownloadSessionButton from "../buttons/DownloadSessionButton/DownloadSessionButton"
import useExpandSubArray from "@/hooks/searchAndSort/useExpandSubArray"
import { useTranslation } from "react-i18next"
import { RootContext } from "@/context/rootContext"
import { useContext } from "react"

export default function ForceOpenForm() {
  const { t } = useTranslation()
  const { sessionID } = useParams()
  const { sessionManyQuery } = useContext(RootContext)
  const [_, { data }] = sessionManyQuery
  const sessions = data?.sessionMany?.payload
  const expandedSessions = useExpandSubArray(sessions, "children")

  return (
    <div className="flex flex-col">
      <span className="mb-10 flex justify-center !text-lg font-bold">
        {t(`Be careful, you could overwrite someone else's work or your's could be
        overwritten. Are you sure you want to force open?`)}
      </span>

      <div className="flex justify-end">
        <Link to="../" className="btn-ghost btn-sm btn mr-5 ml-auto">
          {t("Cancel")}
        </Link>

        <div className="flex items-center">
          <DownloadSessionButton
            forceOpen
            path={DATA_PATH.ACTIVE}
            session={expandedSessions?.find(
              (session) => session.sessionID === sessionID
            )}
            buttonClassName="btn-warning !btn-sm"
          >
            {t("Force Open")}
          </DownloadSessionButton>
        </div>
      </div>
    </div>
  )
}
