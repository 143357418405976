import { info } from "@/utilities/log";
import { createAction } from "@reduxjs/toolkit";

const purge = (initialState) => () => {
  info("PURGING...");

  return initialState;
};

export const PURGE = createAction("PURGE");
export default purge;
