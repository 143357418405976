export const DATA_PATH = {
  OFFLINE: "offline",
  META: "meta",
  ACTIVE: "active",
  CSVS: "csvs",
  CSV_DOWNLOAD: "csvs/downloads",
  PDFS: "pdfs",
  PDF_DOWNLOAD: "pdfs/downloads",
  EXPORTS: "exports",
  SETTINGS_DEFAULT: "settings/default",
  SETTINGS_OFFLINE: "settings/offline",
  SETTINGS_ACTIVE: "settings/active",
};
