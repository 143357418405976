import FormInput from "../formComponents/FormInput"
import { useLazyHasPermission } from "@/hooks/account/useHasPermission/useHasPermission"
import checkRequired from "@/utilities/validators/checkRequired"
import { useTranslation } from "react-i18next"
import { emptyArr } from "@/utilities/empties"
import DateInput from "../formComponents/DateInput/DateInput"
import { Link } from "react-router-dom"

export default function FacilityForm({
  form,
  submit,
  loading,
  hiddenFields = emptyArr,
  title,
  submitLabel,
  className,
  ...props
}) {
  const hasPermission = useLazyHasPermission()
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = form

  return (
    <form
      {...props}
      onSubmit={handleSubmit(submit)}
      className={`flex min-w-[18rem] flex-col gap-5 ${className}`}
    >
      <h1 data-testid="formTitle">{title}</h1>

      {!hiddenFields.includes("share") && (
        <div className="form-control">
          <label className="flex cursor-pointer">
            <span className="mr-auto font-medium">{t("Share Clients")}</span>

            <input
              {...register("share")}
              data-testid="inputShare"
              type="checkbox"
              className="toggle-success toggle"
            />
          </label>
        </div>
      )}

      {hasPermission("updateSubscription") && (
        <DateInput
          data-testid="inputDate"
          control={control}
          name={"date"}
          label={t("Facility Subscription Expiration")}
        />
      )}

      {!hiddenFields.includes("name") && hasPermission("updateFacility") && (
        <FormInput
          data-testid="inputName"
          label={t("Facility Name")}
          errors={errors}
          name="name"
          placeholder={t("Facility Name")}
          {...register("name", { required: true })}
        />
      )}

      {hasPermission("updateSubscription") && (
        <FormInput
          data-testid="inputRMA"
          label={t("RMA #")}
          errors={errors}
          name="RMA"
          placeholder={t("RMA #")}
          {...register("RMA", { validate: checkRequired })}
        />
      )}

      <div className="flex justify-end mt-auto gap-5">
        <Link data-testid="buttonCancel" to="../" className="btn btn-sm">
          {t("Cancel")}
        </Link>

        <button
          data-testid="buttonSubmit"
          type="submit"
          disabled={loading}
          className={`btn-success btn-sm btn px-8 `}
        >
          {loading ? (
            <span className="loading loading-ring loading-md text-neutral"></span>
          ) : (
            submitLabel
          )}
        </button>
      </div>
    </form>
  )
}
