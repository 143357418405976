import MY_USER from "@/api/user/myUser";
import USER from "@/api/user/userFragment";
import { selectCurrentUserId } from "@/redux/account/selectors";
import { error } from "@/utilities/log";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { useCallback } from "react";
import { useSelector } from "react-redux";

export default function useCurrentUser() {
  const client = useApolloClient();
  const currentUserId = useSelector(selectCurrentUserId);
  const currentUser = client.readFragment({
    id: `User:{"cognitoID":"${currentUserId}"}`,
    fragmentName: "UserFragment",
    fragment: USER,
  });

  return currentUser;
}

export function useLazyCurrentUser() {
  const [getMyUser] = useLazyQuery(MY_USER);
  const getCurrentUser = useCallback(async () => {
    try {
      const response = await getMyUser();

      return response?.data?.myUser;
    } catch (e) {
      error(e);
    }
  }, [getMyUser]);

  return getCurrentUser;
}
