import useWindowFocus from "@/hooks/tauri/useWindowFocus";
import { appWindow } from "@tauri-apps/api/window";

import {
  BsArrowUpRightCircleFill,
  BsDashCircleFill,
  BsXCircleFill,
} from "react-icons/bs";
import useTryCloseWindow from "../hooks/useTryCloseWindow";
import { useSelector } from "react-redux";
import { selectPlatform } from "@/redux/environment/selectors";

export default function MacTitleBar() {
  const currentPlatform = useSelector(selectPlatform);
  const isFocused = useWindowFocus();
  const tryCloseWindow = useTryCloseWindow();

  if (currentPlatform !== "macos") return null;

  return (
    <div
      data-tauri-drag-region
      className="bg-base-100 z-50 flex select-none justify-start pl-2"
    >
      <button
        onClick={tryCloseWindow}
        className={`${
          isFocused ? "text-red-400" : "text-gray-500"
        } btn-link btn-sm flex items-center justify-center rounded-none p-0`}
      >
        <BsXCircleFill size={14} />
      </button>

      <button
        onClick={() => appWindow.minimize()}
        className={`${
          isFocused ? "text-yellow-500" : "text-gray-500"
        } btn-link btn-sm flex items-center justify-center rounded-none p-0 pl-2`}
      >
        <BsDashCircleFill size={14} />
      </button>

      <button
        onClick={() => appWindow.toggleMaximize()}
        className={`${
          isFocused ? "text-green-500" : "text-gray-500"
        } btn-link btn-sm flex items-center justify-center rounded-none p-0 pl-2 `}
      >
        <BsArrowUpRightCircleFill size={14} />
      </button>
    </div>
  );
}
