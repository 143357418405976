export default function getSessionIDSegments(sessionID) {
  const regex = new RegExp(/(-?)(trim_stance|average_stance).{9}(-?)/g);
  const segments = sessionID.match(regex) || [];
  const rootID = segments
    ? segments.reduce((acc, cur) => {
        return acc.replace(cur, "");
      }, sessionID)
    : sessionID;

  const trimmed = segments.map((segment) => {
    const trimFirst = trimStartChar(segment, "-");
    const trimLast = trimEndChar(trimFirst, "-");

    return trimLast;
  });

  return [rootID, ...trimmed];
}

function trimStartChar(str, char) {
  const first = str?.[0];

  if (first === char) {
    return str.slice(1);
  }

  return str;
}

function trimEndChar(str, char) {
  const last = str?.[str.length - 1];

  if (last === char) {
    return str.slice(0, -1);
  }

  return str;
}
