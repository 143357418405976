import USER_ENABLE from "@/api/user/enableUser";
import { RootContext } from "@/context/rootContext";
import useSelectUser from "@/hooks/apolloState/useSelectUser";
import { useMutation } from "@apollo/client";
import { useCallback, useContext } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export default function EnableUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userID } = useParams();
  const user = useSelectUser(userID);
  const { userManyQuery } = useContext(RootContext);
  const [_, { refetch }] = userManyQuery;
  const [enableUser, { loading }] = useMutation(USER_ENABLE, {
    onCompleted: () => {
      refetch();

      toaster.success(t("User re-enabled!"));

      navigate("../");
    },
  });
  const submit = useCallback(
    (e) => {
      e.preventDefault();
      enableUser({ variables: { email: user?.email } });
    },
    [user, enableUser]
  );

  return (
    <form onSubmit={submit}>
      <h1 className="flex justify-center">{t("Re-enable User")}</h1>

      <div className="my-5">
        <span>
          {t("Are you sure you want to re-enable")}
          {` `}
        </span>
        <span className="text-success">{user?.name}</span>?
      </div>

      <div className="flex w-full justify-end">
        <button
          disabled={loading}
          className={`btn-success btn-sm btn`}
          type="submit"
        >
          {loading ? (
            <span className="loading loading-ring loading-md text-neutral"></span>
          ) : (
            t("Re-enable")
          )}
        </button>
      </div>
    </form>
  );
}
