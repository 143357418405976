import useOpenNewSession from "@/hooks/integration/useOpenNewSession";
import { addSessionMeta } from "@/redux/sessions/slice";
import { getMetaPath } from "@/utilities/paths/getSessionPaths";
import { DATA_PATH } from "@/utilities/paths/paths";
import { BaseDirectory, writeTextFile } from "@tauri-apps/api/fs";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import generateId from "@/utilities/generate/generateId";
import { FILE_EXTENSION } from "@/constants";
import useOfflineMeta from "@/hooks/io/useOfflineMeta";
import { toaster } from "@/utilities/toaster";

export default function useSubmit(getValues, offline, refetch) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const openNewSession = useOpenNewSession();
  const { t } = useTranslation();
  const { refetch: refetchOffline } = useOfflineMeta();
  const submit = useCallback(async () => {
    setLoading(true);
    const _sessionID = generateId();
    const isTestSession = getValues("isTestSession");
    const session = {
      sessionID: _sessionID,
      clientID: getValues("client")?.clientID,
      sessionType: getValues("sessionType")?.value,
      notes: getValues("notes"),
      createdAt: new Date().toISOString(),
      // meta.offline is a flag on meta to indicate this session should be
      // handled as though the user were offline
      extension: FILE_EXTENSION.XSN,
      offline,
      new: true,
      isTestSession,
    };

    dispatch(addSessionMeta(session));

    const metaPath = await getMetaPath(_sessionID);

    await openNewSession(session, DATA_PATH.ACTIVE);

    await writeTextFile(metaPath, JSON.stringify(session), {
      dir: BaseDirectory.App,
    });

    setLoading(false);

    refetch?.();
    refetchOffline?.();

    isTestSession
      ? toaster.success(t("Test session is starting"))
      : toaster.success(t("Session is starting"));

    navigate("../");
  }, [
    getValues,
    navigate,
    openNewSession,
    offline,
    refetch,
    dispatch,
    refetchOffline,
    t,
  ]);

  return { submit, loading };
}
