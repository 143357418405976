import { useCallback } from "react";
import { useForm } from "react-hook-form";
import FormInput from "@/components/forms/formComponents/FormInput";
import { Link, useNavigate } from "react-router-dom";
import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser";
import { useMutation } from "@apollo/client";
import USER_UPDATE from "@/api/user/userUpdate";
import { useTranslation } from "react-i18next";
import { toaster } from "@/utilities/toaster";

export default function PersonalInfoForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const form = useForm({
    defaultValues: {
      name: currentUser?.name,
      email: currentUser?.email,
    },
  });
  const { register, handleSubmit, getValues, formState, reset } = form;
  const [updateUser, { loading }] = useMutation(USER_UPDATE);
  const { errors } = formState;
  const submit = useCallback(async () => {
    const { data } = await updateUser({
      variables: {
        cognitoID: currentUser?.cognitoID,
        name: getValues("name"),
      },
    });

    reset({
      name: data?.userUpdate?.name,
      email: data?.userUpdate?.email,
    });
    toaster.success(t("Updated Account Info"));
    navigate("../");
  }, [getValues, updateUser, currentUser, reset, navigate, t]);

  return (
    <form
      className="flex min-w-[200px] flex-col w-full gap-5"
      onSubmit={handleSubmit(submit)}
    >
      <h4 className="text-center text-3xl font-bold">{t("Personal Info")}</h4>

      <FormInput
        name="name"
        label={t("Name")}
        errors={errors}
        placeholder={t("Name")}
        {...register("name", {
          required: t("Name is required"),
        })}
      />

      <FormInput
        name="email"
        label={t("Email")}
        errors={errors}
        disabled
        placeholder={t("Email")}
        {...register("email", {
          required: t("Email is required"),
          pattern: {
            value:
              /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
            message: t("Email is invalid"),
          },
        })}
      />

      <div className="flex-grow"></div>

      <div className="flex justify-end">
        <Link
          to="../"
          className="btn btn-neutral btn-sm btn-outline px-6"
          disabled={loading}
        >
          {t("Cancel")}
        </Link>

        <button
          className="btn btn-success btn-sm px-8 ml-5"
          disabled={loading}
          type="submit"
        >
          {loading ? (
            <span className="loading loading-ring loading-md text-neutral"></span>
          ) : (
            t("Save")
          )}
        </button>
      </div>
    </form>
  );
}
