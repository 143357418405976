import { info } from "@/utilities/log";
import { useCallback } from "react";
import { invoke } from "@tauri-apps/api";

export default function useZip() {
  const zip = useCallback(async (fromPaths, toPath) => {
    info("[useZip] started ");

    await invoke("zip_files", {
      fromPaths,
      toPath,
    });

    info("[useZip] completed ");
  }, []);

  return zip;
}
