import { RootContext } from "@/context/rootContext";
import { toaster } from "@/utilities/toaster";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function useSubmit(form, clientUpdate, clientID) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { clientManyQuery } = useContext(RootContext);
  const [_, { refetch }] = clientManyQuery;
  const { reset, getValues } = form;
  const submit = useCallback(async () => {
    await clientUpdate({
      variables: {
        clientID,
        fullname: getValues("fullname"),
        groups: getValues("groups")?.map((option) => option.value),
        privateID: getValues("privateID"),
        users: getValues("users").map((user) => {
          const isObject = typeof user === "object";
          return isObject ? user.cognitoID : user;
        }),
      },
    });

    // reset form
    reset();
    // toast success
    toaster.success(t("Client Updated!"));

    refetch();

    // navigate from modal
    navigate(-1, { replace: true });
  }, [getValues, clientID, clientUpdate, reset, navigate, refetch, t]);

  return submit;
}
