import Row from "../Row/Row"
import LoadingRow from "../LoadingRow/LoadingRow"

export default function Body({
  rows,
  rowOnClick,
  rowOnDoubleClick,
  rowClassName,
  virtualizer
}) {
  const virtualItems = virtualizer.getVirtualItems()

  return (
    <div className="Body border-none text-base-100 relative">
      {virtualItems.map((virtualRow, index) => {
        const row = rows[virtualRow.index]

        if (!row)
          return (
            <LoadingRow
              key={virtualRow.index}
              virtualRow={virtualRow}
              index={index}
            />
          )

        return (
          <Row
            key={row.id}
            style={{
              height: `${virtualRow.size}px`,
              transform: `translateY(${
                virtualRow.start - index * virtualRow.size
              }px)`
            }}
            row={row}
            rowOnClick={rowOnClick}
            rowOnDoubleClick={rowOnDoubleClick}
            className={rowClassName}
            virtualizer={virtualizer}
          />
        )
      })}
    </div>
  )
}
