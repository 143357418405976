import useLogout from "@/hooks/account/useLogout";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useClickOutside from "@/hooks/ui/useClickOutside";

export default function Dropdown({ className, setOpen, open }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const logout = useLogout();
  const ref = useRef(null);
  useClickOutside(ref, () => setOpen(false), "click");

  return (
    <div
      data-theme="insoles-light"
      ref={ref}
      className={`absolute z-50 flex flex-col bg-base-100 text-neutral min-w-52 p-2 rounded-xl top-full right-0 shadow-lg ${className} ${
        open ? "visible" : "hidden"
      }`}
    >
      <button
        className="btn btn-ghost font-normal normal-case"
        onClick={() => {
          navigate("/auth/account");
          setOpen(false);
        }}
      >
        {t("My Account")}
      </button>

      <button
        className="btn btn-ghost font-normal normal-case"
        onClick={() => {
          logout();
          setOpen(false);
        }}
      >
        {t("Logout")}
      </button>
    </div>
  );
}
