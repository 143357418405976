import { selectChildProcess } from "@/redux/sidecar/selectors";
import { useMutation } from "@apollo/client";
import { Child } from "@tauri-apps/api/shell";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import SESSION_RELEASE_ALL_LOCKS from "@/api/session/sessionReleaseAllLocks";
import { error } from "@/utilities/log";

export default function useReleaseSessionLocks() {
  const [sessionReleaseAllLocks] = useMutation(SESSION_RELEASE_ALL_LOCKS, {});
  const childProcess = useSelector(selectChildProcess);
  const releaseSessionLocks = useCallback(async () => {
    try {
      if (childProcess) {
        const child = new Child(childProcess.pid);

        await child.kill();
      }

      await sessionReleaseAllLocks();
    } catch (e) {
      error(e);
    }
  }, [sessionReleaseAllLocks, childProcess]);

  return releaseSessionLocks;
}
