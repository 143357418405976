import i18n from "@/configurations/i18n";
import { toaster } from "@/utilities/toaster";
import { error } from "../log";

export default function handleNetworkErrors(networkError) {
  const { message } = networkError;

  if (message?.includes("Failed to fetch")) {
    toaster.error(i18n.t("Check your internet connection..."), {
      id: "checkConnection",
    });
  }

  error("Error link (network): ", networkError);
}
