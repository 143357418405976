import { Outlet, useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import SESSION_MANY from "@/api/session/sessionMany";
import useMonitorIsOnline from "@/hooks/account/useMonitorIsOnline";
import CLIENT_MANY from "@/api/client/clientMany";
import useSetForegroundWindowListener from "@/hooks/events/useSetForegroundWindowListener";
import useOnFocusChangedListener from "@/hooks/events/useOnFocusChangedListener";
import useShutdownChildListener from "@/hooks/events/useShutdownChildListener";
import useListenToUpdateStatus from "@/hooks/events/useListenToUpdateStatus";
import useListenForErrorEvent from "@/hooks/events/useListenForErrorEvent";
import { RootContext } from "@/context/rootContext";
import { useMemo } from "react";
import { info } from "@/utilities/log";
import USER_MANY from "@/api/user/userMany";

export default function Root({ children }) {
  const location = useLocation();
  const sessionManyQuery = useLazyQuery(SESSION_MANY, {
    fetchPolicy: "cache-and-network",
  });
  const clientManyQuery = useLazyQuery(CLIENT_MANY, {
    fetchPolicy: "cache-and-network",
  });
  const userManyQuery = useLazyQuery(USER_MANY, {
    fetchPolicy: "cache-and-network",
  });
  const rootContextValues = useMemo(() => {
    return {
      sessionManyQuery,
      clientManyQuery,
      userManyQuery,
    };
  }, [sessionManyQuery, clientManyQuery, userManyQuery]);
  info("[location]", location.pathname);

  useSetForegroundWindowListener();
  useOnFocusChangedListener();
  useShutdownChildListener();

  useListenToUpdateStatus();
  useListenForErrorEvent();

  // this does not break the rules of hooks because these conditions are not evaluated at runtime
  // this hook can be annoying during development as it spams the dev server with 'isOnline' requests
  // eslint-disable-next-line react-hooks/rules-of-hooks
  import.meta.env.PROD && useMonitorIsOnline();

  // if (!isLoggedIn) {
  //   // TODO: use state from to redirect back to the page the user was trying to access
  //   return <Navigate to="/login" state={{ from: location }} replace />;
  // }

  return (
    <RootContext.Provider value={rootContextValues}>
      {children}

      <Outlet />
    </RootContext.Provider>
  );
}
