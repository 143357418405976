import Table from "@/components/Table/Table"
import { useSelector } from "react-redux"
import useColumnScaffold from "./hooks/useColumnScaffold"
import ToggleShareAllClients from "@/components/buttons/ToggleShareAllClients/ToggleShareAllClients"
import NavBar from "@/components/NavBar/NavBar"
import NewUserButton from "@/components/buttons/NewUserButton/NewUserButton"
import View from "@/components/View/View"
import Search from "@/components/Search/Search"
import Header from "@/components/Header/Header"
import MacTitleBar from "@/components/TitleBar/MacTitleBar/MacTitleBar"
import { useTranslation } from "react-i18next"
import { selectSortUsers } from "@/redux/ui/selectors"
import { setSortUsers } from "@/redux/ui/slice"
import UserFilters from "./UserFilters/UserFilters"
import { Outlet } from "react-router-dom"
import useUserManyQuery from "./hooks/useUserManyQuery"

export default function UserList() {
  const { t } = useTranslation()
  const sort = useSelector(selectSortUsers)
  const { loading, data, previousData, refetch, fetchMore } = useUserManyQuery()
  const columns = useColumnScaffold(refetch)
  const users = (data ?? previousData)?.userMany?.payload
  const pageInfo = (data ?? previousData)?.userMany?.pageInfo

  return (
    <View>
      <MacTitleBar />

      <Header />

      <Outlet />

      <NavBar title={t("Users")}>
        <ToggleShareAllClients />

        <Search placeholder={t("Search")} />

        <UserFilters />

        <NewUserButton />
      </NavBar>

      <Table
        data={users}
        columns={columns}
        loading={loading}
        pageInfo={pageInfo}
        fetchMore={fetchMore}
        sort={sort}
        setSort={setSortUsers}
        emptyDescription={t("There are no users")}
      />
    </View>
  )
}
