import EditSessionButton from "./EditSessionButton/EditSessionButton";
import OpenOfflineSessionButton from "./OpenOfflineSessionButton/OpenOfflineSessionButton";
import ReconcileSessionButton from "./ReconcileSessionButton/ReconcileSessionButton";
import DeleteOfflineSessionButton from "./DeleteOfflineSessionButton/DeleteOfflineSessionButton";
import { useHasPermission } from "@/hooks/account/useHasPermission/useHasPermission";

export default function Actions({ session, sessions }) {
  const hasPermission = useHasPermission("spoofFacility");

  return (
    <div className="flex items-center">
      <OpenOfflineSessionButton session={session} />

      <EditSessionButton session={session} />

      {!hasPermission && (
        <ReconcileSessionButton session={session} sessions={sessions} />
      )}

      <DeleteOfflineSessionButton session={session} />
    </div>
  );
}
