import React, { forwardRef } from "react";
import HeaderCell from "../HeaderCell/HeaderCell";

const Header = forwardRef(({ table }, ref) => {
  return (
    <div
      ref={ref}
      className="Header sticky z-10 bg-base-100 top-0"
      data-theme="insoles"
    >
      {table.getHeaderGroups().map((headerGroup) => {
        return (
          <div className="flex" key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return <HeaderCell key={header.id} header={header} />;
            })}
          </div>
        );
      })}
    </div>
  );
});

export default Header;
