import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  environment: import.meta.env.PROD ? "production" : "local",
  platform: import.meta.env.TAURI_PLATFORM,
  // platform: "windows",
};

export const environmentSlice = createSlice({
  name: "ENVIRONMENT",
  initialState,
  reducers: {
    setEnvironment: (state, { payload }) => {
      state.environment = payload;
    },
    setPlatform: (state, { payload }) => {
      state.platform = payload;
    },
  },
  // do not purge environment
  // extraReducers: () => ({
  //   ...purge(initialState),
  // }),
});

export const { setEnvironment, setPlatform } = environmentSlice.actions;

export default environmentSlice;
