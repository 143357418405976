import SelectLanguage from "@/components/selects/SelectLanguage/SelectLanguage";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import InputField from "../formComponents/InputField/InputField";
import { useForm } from "react-hook-form";
import { LANGUAGE } from "@/components/selects/SelectLanguage/constants";
import ToggleCurrentRole from "@/components/forms/SettingsForm/ToggleCurrentRole/ToggleCurrentRole";
import { ROLE } from "@/views/UserList/constants";
import useCurrentUser, {
  useLazyCurrentUser,
} from "@/hooks/account/useCurrentUser/useCurrentUser";
import useUpdateSettings from "./useUpdateSettings";

export default function SettingsForm() {
  const { t } = useTranslation();
  const getCurrentUser = useLazyCurrentUser();
  const currentUser = useCurrentUser();
  const { control, handleSubmit, getValues, register } = useForm({
    defaultValues: async () => {
      const currentUser = await getCurrentUser();

      return (
        currentUser?.language && {
          language: {
            value: currentUser.language,
            label: LANGUAGE[currentUser.language],
          },
          currentRole: currentUser.currentRole?.name === ROLE.ADMIN,
        }
      );
    },
  });
  const [updateSettings, { loading }] = useUpdateSettings(getValues);

  return (
    <form
      className="flex flex-col h-full gap-5"
      onSubmit={handleSubmit(updateSettings)}
    >
      <h4 className="text-center text-3xl font-bold">{t("Settings")}</h4>

      <InputField label={t("Language")}>
        <SelectLanguage control={control} rules={{ required: true }} />
      </InputField>

      {currentUser?.role?.name === ROLE.ADMIN ? (
        <InputField className="mb-auto" label={t("Current Role")}>
          <ToggleCurrentRole {...register("currentRole")} />
        </InputField>
      ) : (
        <div className="mb-auto"></div>
      )}

      <div className="flex justify-end">
        <Link
          to="../"
          disabled={loading}
          className="btn btn-neutral btn-sm btn-outline px-6"
        >
          {t("Cancel")}
        </Link>

        <button
          className="btn-success btn-sm btn px-8 ml-5"
          disabled={loading}
          type="submit"
        >
          {loading ? (
            <span className="loading loading-ring loading-md text-neutral"></span>
          ) : (
            t("Save")
          )}
        </button>
      </div>
    </form>
  );
}
