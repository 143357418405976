import { forwardRef } from "react";

const View = forwardRef(({ children, className }, ref) => {
  return (
    <div
      ref={ref}
      className={`View relative flex flex-col h-screen ${className}`}
    >
      {children}
    </div>
  );
});

export default View;
