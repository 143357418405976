import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useRemoveSession from "@/hooks/io/useRemoveSession";
import getSessionPaths from "@/utilities/paths/getSessionPaths";
import { toaster } from "@/utilities/toaster";
import { error } from "@/utilities/log";
import { useLazyRefreshOpenSessionsList } from "@/hooks/integration/useRefreshOpenSessionsList";
import { useLazyOfflineMeta } from "@/hooks/io/useOfflineMeta";
import useReadMetaFile from "../useReadMetaFile";
import isSessionReport from "@/utilities/isSessionReport";

export default function useDeleteOfflineSessions(
  sessionID,
  extension,
  childSessions
) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const getMeta = useReadMetaFile();
  const removeSession = useRemoveSession();
  const [refreshOpenSessions] = useLazyRefreshOpenSessionsList();
  const [refetch] = useLazyOfflineMeta();
  const deleteOfflineSession = useCallback(
    async (e) => {
      setLoading(true);
      e.stopPropagation();
      const toDelete = [
        { sessionID, extension },
        ...childSessions.map((s) => ({
          sessionID: s.sessionID,
          extension: s?.extension ?? extension,
        })),
      ];
      const meta = await getMeta(sessionID);
      const deleteSubject = isSessionReport(meta?.exportType) ? "Report" : "Offline session(s)";

      for (const { sessionID, extension } of toDelete) {
        try {
          const { offlinePath } = await getSessionPaths({
            sessionID,
            extension,
          });

          await removeSession(sessionID, offlinePath);
        } catch (e) {
          toaster.error(t("Failed to delete offline session: ", sessionID));
          error(e);
        }
      }

      await refetch();
      await refreshOpenSessions();

      navigate("../");
      toaster.success(t(`${deleteSubject} deleted`));
      setLoading(false);
    },

    [
      childSessions,
      removeSession,
      navigate,
      t,
      getMeta,
      refetch,
      refreshOpenSessions,
      sessionID,
      extension,
    ]
  );

  return [deleteOfflineSession, { loading }];
}
