import useSelectClient from "@/hooks/apolloState/useSelectClient";
import { Link, useParams } from "react-router-dom";

export default function ClientListCrumb() {
  const { clientID } = useParams();
  const client = useSelectClient(clientID);

  return (
    <Link to={`/auth/clients/${clientID}/sessions`}>{client?.fullname}</Link>
  );
}
