import { RootContext } from "@/context/rootContext"
import {
  selectLimit,
  selectOffset,
  selectSearch,
  selectUserEnabled
} from "@/redux/listFilter/selectors"
import { selectSortUsersAsObject } from "@/redux/ui/selectors"
import { useContext, useEffect } from "react"
import { useSelector } from "react-redux"

export default function useUserManyQuery() {
  const { userManyQuery } = useContext(RootContext)
  const search = useSelector(selectSearch)
  const enabled = useSelector(selectUserEnabled)
  const offset = useSelector(selectOffset)
  const limit = useSelector(selectLimit)
  const _sortAsObject = useSelector(selectSortUsersAsObject)
  const [userMany, { data, loading, refetch, previousData, fetchMore }] =
    userManyQuery

  useEffect(() => {
    userMany({
      variables: {
        filter: { enabled, search },
        sort: _sortAsObject,
        offset,
        limit
      }
    })
  }, [userMany, search, enabled, offset, limit, _sortAsObject])

  return { loading, data, previousData, refetch, fetchMore }
}
