import FACILITY_UPDATE from "@/api/facility/facilityUpdate";
import { toaster } from "@/utilities/toaster";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function useSubmit(form, refetch, goBack) {
  const { getValues, setValue } = form;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [updateSettings, { loading }] = useMutation(FACILITY_UPDATE);
  const submit = useCallback(async () => {
    try {
      await updateSettings({
        variables: {
          name: getValues("name"),
          shareAllClients: getValues("share"),
          subscriptionExpirationDate: getValues("date")?.toISOString(),
          RMA: getValues("RMA"),
        },
      });

      // await client.resetStore();
      toaster.success(t("Settings updated"));

      goBack && navigate("../");

      refetch();
    } catch (e) {
      toaster.error(t("There was a a problem updating settings"));

      setValue("share", !getValues("share"));
    }
  }, [getValues, updateSettings, setValue, refetch, t, goBack, navigate]);

  return { submit, loading };
}
