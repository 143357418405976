import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { checkMatchingFields } from "@/utilities/validators";
import useResetPassword from "./hooks/useResetPassword";
import FormInput from "@/components/forms/formComponents/FormInput";
import Spinner from "@/components/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import PasswordRequirements from "../../PasswordRequirements/PasswordRequirements";
import checkCognitoPasswordRequirements from "@/utilities/validators/checkCognitoPasswordRequirements";

export default function ResetPasswordCode() {
  const { t } = useTranslation();
  const form = useForm();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = form;
  const formValues = watch();
  const { resetPassword, loading } = useResetPassword();
  const submit = useCallback(async () => {
    await resetPassword(getValues());
  }, [resetPassword, getValues]);

  return (
    <div>
      <h3 className="mb-8 text-3xl">{t("Reset Password")}</h3>

      <form className="flex flex-col gap-4" onSubmit={handleSubmit(submit)}>
        <FormInput
          name="email"
          label={t("Email")}
          errors={errors}
          placeholder={t("Email")}
          {...register("email", {
            required: t("Email is required"),
            pattern: {
              value:
                /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
              message: t("Email is invalid"),
            },
          })}
        />

        <FormInput
          name="code"
          label={t("Code")}
          errors={errors}
          placeholder={t("Code")}
          {...register("code", { required: t("Code is required") })}
        />

        <div className="flex gap-4">
          <FormInput
            name="password"
            label={t("New Password")}
            errors={errors}
            placeholder={t("Required")}
            type="password"
            className="flex-1"
            {...register("password", {
              required: t("Password is required"),
              validate: checkCognitoPasswordRequirements,
            })}
          />

          <FormInput
            name="confirmPassword"
            label={t("Confirm Password")}
            errors={errors}
            placeholder={t("Required")}
            type="password"
            className="flex-1"
            {...register("confirmPassword", {
              required: t("Confirm Password is required"),
              validate: checkMatchingFields(
                formValues.password,
                t("Passwords do not match")
              ),
            })}
          />
        </div>

        <PasswordRequirements />

        <div className="modal-action">
          <button
            type="button"
            onClick={() => navigate("/")}
            className="btn-outline btn-sm btn"
          >
            {t("Cancel")}
          </button>

          <button
            type="submit"
            className="btn-success btn-sm btn"
            disabled={loading}
          >
            {loading ? (
              <span className="loading loading-ring loading-md text-neutral"></span>
            ) : (
              t("CONFIRM")
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
