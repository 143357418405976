import USER_DISABLE from "@/api/user/disableUser";
import { RootContext } from "@/context/rootContext";
import useSelectUser from "@/hooks/apolloState/useSelectUser";
import { useMutation } from "@apollo/client";
import { useCallback, useContext } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export default function DisableUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userID } = useParams();
  const user = useSelectUser(userID);
  const { userManyQuery } = useContext(RootContext);
  const [_, { refetch }] = userManyQuery;
  const [disableUser, { loading }] = useMutation(USER_DISABLE);
  const submit = useCallback(
    async (e) => {
      e.preventDefault();

      await disableUser({ variables: { email: user?.email } });

      refetch();

      toaster.success(t("User Disabled!"));

      navigate("../");
    },
    [user, disableUser, refetch, t, navigate]
  );

  return (
    <form onSubmit={submit}>
      <h1 className="flex justify-center">{t("Disable User")}</h1>

      <div className="my-5">
        <span>
          {t("Are you sure you want to disable user")}
          {` `}
        </span>
        <span className="text-success">{user?.name}</span>?
      </div>

      <div className="flex w-full justify-end">
        <button
          disabled={loading}
          className={`btn-error btn-sm btn`}
          type="submit"
        >
          {loading ? (
            <span className="loading loading-ring loading-md text-neutral"></span>
          ) : (
            t("Disable")
          )}
        </button>
      </div>
    </form>
  );
}
