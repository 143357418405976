import { listen } from "@tauri-apps/api/event";
import { useEffect } from "react";
import useSessionClosedHandler from "./useSessionClosedHandler";

export default function useSessionClosedListener() {
  const handler = useSessionClosedHandler();

  useEffect(() => {
    const unlisten = listen("session_closed", handler);

    return () => {
      unlisten.then((f) => f());
    };
  }, [handler]);
}
