import { useEffect, useState } from "react";
import { invoke } from "@tauri-apps/api/tauri";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectOpenSessions } from "@/redux/sessions/selectors";
import useCheckIsDesktopRunning from "./useCheckIsDesktopRunning/useCheckIsDesktopRunning";
import { info } from "@/utilities/log";
import timeout from "@/utilities/events/timeout";
import { emptyObj } from "@/utilities/empties";

export default function useRefreshOpenSessionsList() {
  const dispatch = useDispatch();
  const [getOpenSessions, { loading }] = useLazyRefreshOpenSessionsList();
  const openSessions = useSelector(selectOpenSessions);

  useEffect(() => {
    getOpenSessions();
  }, [dispatch, getOpenSessions]);

  return { data: openSessions, loading };
}

export function useLazyRefreshOpenSessionsList() {
  const [loading, setLoading] = useState(false);
  const checkIsDesktopRunning = useCheckIsDesktopRunning();
  const getOpenSessions = useCallback(
    async (options = emptyObj) => {
      const { pause = 0, chainProcess } = options;
      setLoading(true);

      const isDesktopRunning = await checkIsDesktopRunning(chainProcess);

      if (!isDesktopRunning) {
        info(
          "[useLazyRefreshOpenSessionsList] NO OPEN SESSIONS, APPLICATION NOT RUNNING..."
        );
      }

      info("[useLazyRefreshOpenSessionsList] UPDATING OPEN SESSIONS...");
      await timeout(pause);
      isDesktopRunning && (await invoke("get_open_sessions_list"));

      setLoading(false);
    },
    [checkIsDesktopRunning]
  );

  return [getOpenSessions, { loading }];
}
