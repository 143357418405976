import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useVerifyAccount from "./hooks/useVerifyAccount";
import { Trans, useTranslation } from "react-i18next";

export default function VerificationCodeForm() {
  const { t } = useTranslation();
  const form = useForm();
  const navigate = useNavigate();
  const { register, handleSubmit, watch, formState } = form;
  const { errors } = formState;
  const formValues = watch();
  const { signUpAws, loading } = useVerifyAccount(formValues);

  return (
    <form className="flex flex-col" onSubmit={signUpAws}>
      <span className="text-sm">
        <Trans>
          You should receive a verification code in your email. Please enter it
          below. If you have not received it, check your Junk folder in your
          inbox or click on the
        </Trans>{" "}
        <b>
          <Trans>Resend Code</Trans>
        </b>{" "}
        <Trans>link.</Trans>
      </span>

      <input
        {...register("verificationCode", {
          required: true,
        })}
        label={t("Verification Code")}
        type="text"
        className={`${errors?.verificationCode && "tooltip-open"} tooltip`}
        data-tip={errors?.verificationCode}
      />

      <div>
        <button className="btn btn-success">{t("submit")}</button>
      </div>
    </form>
  );
}
