import { useCallback } from "react";
import { appWindow } from "@tauri-apps/api/window";
import useLogout from "../../../hooks/account/useLogout";
import { useTranslation } from "react-i18next";

export default function useCloseWindow() {
  const { t } = useTranslation();
  const logout = useLogout();
  const closeWindow = useCallback(async () => {
    await logout({ onStartMessage: t("Closing...") });
    await appWindow.close();
  }, [logout, t]);

  return closeWindow;
}
