import { useCallback } from "react";
import useCheckIsDesktopRunning from "@/hooks/integration/useCheckIsDesktopRunning/useCheckIsDesktopRunning";
import useIsDesktopChannelOpen from "@/hooks/integration/useIsDesktopChannelOpen/useIsDesktopChannelOpen";

export default function useVerifyDesktop() {
  const checkIsDesktopRunning = useCheckIsDesktopRunning();
  const desktopChannel = useIsDesktopChannelOpen();
  const verifyDesktop = useCallback(
    async (chainProcess) => {
      const isDesktopRunning = await checkIsDesktopRunning(chainProcess);
      const status = await desktopChannel();

      return { isDesktopRunning, status };
    },
    [checkIsDesktopRunning, desktopChannel]
  );

  return verifyDesktop;
}
