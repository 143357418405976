import { error } from "@/utilities/log";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function useRequestPasswordReset() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const sendResetPasswordCode = async (formData) => {
    const { email } = formData;

    try {
      setLoading(true);
      await Auth.forgotPassword(email);

      navigate("/reset_password_code");
      toaster.success(t("Check email for confirmation code"));
    } catch (e) {
      toaster.error(t("Error sending reset password code"));
      error(e.message);
    } finally {
      setLoading(false);
    }
  };

  return { sendResetPasswordCode, loading };
}
