import FormInput from "@/components/forms/formComponents/FormInput";
import { selectIsOnline } from "@/redux/account/selectors";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useLogin from "./useLogin";
import { useTranslation } from "react-i18next";

export default function LoginForm({ className }) {
  const { t } = useTranslation();
  const form = useForm();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = form;
  const isOnline = useSelector(selectIsOnline);
  const { login, loading } = useLogin(getValues);

  return (
    <div
      className={`flex flex-col w-full ${
        isOnline ? "" : "tooltip tooltip-bottom"
      } ${className}`}
      data-tip={t("You appear to be offline")}
    >
      <form
        className="flex w-full flex-col gap-5"
        onSubmit={handleSubmit(() => login())}
      >
        <FormInput
          name="email"
          label={t("Login Name")}
          errors={errors}
          placeholder={t("Email")}
          disabled={!isOnline}
          {...register("email", {
            required: t("Email is required"),
            pattern: {
              value:
                /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
              message: t("Email is invalid"),
            },
          })}
        />

        <FormInput
          name="password"
          label={t("Password")}
          errors={errors}
          placeholder={t("Password")}
          type="password"
          className="pb-0"
          disabled={!isOnline}
          {...register("password", { required: t("Password is required") })}
        />

        <div className="flex flex-1 justify-end">
          <Link
            className="text-white underline"
            to="/send_reset_password_email"
            disabled={!isOnline || loading}
          >
            <span>{t("Reset password")}</span>
          </Link>
        </div>

        <button
          disabled={!isOnline || loading}
          className={`btn-success btn flex-1 rounded-md`}
        >
          {loading ? (
            <span className="loading loading-ring loading-md text-white"></span>
          ) : (
            <span>{t("Login")}</span>
          )}
        </button>
      </form>
    </div>
  );
}
