import { useTranslation } from "react-i18next";
import Card from "../../Card/Card";
import useDefaultSettingsInfo from "@/hooks/account/useDefaultSettingsInfo/useDefaultSettingsInfo";
import useOpenDefaultSettings from "@/hooks/integration/useOpenDefaultSettings/useOpenDefaultSettings";

export default function DesktopSettingsCard() {
  const { t } = useTranslation();
  const { cardTitle, lastUpdatedAt, lastUpdatedBy } = useDefaultSettingsInfo();
  const [openDefaultSettings, { loading }] = useOpenDefaultSettings();

  return (
    <Card
      className="mb-5"
      title={cardTitle}
      buttons={
        <button
          onClick={openDefaultSettings}
          className="btn btn-primary btn-sm ml-auto text-base-100 w-52"
          disabled={loading}
        >
          {loading ? (
            <span className="loading loading-dots loading-xl"></span>
          ) : (
            t("Edit Default Settings")
          )}
        </button>
      }
    >
      {lastUpdatedBy && (
        <div className="flex gap-2">
          <span className="text-base-200 font-bold text-sm w-28">
            {t("Last updated by")}
          </span>

          <span>{lastUpdatedBy}</span>
        </div>
      )}
      {lastUpdatedAt && (
        <div className="flex gap-2">
          <span className="text-base-200 font-bold text-sm w-28">
            {t("at")}
          </span>

          <span>{lastUpdatedAt}</span>
        </div>
      )}
    </Card>
  );
}
