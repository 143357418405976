import { useTranslation } from "react-i18next";

export default function LoadingRow({ virtualRow, index }) {
  const { t } = useTranslation();

  return (
    <div
      key={virtualRow.index}
      className="flex justify-center items-center gap-3"
      style={{
        height: `${virtualRow.size}px`,
        transform: `translateY(${
          virtualRow.start - index * virtualRow.size
        }px)`,
      }}
    >
      <span>{t("loading")}</span>

      <span className="loading loading-infinity loading-sm"></span>
    </div>
  );
}
