import { flexRender } from "@tanstack/react-table"

import ArrowUpIcon from "@/assets/vectors/arrow-up.svg?react"
import ArrowDownIcon from "@/assets/vectors/arrow-down.svg?react"
import BiDirectionArrow from "@/assets/vectors/bi-arrow.svg?react"

export default function HeaderCell({ header }) {
  const {
    column: {
      id,
      getToggleSortingHandler,
      columnDef,
      getCanSort,
      getIsSorted,
      getCanResize,
      getIsResizing
    },
    getResizeHandler,
    getContext,
    colSpan,
    getSize
  } = header
  const canSort = getCanSort()
  const isSorted = getIsSorted()
  const toggleSort = getToggleSortingHandler()
  const minSize = columnDef.minSize

  return (
    <div
      colSpan={colSpan}
      key={id}
      style={{ width: getSize() + "px" }}
      className={`HeaderCell flex items-center justify-center relative rounded-none text-center overflow-hidden p-4 
      ${columnDef.meta?.className} ${columnDef.meta?.headerCellClassName}`}
    >
      <div
        style={{ minWidth: minSize + "px" }}
        className={`flex select-none items-center justify-center gap-1 ${
          canSort ? "cursor-pointer" : ""
        }`}
        onClick={toggleSort}
      >
        <div className="grow"></div>

        {flexRender(columnDef.header, getContext())}

        <div className="grow"></div>

        {canSort ? (
          <div className="flex justify-end mr-2">
            {!isSorted ? (
              <BiDirectionArrow width={16} />
            ) : isSorted === "asc" ? (
              <ArrowUpIcon width={16} />
            ) : (
              <ArrowDownIcon width={16} />
            )}
          </div>
        ) : (
          <div className="mr-2"></div>
        )}
      </div>

      {getCanResize() && (
        <div
          onMouseDown={getResizeHandler()}
          onTouchStart={getResizeHandler()}
          className={`absolute bg-neutral/40 hover:bg-white/50 active:bg-white/75 rounded-full active:rounded-none w-1.5 h-full right-0 top-0 mr-1 cursor-pointer focus:rounded-full ${
            getIsResizing() ? "bg-base-300" : ""
          }`}
        ></div>
      )}
    </div>
  )
}
