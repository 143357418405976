import { toaster } from "@/utilities/toaster";
import { Auth } from "aws-amplify";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

export default function useResetPassword(reset) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const resetPassword = useCallback(
    async (formValues) => {
      try {
        setLoading(true);
        const currentUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(
          currentUser,
          formValues.currentPassword,
          formValues.newPassword
        );

        await Auth.currentAuthenticatedUser();

        reset();
        toaster.success(t("Pasword has been reset"));

        setLoading(false);
      } catch (e) {
        setLoading(false);

        throw e;
      }
    },
    [reset, t]
  );

  return { resetPassword, loading };
}
