import Filters from "@/components/NavBar/Filters/Filters";
import { selectUserEnabled } from "@/redux/listFilter/selectors";
import { setUserEnabled } from "@/redux/listFilter/slice";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

export default function UserFilters() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userEnabled = useSelector(selectUserEnabled);
  const isFiltered = !!userEnabled;
  const userEnabledToggler = useCallback(
    (e) => {
      const enabled = e.target.checked;

      dispatch(setUserEnabled(enabled ? enabled : null));
    },
    [dispatch]
  );

  return (
    <div>
      <Filters
        isFiltered={isFiltered}
      >
        <div className="form-control">
          <label className="flex cursor-pointer">
            <span className="mr-auto font-medium">
              {t("Enabled users only")}
            </span>

            <input
              checked={userEnabled ? true : false}
              onChange={userEnabledToggler}
              type="checkbox"
              className="toggle-success toggle"
            />
          </label>
        </div>
      </Filters>
    </div>
  );
}
