import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";

export default function useSelectSession(sessionID) {
  const client = useApolloClient();
  const selectSession = useCallback(
    (sessionID) => {
      const cacheData = client.cache.extract();
      const sessions = Object.values(cacheData).filter(
        (data) => data.__typename === "Session"
      );
      const session = sessions.find((session) => session.sessionID === sessionID);

      return session;
    },
    [client]
  );

  return sessionID ? selectSession(sessionID) : selectSession;
}
