import { useNavigate, useOutlet } from "react-router-dom";
import ScrollManager from "../ScrollManager/ScrollManager";
import { useCallback, useEffect, useRef } from "react";

export default function Modal({
  className,
  children,
  dataTheme = "insoles-light",
}) {
  const navigate = useNavigate();
  const ref = useRef(null);
  const outlet = useOutlet();
  const outletRef = useRef(null);
  const handleClick = useCallback(
    (e) => {
      const modalDoc = ref.current;

      if (!modalDoc) return;

      const path = e.composedPath ? e.composedPath() : e.path;
      const isInPath = path.includes(ref.current);
      const isClickInsideModal = ref.current.contains(e.target);
      const isClickInsideOutlet = outletRef.current.contains(e.target);
      const isSuperSelect = e.target.closest('[class*="super-select"]');
      const isOutletWrapper = e.target.closest(".outlet-wrapper");
      const isInteractiveElement = e.target.closest(
        'button, a, input, select, textarea, [role="button"]'
      );

      if (isSuperSelect) return;
      if (isInteractiveElement) return;
      if (isInPath) return;
      if (isClickInsideModal) return;
      if (isClickInsideOutlet) return;
      if (isOutletWrapper) return;

      navigate("../", { replace: true });
    },
    [ref, navigate]
  );

  useEffect(() => {
    window.addEventListener("touchend", handleClick);
    window.addEventListener("mousedown", handleClick);

    return () => {
      window.removeEventListener("touchend", handleClick);
      window.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick]);

  return (
    <div id="modal-wrapper" className={`modal modal-open`}>
      <div
        ref={ref}
        data-theme={dataTheme}
        className={`ModalContentWrapper modal-box z-50 relative p-0 ${className}`}
      >
        <ScrollManager className="max-h-[900px] p-5">
          {children}

          <div ref={outletRef} className="outlet-wrapper">
            {outlet}
          </div>
        </ScrollManager>
      </div>
    </div>
  );
}
