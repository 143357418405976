import useDeleteOfflineSessions from "@/hooks/io/useDeleteOfflineSessions/useDeleteOfflineSessions";
import useGetChildSessions from "@/hooks/io/useGetChildSessions/useGetChildSessions";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useLocation } from "react-router-dom";

export default function DeleteOfflineSession() {
  const { t } = useTranslation();
  const { sessionID } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const childSessions = useGetChildSessions(sessionID);
  const [deleteHandler, { loading }] = useDeleteOfflineSessions(
    sessionID,
    state.extension,
    childSessions
  );
  const hasChildSessions = childSessions.length > 0;

  return (
    <div className="flex flex-col">
      <h1>
        {hasChildSessions
          ? t(
              "Are you sure you want to delete this offline session and its child sessions?"
            )
          : t("Are you sure you want to delete this offline session?")}
      </h1>

      <div className="py-5 flex flex-col">
        <span className="font-bold text-md">
          {hasChildSessions ? t("Session IDs") : t("Session ID")}
        </span>

        <div data-testid="divSessionId">{sessionID}</div>

        {childSessions.map(({ sessionID }) => (
          <div key={sessionID}>{sessionID}</div>
        ))}
      </div>

      <div className="flex justify-end gap-5">
        <button
          data-testid="cancelDelete"
          onClick={() => navigate("../")}
          disabled={loading}
          className="btn btn-sm"
        >
          {t("Cancel")}
        </button>

        <button
          disabled={loading}
          data-testid="btnDeleteHandler"
          className="btn btn-sm btn-error"
          onClick={deleteHandler}
        >
          {loading ? (
            <span className="loading loading-ring loading-sm"></span>
          ) : (
            t("Delete")
          )}
        </button>
      </div>
    </div>
  );
}
