import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Card from "../../Card/Card";

export default function AccountSecurityCard() {
  const { t } = useTranslation();

  return (
    <Card
      className="mb-5"
      title={t("Account Security")}
      buttons={
        <Link
          to="edit_password"
          className="btn btn-primary btn-sm ml-auto text-base-100 w-52"
        >
          {t("Change Password")}
        </Link>
      }
    >
      <div className="flex gap-5">
        <span className="text-base-200 font-bold text-sm w-28">
          {t("Password")}:
        </span>

        <span>*******************</span>
      </div>
    </Card>
  );
}
