export default function mergeWithPayload(
  existing = { payload: [], pageInfo: {} },
  incoming,
  { args: { offset = 0 } }
) {
  const merged = existing ? existing.payload.slice(0) : [];
  for (let i = 0; i < incoming.payload.length; ++i) {
    merged[offset + i] = incoming.payload[i];
  }
  return {
    payload: merged,
    pageInfo: incoming.pageInfo,
  };
}
