import { gql } from "@apollo/client";
import ROLE from "./roleFragment";

const ROLE_MANY = gql`
  query ROLE_MANY {
    roleMany {
      ...RoleFragment
    }
  }

  ${ROLE}
`;

export default ROLE_MANY;
