import Header from "@/components/Header/Header";
import MacTitleBar from "@/components/TitleBar/MacTitleBar/MacTitleBar";
import View from "@/components/View/View";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import logo_bg from "@/assets/images/logo_bg.png";
import { Link } from "react-router-dom";
import useGitRelease from "./useGitRelease";

const FILE_TYPE = "nsis.zip";

export default function Downloads() {
  const { t } = useTranslation();
  const [getRelease, { data, loading }] = useGitRelease();
  const link = useMemo(() => {
    return data?.assets?.find((asset) => asset.name.endsWith(FILE_TYPE))
      ?.browser_download_url;
  }, [data]);

  useEffect(() => {
    getRelease();
  }, [getRelease]);

  return (
    <View className="bg-hero-image bg-cover bg-right-top bg-no-repeat">
      <MacTitleBar />

      <Header />

      <div className="flex justify-center items-center h-full w-full">
        <div className="card w-96 glass">
          <figure>
            <img src={logo_bg} alt="logo" />
          </figure>

          <div className="card-body">
            <h2 className="card-title">
              {t("Latest Version ")}{" "}
              {data?.tag_name?.replace("v", "") || (
                <div className="loading loading-dots loading-sm"></div>
              )}
            </h2>

            <p>{t("Get the latest Clinical Foot and Gait here")}</p>

            <div className="card-actions justify-end">
              <Link
                to={link}
                target="_blank"
                disabled={loading}
                rel="noopener noreferrer"
                className="btn btn-primary"
              >
                {t("Download")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </View>
  );
}
