import { emptyObj } from "@/utilities/empties"
import { useEffect } from "react"

const LOAD_THRESHOLD = 6

export default function useFetchNextPage(
  virtualizer,
  data,
  pageInfo = emptyObj,
  fetchMore,
  loading
) {
  useEffect(() => {
    const virtualItems = virtualizer?.getVirtualItems()
    const [lastItem] = [...virtualItems].reverse()
    const { currentOffset, currentLimit, hasNextPage } = pageInfo
    const offset = currentOffset + currentLimit

    if (!lastItem) return

    if (
      lastItem.index >= data?.length - LOAD_THRESHOLD &&
      hasNextPage &&
      !loading
    ) {
      fetchMore({ variables: { offset } })
    }
  }, [virtualizer.getVirtualItems(), data, pageInfo, fetchMore, loading])
}
