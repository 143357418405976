import * as Sentry from "@sentry/react";

export default function configureSentry(environment) {
  Sentry.init({
    dsn: environment.SENTRY_AUTH_TOKEN,
    environment: environment.STAGE,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
}
